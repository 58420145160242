import styled from "styled-components";

const ParentDiv = styled.div`
a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit; 
  text-decoration:none; 
  cursor:pointer; 
  text-decoration: underline; 
}

  .leads-header {
    min-height: 100px;
  }
  .leads-header-Icon {
    color: #935b09;
    background-color: #FFDDB5;
    border: 2px solid #935b09;
  }
  .leads-connector-line {
    background-color: #E39D35;
  }
  .leads-header-normal-title {
    display: flex;
    align-items: center;
    padding-bottom: 45px;
    justify-content: left;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.25px;
    color: #4B4640;
  }
  .leads-header-normal-value {
    display: flex;
    align-items: center;
    padding-bottom: 45px;
    justify-content: right;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.25px;
  }
  .leads-content-normal-title {
    font-style: normal;
    font-weight: 500;
    color: #4B4640;
  }
  .leads-content-normal-value {

  }

  .leads-header-alternate-title {
    display: flex;
    align-items: center;
    padding-bottom: 45px;
    justify-content: right;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.25px;
    color: #4B4640;
  }
  .leads-header-alternate-value {
    display: flex;
    align-items: center;
    padding-bottom: 45px;
    justify-content: left;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.25px;
  }
  .leads-content-alternate-title {
    display: flex;
    justify-content: right;
    font-style: normal;
    font-weight: 500;
    color: #4B4640; 
  }
  .leads-content-alternate-value {
    display: flex;
    justify-content: left;
  }

`;

export {
  ParentDiv,
};
