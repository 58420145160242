import * as React from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import PlotCard from "./PlotCard/PlotCard";

// Store imports
import {
  getLayoutList,
  getPromotorsDetails,
} from "../../store/layout/actionCreator";

import "./Home.css";

const Home = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { getLayoutList, layout, getPromotorsDetails, promoters } = props;

  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (userDetails && userDetails.user_id !== "") {
      getLayoutList(userDetails);
      if (userDetails.user_type === "P") {
        getPromotorsDetails({
          company_id: userDetails.user_id,
        });
      }
    }
  }, [userDetails]);

  //console.log("layout", promoters);

  const openLayout = (e, data) => {
    e.stopPropagation();
    if (data.id !== "") {
      // console.log("&&& id", data);
      navigate("/layoutdetails", {
        state: {
          id: data.id,
          layout_name: data.name,
          project_hash: data.hash_key,
        },
      });
    }
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={state && state.menu_url !== "" ? true : false}
              backRoute={(e) => backRoute(e, state.menu_url, {})}
            />
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          margin="2em 0 0 0;"
          textAlign={"center"}
        >
          {promoters &&
            promoters.general_information &&
            promoters.general_information.logo !== "" && (
              <div className="">
                <img
                  height={150}
                  src={promoters.general_information.logo}
                  alt="#"
                />
              </div>
            )}
        </Grid>

        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-txt">layout details</span>
        </Grid>
        {layout && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <PlotCard list={layout} openLayout={openLayout} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.layout.list,
    promoters: state.layout.promoters,
  };
};
const mapDispatchToProps = {
  getLayoutList,
  getPromotorsDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
