import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import "../Home/PlotCard/PlotCard.css";
import "../SiteVisit/SiteVisit.css";
import "./ViewEmployees.css";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import Dialog from "../Dialog";

// Store imports
import {
  getEmployeeList,
  deleteEmployee,
} from "../../store/promoters/actionCreator";
import EmployeePopup from "../Overlays/EmployeePopup/EmployeePopup";
import XHR from "../../util/xhr";

import { map } from "lodash";

const ViewEmployees = (props) => {
  const navigate = useNavigate();

  const { getEmployeeList, employee_list, deleteEmployee } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState(null);
  const [editUser, setEditUser] = React.useState(null);
  const [openUserPopup, setOpenUserPopup] = React.useState(false);
  const [expandList, setExpandList] = React.useState(null);
  const [dialogShow, setDialogShow] = React.useState(false);
  const [dialogProps, setDialogProps] = React.useState({});

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (userDetails) {
      getEmployeeList({
        user_id: userDetails.user_id,
      });
    }
  }, [userDetails]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    XHR.post("realestate_v1/user/add", data).then(
      (res) => {
        if (res.data && res.data.status) {
          setIsLoading(false);
          if (userDetails) {
            getEmployeeList({
              user_id: userDetails.user_id,
            });
          }
          setOpenUserPopup(false);
        } else {
          setIsLoading(false);
          openSnackBar(`User already exists, Please contact administrator`);
        }
      },
      (err) => {}
    );
  };

  const onDelete = (id) => {
    setDialogShow(true);
    setDialogProps({
      title: `Do you want to delete user?`,
      message: "",
      onClose: (yes) => {
        closeDialog(true);
      },
      button: [
        {
          title: "No",
          click: () => closeDialog(true),
        },
        {
          title: "Yes",
          click: () => deleteUser(id),
        },
      ],
    });
  };

  const deleteUser = (id) => {
    setIsLoading(true);
    closeDialog();
    deleteEmployee({ id }).then((res) => {
      if (res.status) {
        setIsLoading(false);
        // Delete success
        if (userDetails) {
          getEmployeeList({
            user_id: userDetails.user_id,
          });
        }
        openSnackBar("User Removed", "success");
      } else {
        setIsLoading(false);
        openSnackBar(`Unable to delete, Please contact administrator`);
      }
    });
  };

  const closeDialog = () => {
    setDialogShow(false);
    setTimeout(() => {
      setDialogProps({});
    }, 100);
  };

  return (
    <>
      <Dialog open={dialogShow} {...dialogProps} />
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <EmployeePopup
        openFlag={openUserPopup}
        setCloseFlag={setOpenUserPopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
        editUser={editUser}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid item lg={12} md={12} sm={12} xs={12} alignItems="center">
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={true}
              backRoute={(e) => backRoute(e, "promoters", {})}
            />
          </Grid>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-txt">view employees</span>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {employee_list.length > 0 &&
              map(employee_list, function (data, index) {
                return (
                  <Card
                    className="box-card"
                    key={index}
                    onClick={() => {
                      setExpandList(index);
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{ background: "#ffeedd", color: "#462B00" }}
                          aria-label="recipe"
                        >
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <>
                          <Typography className="box-text" gutterBottom>
                            Name : {data.name}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Mobile No : {data.contact}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Email Id : {data.email}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Role : {data.role_name}
                          </Typography>
                        </>
                      }
                    />
                    <CardContent
                      style={{
                        paddingTop: 0,
                        paddingBottom: 16,
                      }}
                    >
                      {expandList === index && (
                        <>
                          {data.mappedProject &&
                            data.mappedProject.length > 0 && (
                              <>
                                <Divider style={{ marginBottom: 10 }} />
                                <Typography
                                  className="box-header"
                                  gutterBottom
                                  style={{ textAlign: "center" }}
                                >
                                  Assigned Layouts
                                </Typography>
                                {data.mappedProject &&
                                  data.mappedProject.length > 0 &&
                                  map(
                                    data.mappedProject,
                                    function (edata, eindex) {
                                      return edata.is_mapped ? (
                                        <>
                                          <Typography
                                            className="box-text"
                                            gutterBottom
                                          >
                                            {`${eindex + 1}. ${edata.name}`}
                                          </Typography>
                                        </>
                                      ) : null;
                                    }
                                  )}
                              </>
                            )}
                          {data.mappedMenu && data.mappedMenu.length > 0 && (
                            <>
                              <Divider
                                style={{ marginTop: 10, marginBottom: 10 }}
                              />
                              <Typography
                                className="box-header"
                                gutterBottom
                                style={{ textAlign: "center" }}
                              >
                                Assigned Modules
                              </Typography>
                              {data.mappedMenu &&
                                data.mappedMenu.length > 0 &&
                                map(data.mappedMenu, function (mdata, mindex) {
                                  return (
                                    <>
                                      <Typography
                                        className="box-text"
                                        gutterBottom
                                      >
                                        {`${mindex + 1}. ${mdata.menu_name}`}
                                      </Typography>
                                    </>
                                  );
                                })}
                            </>
                          )}

                          {data.mappedActionMenu && data.mappedActionMenu.length > 0 && (
                            <>
                              <Divider
                                style={{ marginTop: 10, marginBottom: 10 }}
                              />
                              <Typography
                                className="box-header"
                                gutterBottom
                                style={{ textAlign: "center" }}
                              >
                                Action Modules
                              </Typography>
                              {data.mappedActionMenu &&
                                data.mappedActionMenu.length > 0 &&
                                map(data.mappedActionMenu, function (mdata, mindex) {
                                  return (
                                    <>
                                      <Typography
                                        className="box-text"
                                        gutterBottom
                                      >
                                        {`${mindex + 1}. ${mdata.menu_name}`}
                                      </Typography>
                                    </>
                                  );
                                })}
                            </>
                          )}
                        </>
                      )}
                    </CardContent>
                    {expandList === index && (
                      <CardActions
                        disableSpacing
                        style={{
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          style={{ marginRight: "auto" }}
                          onClick={(e) => onDelete(data.id)}
                        >
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          onClick={(e) => {
                            setOpenUserPopup(true);
                            setEditUser(data);
                          }}
                        >
                          <EditIcon fontSize="large" />
                        </IconButton>
                      </CardActions>
                    )}
                  </Card>
                );
              })}
            {employee_list && employee_list.length === 0 && (
              <Card className="box-card" key={0}>
                <CardContent
                  className="box-content"
                  style={{ textAlign: "center" }}
                >
                  <Typography className="box-text" gutterBottom>
                    No User
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "42%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employee_list: state.promoters.employee_list,
  };
};
const mapDispatchToProps = {
  getEmployeeList,
  deleteEmployee,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewEmployees);
