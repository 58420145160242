import auth from "./auth/reducer";
import layout from "./layout/reducer";
import promoters from "./promoters/reducer";
import role from "./role/reducer";
import leads from "./leads/reducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth,
  layout,
  promoters,
  role,
  leads,
});

export default rootReducer;
