import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import WestIcon from "@mui/icons-material/West";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//Custom imports
import * as CSS from "../style";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

const PromoterProfileStepFour = (props) => {
  const navigate = useNavigate();
  const { nextStep, id = null } = props;

  const onBackBtnClk = () => {
    nextStep(1);
  };

  const onNextBtnClk = () => {
    nextStep(3);
  };

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const promotor_user = localStorage.getItem("realestate_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (id) {
      getProjectDetails(id);
    }
  }, [id]);

  const getProjectDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        setData((res.data && res.data.data) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updateProjectData = () => {
    setIsLoading(true);
    const payload1 = JSON.parse(JSON.stringify({ ...data }));
    XHR.post("realestate/updateinfo", payload1).then(
      (res) => {
        setIsLoading(false);
        openSnackBar("Success.", "success");
        getProjectDetails(id);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [uploadObj, setUploadObj] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleUploadClose = () => {
    setOpenUpload(false);
    setUploadType(null);
    setUploadObj(null);
    setUploadStatus(null);
    setUploading(false);
  };

  const onFileChange = (event) => {
    setUploadStatus(null);
    setUploading(false);
    setUploadObj(event.target.files[0]);
  };

  const handleConfirmUpload = () => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("type", uploadType === "image" ? "I" : "S");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setUploading(false);
        setUploadStatus(res.data || null);
        openSnackBar("Successfully uploaded.", "success");
      },
      (err) => {
        setUploading(false);
        console.log("err", err);
      }
    );
  };

  const handlePreview = () => {
    if (uploadStatus && uploadStatus.url) {
      window.open(uploadStatus.url, "_blank");
    }
  };

  const handleSave = () => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    let assets = dataCopy.gallery || [];
    assets.push({ type: uploadType, url: uploadStatus.url });
    console.log("assets", assets);
    setData({
      ...data,
      gallery: assets,
    });
    handleUploadClose();
  };

  const handleRemoveGallery = (index, type) => {
    const assets =
      data && data.gallery && data.gallery.filter((el) => el.type === type);

    const allAssets =
      data && data.gallery && data.gallery.filter((el) => el.type !== type);

    assets.splice(index, 1);
    setData({
      ...data,
      gallery: allAssets.concat(assets),
    });
  };

  const renderPreviewGallery = (previewType) => {
    const assets =
      data &&
      data.gallery &&
      data.gallery.filter((el) => el.type === previewType);
    return (
      <>
        {assets && assets.length > 0 && (
          <CSS.PromoterDirCardContainer>
            <ImageList cols={6}>
              {(assets || []).map((el, i) => (
                <ImageListItem key={i}>
                  <img
                    src={`${el.url}`}
                    srcSet={`${el.url}`}
                    alt={el.url}
                    loading="lazy"
                    style={{ height: 100, cursor: "pointer" }}
                    onClick
                  />
                  <div className="photos-delete" loading="lazy">
                    <Tooltip
                      title="Delete"
                      onClick={() => handleRemoveGallery(i, previewType)}
                    >
                      <DeleteIcon />
                    </Tooltip>
                  </div>
                </ImageListItem>
              ))}
            </ImageList>
          </CSS.PromoterDirCardContainer>
        )}
      </>
    );
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Dialog
        open={openUpload}
        size="md"
        fullWidth
        onClose={handleUploadClose}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title">Upload</DialogTitle> */}
        <DialogContent>
          <Grid
            container
            direction="column"
            gap="1em"
            justifyContent="center"
            alignItems="center"
          >
            <div style={{ position: "absolute", top: "0px", right: "0px" }}>
              <IconButton aria-label="closePopup" onClick={handleUploadClose}>
                <HighlightOffIcon />
              </IconButton>
            </div>
            <div style={{ marginTop: "2%" }}></div>
            {(uploadType === "image" ||
              uploadType === "logo" ||
              uploadType === "layout" ||
              uploadType === "boardPhoto" ||
              uploadType === "teamPhoto" ||
              uploadType === "feedbackPhoto") && (
              <Grid item xs={12}>
                <input
                  accept="image/jpeg"
                  style={{ display: "none" }}
                  id="image-button-file"
                  onChange={onFileChange}
                  type="file"
                  multiple
                />
                {!uploadObj ? (
                  <label htmlFor="image-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className="login-button"
                    >
                      Choose Image
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography
                      style={{ marginBottom: 10, textAlign: "center" }}
                    >
                      {uploadObj.name || "Image"}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={handleConfirmUpload}
                          style={{ marginRight: 10 }}
                          className="login-button"
                        >
                          {uploading ? "Uploading" : "Upload Image"}
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="login-button"
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component="span"
                          className="login-button"
                        >
                          Preview Image
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="login-button"
                            style={{ marginRight: 10 }}
                          >
                            Choose New Image
                          </Button>
                        </label>
                        {uploadStatus && (
                          <label htmlFor="image-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className="login-button"
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                          </label>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleUploadClose} color="primary">
            Close
          </Button>
          {uploadStatus && (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions> */}
      </Dialog>
      <Grid container justifyContent="center" alignItems="center" height="50vh">
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Upload Photos</span>
                <div>
                  <span className="login-form-heading-step">(Step 2 of 6)</span>
                </div>
              </div>
              <div className="profile-back-next-btn-cntr">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onBackBtnClk}
                  className="login-button"
                  startIcon={<WestIcon />}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    setUploadType("image");
                    setOpenUpload(true);
                  }}
                >
                  Upload Photo
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  onClick={() => updateProjectData()}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onNextBtnClk}
                  className="login-button"
                  endIcon={<EastIcon />}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="2em 0 2em 0"
          padding="0 4em 0 4em"
        >
          {renderPreviewGallery("image")}
        </Grid>
      </Grid>
    </>
  );
};

export default PromoterProfileStepFour;
