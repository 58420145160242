import { useState, useEffect } from "react";

//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
//Custom imports
import * as CSS from "../Style.js";

const FilterPlotsPopup = (props) => {
  const { openFlag, setCloseFlag, onSubmit, openSnackBar } = props;

  const [filterPopupData, setFilterPopupData] = useState({
    Available: true,
    Booked: true,
    Sold: true,
  });

  const handleChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.checked;
    const updatedData = { ...filterPopupData };
    updatedData[modifiedKey] = modifiedValue;
    setFilterPopupData(updatedData);
  };

  const doSubmit = () => {
    if (
      !filterPopupData.Available &&
      !filterPopupData.Booked &&
      !filterPopupData.Sold
    ) {
      openSnackBar("Please Select", "error");
      return;
    }
    const filterArray = [];
    filterPopupData.Available && filterArray.push(0);
    filterPopupData.Booked && filterArray.push(2);
    filterPopupData.Sold && filterArray.push(1);
    onSubmit(filterArray);
  };

  return (
    <>
      <CSS.CustomizedDialog
        open={openFlag}
        onClose={() => setCloseFlag(false)}
        maxWidth={"md"}
        fullWidth={true}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="1em 0 1em 0;"
        >
          <CSS.CloseBtn>
            <IconButton
              aria-label="closePopup"
              onClick={() => setCloseFlag(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </CSS.CloseBtn>
          <CSS.TitleContainer>
            <span className="title">Filter</span>
          </CSS.TitleContainer>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterPopupData.Available}
                  onChange={handleChange}
                  name="Available"
                />
              }
              label="Available"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterPopupData.Booked}
                  onChange={handleChange}
                  name="Booked"
                />
              }
              label="Booked"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterPopupData.Sold}
                  onChange={handleChange}
                  name="Sold"
                />
              }
              label="Sold"
            />
          </FormGroup>
          <CSS.customBtn variant="outlined" onClick={doSubmit}>
            Submit
          </CSS.customBtn>
        </Grid>
      </CSS.CustomizedDialog>
    </>
  );
};

export default FilterPlotsPopup;
