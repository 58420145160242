import axios from "axios";

import { baseUrl } from "../../util/config";

const axiosInstance = axios.create({
  baseURL: `${baseUrl}/`,
  // baseURL: `http://localhost:8000/`,
});
axiosInstance.interceptors.request.use((config) => {
  return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 400) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
