import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import SaveIcon from "@mui/icons-material/Save";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CircularProgress from "@mui/material/CircularProgress";

//Custom imports
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import XHR from "../../../../util/pxhr";
import * as CSS from "../style";

import { baseUrl } from "../../../../util/config";

//Other lib imports
import axios from "axios";

const AddRevision = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [inputObj, setInputObj] = useState({
    sharedDate: "",
    sharedMode: "email",
    docUrl: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [dataDetails, setDataDetails] = React.useState(null);
  const [leadsDetails, setLeadsDetails] = React.useState(null);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  useEffect(() => {
    if (state.layoutDetails) {
      setLayoutDetails({
        ...layoutDetails,
        ...state.layoutDetails,
      });
    }

    if (state.data) {
      setDataDetails({
        ...dataDetails,
        ...state.data,
      });
    }
  }, [state]);

  useEffect(() => {
    if (layoutDetails && layoutDetails.id) {
      const payload = {
        layout_id: layoutDetails.id,
        mpdp_mapping_id: state.data.display_id,
      };
      axios.post(`${baseUrl}/leads/list`, payload).then(
        (res) => {
          console.log("res", res.data && res.data);
          if (res.data && res.data.leads_list) {
            if (res.data.leads_list.length > 0) {
              setLeadsDetails(res.data.leads_list[0]);
            }
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  }, [layoutDetails]);

  const doSubmit = () => {
    console.log("inputObj");
    console.log(inputObj);

    if (!inputObj.sharedDate || !inputObj.sharedMode || !inputObj.docUrl) {
      openSnackBar("Please fill all data", "error");
      return;
    }

    const payload = {
      is_approved: 1,
      type: "DR",
      data: {
        ...inputObj,
      },
      mpdp_mapping_id: state.data.display_id,
    };
    axios
      .post(
        `${baseUrl}/leads/conversion/document/update`,
        payload
      )
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          //console.log("err", err);
        }
      );
  };

  const backRoute = (e, url, data = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: {
          layoutDetails: {
            id: layoutDetails.id,
            layout_name: layoutDetails.layout_name,
            layout_id: layoutDetails.id,
            type: layoutDetails.type,
          },
          data: data,
        },
      });
    }
  };

  const onBrochureFileChange = (event) => {
    setIsLoading(true);
    const files = event.target.files[0];
    const maxAllowedSize = 2 * 1024 * 1024;
    if (files.size > maxAllowedSize) {
      openSnackBar("Please upload file less than 2MB", "error");
      return;
    }
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", files);
    formData.append("type", "I");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setIsLoading(false);
        setUploadedFileName(files.name);
        setInputObj({
          ...inputObj,
          docUrl: (res.data && res.data.url) || "",
        });
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  console.log("layoutDetails", layoutDetails, state);

  const doRoute = (e, menu_url, data = {}) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: data,
      });
    }
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Grid container>
        <Grid item margin={{ lg: 15, md: 9, xs: 2 }} lg={12} md={12} xs={12}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Add Revision</span>
              </div>
              <div className="login-form-div">
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="made-contact-by">{"Shared Mode*"}</InputLabel>
                  <Select
                    labelId="made-contact-by"
                    label="Shared Mode"
                    onChange={(e) =>
                      setInputObj({ ...inputObj, sharedMode: e.target.value })
                    }
                    value={inputObj.sharedMode}
                  >
                    <MenuItem value={"whatsapp"}>whatsapp</MenuItem>
                    <MenuItem value={"email"}>email</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Shared Date"
                  required={true}
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setInputObj({ ...inputObj, sharedDate: e.target.value })
                  }
                  value={inputObj.sharedDate}
                />
              </div>
              <div className="login-form-upload-div">
                <label htmlFor="brochure-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    size="small"
                    className="login-button"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload registration document
                  </Button>
                </label>
                <input
                  accept="application/pdf"
                  style={{ display: "none" }}
                  id="brochure-button-file"
                  onChange={onBrochureFileChange}
                  type="file"
                />
                <div>
                  <span className="login-form-div-helper-text">
                    (PDF file less than 2MB)
                  </span>
                </div>
                {inputObj && inputObj.docUrl ? (
                  <>
                    <label
                      htmlFor="brochure-button-file"
                      style={{ paddingTop: "10px" }}
                    >
                      <Button
                        variant="contained"
                        component="span"
                        size="small"
                        className="login-button"
                        onClick={() => window.open(inputObj.docUrl)}
                        endIcon={<RemoveRedEyeIcon />}
                      >
                        Preview File
                      </Button>
                    </label>
                    <div>
                      <span className="login-form-div-helper-text">
                        {uploadedFileName} uploaded successfully.
                      </span>
                    </div>
                  </>
                ) : null}
              </div>

              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={(e) =>
                      doRoute(e, "registrationdocuments", {
                        id: layoutDetails.id,
                        layout_name: layoutDetails.layout_name,
                        layout_id: layoutDetails.id,
                        type: "CRD",
                      })
                    }
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid item margin={4} lg={4} md={4} xs={12}>
          {/* List */}
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default AddRevision;
