import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";

//Custom imports
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import * as CSS from "../style";

import { baseUrl } from "../../../../util/config";

//Other lib imports
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ClientExpectation = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [clientExpectationData, setClientExpectationData] = useState({
    property_location: "",
    budget_min: "",
    budget_max: "",
    area_in_sqft_min: "",
    area_in_sqft_max: "",
    urgency_to_buy: "",
    purpose: "own",
    corner_plot: "yes",
    direction_facing: [],
    expect_loan: "yes",
    home_construction_support: "yes",
  });

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    }
  }, []);

  const clientExpectationInputChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.value;
    const updatedData = { ...clientExpectationData };
    updatedData[modifiedKey] = modifiedValue;
    setClientExpectationData(updatedData);
  };

  // useEffect(() => {
  //   if (state.data.data.display_id) {
  //     const payload = {
  //       mpdp_mapping_id: state.data.data.display_id,
  //     };
  //     axios
  //       .post(
  //         `${baseUrl}/leads/clientexpectation`,
  //         payload
  //       )
  //       .then(
  //         (res) => {
  //           // console.log("res.data", res);
  //           if (res.data && res.data.leadsDetails) {
  //             console.log("res.data", res.data.leadsDetails);
  //             if (res.data.leadsDetails.client_expectation !== "") {
  //               setClientExpectationData({
  //                 ...clientExpectationData,
  //                 ...res.data.leadsDetails.client_expectation,
  //               });
  //             }
  //           }
  //         },
  //         (err) => {
  //           openSnackBar("Failed to submit", "success");
  //           console.log("err", err);
  //         }
  //       );
  //   }
  // }, [state.data.data.display_id]);

  const doSubmit = () => {
    console.log("doSubmit");
    if (
      !clientExpectationData.property_location ||
      !clientExpectationData.budget_min ||
      !clientExpectationData.budget_max ||
      !clientExpectationData.area_in_sqft_min ||
      !clientExpectationData.area_in_sqft_max ||
      !clientExpectationData.purpose ||
      !clientExpectationData.corner_plot ||
      !clientExpectationData.expect_loan
    ) {
      console.log("clientExpectationData", clientExpectationData);
      openSnackBar("Enter all inputs", "error");
      return;
    }
    // console.log("clientExpectationData", clientExpectationData, state);
    const payload = {
      mpdp_mapping_id: state.data.data.display_id,
      client_expectation: clientExpectationData,
      user_id: userDetails && userDetails.user_id,
    };
    axios
      .post(
        `${baseUrl}/leads/clientexpectationupdate`,
        payload
      )
      .then(
        (res) => {
          if (res.data && res.data.status) {
            // updateHistory("A");\
            openSnackBar("Updated Successfully", "success");
            backToRoute(
              state &&
                state.data.layoutDetails &&
                (state.data.layoutDetails.type === "CDP" ||
                  state.data.layoutDetails.type === "CEP" ||
                  state.data.layoutDetails.type === "CDEP" ||
                  state.data.layoutDetails.type === "CDEC")
                ? "collectinformationdetails"
                : "leadsdetails",
              state.data
            );
            // backToRoute("leadsdetails", state.data);
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          console.log("err", err);
        }
      );
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const backToRoute = (url, state = {}) => {
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const handleChange = (userUpdatedVal, value) => {
    const updatedData = { ...clientExpectationData };
    console.log("userUpdatedVal", userUpdatedVal);
    let modifiedValue = updatedData["direction_facing"];
    if (userUpdatedVal) {
      modifiedValue.push(value);
    } else {
      const index = modifiedValue.indexOf(value);
      if (index > -1) {
        modifiedValue.splice(index, 1);
      }
    }
    updatedData["direction_facing"] = modifiedValue;
    setClientExpectationData(updatedData);
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Client Expectation</span>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Preferred Property Location (max 1500 characters)"
                  required
                  multiline
                  rows={4}
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  inputProps={{ maxLength: 1500 }}
                  name={"property_location"}
                  onChange={clientExpectationInputChange}
                  value={clientExpectationData.property_location}
                  helperText={`Add multiple Locations by adding comma(,) after each location`}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Budget - Minimum"
                  type="number"
                  required
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  name={"budget_min"}
                  onChange={clientExpectationInputChange}
                  value={clientExpectationData.budget_min}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Budget - Maximum"
                  type="number"
                  required
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  name={"budget_max"}
                  onChange={clientExpectationInputChange}
                  value={clientExpectationData.budget_max}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Area in Sq.ft - Minimum"
                  type="number"
                  required
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  name={"area_in_sqft_min"}
                  onChange={clientExpectationInputChange}
                  value={clientExpectationData.area_in_sqft_min}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Area in Sq.ft - Maximum"
                  type="number"
                  required
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  name={"area_in_sqft_max"}
                  onChange={clientExpectationInputChange}
                  value={clientExpectationData.area_in_sqft_max}
                />
              </div>
              {/* <div className="login-form-div">
                <FormControl style={{ width: "70%" }}>
                  <InputLabel required>Budget</InputLabel>
                  <Select
                    input={<OutlinedInput label="Budget" />}
                    MenuProps={MenuProps}
                    name={"budget"}
                    onChange={clientExpectationInputChange}
                    value={clientExpectationData.budget}
                  >
                    <MenuItem value={"Min"}>Min</MenuItem>
                    <MenuItem value={"Max"}>Max</MenuItem>
                  </Select>
                </FormControl>
              </div> 
              <div className="login-form-div">
                <FormControl style={{ width: "70%" }}>
                  <InputLabel required>Area in Sq.ft</InputLabel>
                  <Select
                    input={<OutlinedInput label="Area in Sq.ft" />}
                    MenuProps={MenuProps}
                    name={"area_in_sqft"}
                    onChange={clientExpectationInputChange}
                    value={clientExpectationData.area_in_sqft}
                  >
                    <MenuItem value={"Min"}>Min</MenuItem>
                    <MenuItem value={"Max"}>Max</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <div className="login-form-div">
                <FormControl style={{ width: "70%" }}>
                  <InputLabel>Urgency to buy</InputLabel>
                  <Select
                    input={<OutlinedInput label="Urgency to buy" />}
                    MenuProps={MenuProps}
                    name={"urgency_to_buy"}
                    onChange={clientExpectationInputChange}
                    value={clientExpectationData.urgency_to_buy}
                  >
                    <MenuItem value={"1"}>1 month</MenuItem>
                    <MenuItem value={"3"}>3 months</MenuItem>
                    <MenuItem value={"6"}>6 months</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="login-form-div">
                <FormControl style={{ alignItems: "center" }}>
                  <FormLabel>Purpose*</FormLabel>
                  <RadioGroup
                    row
                    name={"purpose"}
                    onChange={clientExpectationInputChange}
                    value={clientExpectationData.purpose}
                  >
                    <FormControlLabel
                      value="own"
                      control={<Radio />}
                      label="Own use"
                    />
                    <FormControlLabel
                      value="resale"
                      control={<Radio />}
                      label="Resale"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="login-form-div">
                <FormControl style={{ alignItems: "center" }}>
                  <FormLabel>Corner Plot*</FormLabel>
                  <RadioGroup
                    row
                    name={"corner_plot"}
                    onChange={clientExpectationInputChange}
                    value={clientExpectationData.corner_plot}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="no"
                    />
                    <FormControlLabel
                      value="any"
                      control={<Radio />}
                      label="any"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="login-form-div">
                <div>
                  <FormControl>
                    <FormLabel>Direction facing</FormLabel>
                  </FormControl>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientExpectationData.direction_facing.includes(
                          "N"
                        )}
                        onChange={(e) => handleChange(e.target.checked, "N")}
                      />
                    }
                    label="N"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientExpectationData.direction_facing.includes(
                          "S"
                        )}
                        onChange={(e) => handleChange(e.target.checked, "S")}
                      />
                    }
                    label="S"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientExpectationData.direction_facing.includes(
                          "E"
                        )}
                        onChange={(e) => handleChange(e.target.checked, "E")}
                      />
                    }
                    label="E"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientExpectationData.direction_facing.includes(
                          "W"
                        )}
                        onChange={(e) => handleChange(e.target.checked, "W")}
                      />
                    }
                    label="W"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientExpectationData.direction_facing.includes(
                          "NE"
                        )}
                        onChange={(e) => handleChange(e.target.checked, "NE")}
                      />
                    }
                    label="NE"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientExpectationData.direction_facing.includes(
                          "NW"
                        )}
                        onChange={(e) => handleChange(e.target.checked, "NW")}
                      />
                    }
                    label="NW"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientExpectationData.direction_facing.includes(
                          "SE"
                        )}
                        onChange={(e) => handleChange(e.target.checked, "SE")}
                      />
                    }
                    label="SE"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientExpectationData.direction_facing.includes(
                          "SW"
                        )}
                        onChange={(e) => handleChange(e.target.checked, "SW")}
                      />
                    }
                    label="SW"
                  />
                </div>
              </div>
              <div className="login-form-div">
                <FormControl style={{ alignItems: "center" }}>
                  <FormLabel>Expect loan*</FormLabel>
                  <RadioGroup
                    row
                    name={"expect_loan"}
                    onChange={clientExpectationInputChange}
                    value={clientExpectationData.expect_loan}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="no"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="login-form-div">
                <FormControl>
                  <FormLabel>Need house construction support</FormLabel>
                  <RadioGroup
                    row
                    style={{ justifyContent: "center" }}
                    name={"home_construction_support"}
                    onChange={clientExpectationInputChange}
                    value={clientExpectationData.home_construction_support}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="no"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={(e) =>
                      backRoute(
                        e,
                        state &&
                          state.data.layoutDetails &&
                          (state.data.layoutDetails.type === "CDP" ||
                            state.data.layoutDetails.type === "CEP" ||
                            state.data.layoutDetails.type === "CDEP" ||
                            state.data.layoutDetails.type === "CDEC")
                          ? "collectinformationdetails"
                          : "leadsdetails",
                        state.data
                      )
                    }
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default ClientExpectation;
