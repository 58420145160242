import {
  GET_LEADS_RECEIVED,
  GET_LEADS_CONTACT_LIST,
  GET_LEADS_NOT_CONTACT_LIST,
  GET_LEADS_CONTACT_NO_RESPONSE_LIST,
  GET_LEADS_CONTACT_LOG,
  GET_LEADS_COUNT,
  GET_LEADS_LIST,
  GET_LEADS_INTEREST_COUNT,
} from "./actionType";
import XHR from "../../util/xhr";

export function getLeadsReceived(inputs = {}) {
  return function (dispatch) {
    return XHR.post("leads/received", inputs).then(
      (res) => {
        dispatch({
          type: GET_LEADS_RECEIVED,
          data: {
            leads: res.data.leadsreceived,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getLeadsNotContactList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("leads/listbytype", inputs).then(
      (res) => {
        dispatch({
          type: GET_LEADS_NOT_CONTACT_LIST,
          data: {
            leads_not_contact_list: res.data.leads_contact_list,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getLeadsContactList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("leads/listbytype", inputs).then(
      (res) => {
        dispatch({
          type: GET_LEADS_CONTACT_LIST,
          data: {
            leads_contact_list: res.data.leads_contact_list,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getLeadsContactNoResponseList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("leads/listbytype", inputs).then(
      (res) => {
        dispatch({
          type: GET_LEADS_CONTACT_NO_RESPONSE_LIST,
          data: {
            leads_contact_no_response_list: res.data.leads_contact_list,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getContactLogs(inputs = {}) {
  return function (dispatch) {
    return XHR.post("leads/contactlog", inputs).then(
      (res) => {
        dispatch({
          type: GET_LEADS_CONTACT_LOG,
          data: {
            leads_contact_log: res.data.leads_contact_log,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getLeadsClientDetailsCollectedList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("leads/listbytype", inputs).then(
      (res) => {
        dispatch({
          type: GET_LEADS_CONTACT_NO_RESPONSE_LIST,
          data: {
            leads_contact_no_response_list: res.data.leads_contact_list,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getLeadsSharedLayoutList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("leads/received", inputs).then(
      (res) => {
        dispatch({
          type: GET_LEADS_COUNT,
          data: {
            leads_count: res.data.leadsreceived,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getLeadsList(inputs = {}) {
  return function (dispatch) {
    if (
      inputs.type === "SVU" ||
      inputs.type === "SVD" ||
      inputs.type === "SVC"
    ) {
      return XHR.post("leads/sitevisit/interest/list", inputs).then(
        (res) => {
          dispatch({
            type: GET_LEADS_LIST,
            data: {
              leads_list: res.data.interest_list,
            },
          });
          return Promise.resolve({ status: true, msg: "Success" });
        },
        (err) => {
          return Promise.resolve({
            status: false,
            msg: "Unable to Connect server...",
          });
        }
      );
    } else {
      return XHR.post("leads/list", inputs).then(
        (res) => {
          dispatch({
            type: GET_LEADS_LIST,
            data: {
              leads_list: res.data.leads_list,
            },
          });
          return Promise.resolve({ status: true, msg: "Success" });
        },
        (err) => {
          return Promise.resolve({
            status: false,
            msg: "Unable to Connect server...",
          });
        }
      );
    }
  };
}

export function getLeadsInterestCount(inputs = {}) {
  return function (dispatch) {
    return XHR.post("leads/sitevisit/interest", inputs).then(
      (res) => {
        dispatch({
          type: GET_LEADS_INTEREST_COUNT,
          data: {
            interest_count: res.data.interest_count,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}
