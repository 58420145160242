import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";

// import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

//Other lib imports
import { useGoogleLogout } from "react-google-login";

//Custom imports
import * as CSS from "./Style.js";

const clientId =
  "749795274272-rbo6pubta1t12egbbbs96lj80mdssumj.apps.googleusercontent.com";

const HomeHeader = (props) => {
  const navigate = useNavigate();

  const { showBack = false, backRoute } = props;

  const menuOptions = ["Logout"];

  const [loginState, setLoginState] = useState({
    isLogin: false,
    email: "",
    imageUrl: "",
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClose = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  const onLogout = () => {
    //console.log("Logged out Success");
    // alert("Logged out Successfully ✌");
    localStorage.clear();
    // window.location.reload();
    window.location.href = "/mportal";
  };

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userDetails = token !== "" ? JSON.parse(token) : {};
    if (userDetails && userDetails.isLogined) {
      setLoginState({
        ...loginState,
        isLogin: true,
        email: userDetails.email,
      });
    } else {
      navigate("/login", {
        state: {},
      });
    }
  }, []);

  const menuNavigate = (menuName) => {
    if (menuName === "Logout") {
      onLogout();
    }
  };

  return (
    <CSS.BtnContainer>
      <IconButton
        className={"iconBtns"}
        onClick={() =>
          navigate("/", {
            state: {},
          })
        }
      >
        <HomeIcon />
      </IconButton>
      <IconButton
        className={"iconBtns"}
        onClick={() =>
          navigate("/", {
            state: {},
          })
        }
      >
        <SearchIcon />
      </IconButton>
      {loginState.isLogin && (
        <Avatar
          alt={(loginState && loginState.email) || ""}
          src={(loginState && loginState.imageUrl) || ""}
          onClick={() => setMenuOpen(true)}
          // onMouseOver={() => setMenuOpen(true)}
          ref={menuRef}
        />
      )}
      {showBack && (
        <IconButton className={"iconBtns"} onClick={backRoute}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={menuOpen}
        role={undefined}
        anchorEl={menuRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        menuNavigate(option);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </CSS.BtnContainer>
  );
};

export default HomeHeader;
