import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import WestIcon from "@mui/icons-material/West";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";

//Custom imports
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";
import moment from "moment";
import GoogleLocationPopup from "../../Overlays/GoogleLocationPopup/GoogleLocationPopup";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const AddLayoutStepOne = (props) => {
  const navigate = useNavigate();
  const { nextStep, id = null, isSubmited = false } = props;
  const classes = useStyles();
  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [submitClicked, setSubmitClicked] = useState(false);
  const [openGoogleLocationPopup, setOpenGoogleLocationPopup] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [plots, setPlots] = useState([]);
  const [data, setData] = useState({
    name: "",
    total_area: "",
    total_plots: "",
    gps_location: "",
    user_id: "",
    general_information: {
      project_status: "",
      price_range_from: "",
      price_range_to: "",
      project_size: "",
      started_year: "2023",
      rate_per_sq_feet_from: "",
      rate_per_sq_feet_to: "",
      description: "",
      layout: "",
    },
    amenities: [],
    salient: [],
    bank_details: [],
    gallery: [],
    about_company: {
      address: "",
      no_of_projects: 0,
      logo: "",
      description: "",
      dtcp_number: "",
      rera_no: "",
      approval_name: "",
      approval_no: "",
    },
    contact: [],
  });

  const [promoterOption, setPromoterOption] = useState([]);

  useEffect(() => {
    const promotor_user = localStorage.getItem("realestate_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  const onBackBtnClk = () => {
    navigate("/projects");
  };

  useEffect(() => {
    loadData(id);
  }, [id]);

  useEffect(() => {
    if (isSubmited) {
      setSubmitClicked(isSubmited);
    }
  }, [isSubmited]);

  const loadData = (refid) => {
    getPlotDetails(refid);
    getAddedPlots(refid);
  };

  const getPlotDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.data) {
          setData(res.data.data);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );

    XHR.post("realestate/company/list", {
      onlyPromoters: true,
    }).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.companies) {
          setPromoterOption(res.data.companies);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAddedPlots = (id) => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post("realestate/plots/getplots", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.plots) {
          setPlots(res.data.plots);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleParentChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleChange = (key1, key2, value) => {
    setData({
      ...data,
      [key1]: {
        ...data[key1],
        [key2]: value,
      },
    });
  };

  const handleDateChange = (key1, key2, value) => {
    const newDate = moment(new Date(value)).format("YYYY-MM-DD");
    setData({
      ...data,
      [key1]: {
        ...data[key1],
        [key2]: newDate,
      },
    });
  };

  const onNextBtnClk = () => {
    nextStep(2);
  };

  const onBrochureFileChange = (event) => {
    setIsLoading(true);
    const files = event.target.files[0];
    const maxAllowedSize = 5 * 1024 * 1024;
    if (files.size > maxAllowedSize) {
      openSnackBar("Please upload file less than 5MB", "error");
      return;
    }
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", files);
    formData.append("type", "I");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setIsLoading(false);
        setUploadedFileName(files.name);
        setData({
          ...data,
          general_information: {
            ...data.general_information,
            brochure: (res.data && res.data.url) || false,
          },
        });
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updateProjectData = (addData2) => {
    setIsLoading(true);
    const payload1 = JSON.parse(JSON.stringify({ ...addData2 }));
    XHR.post("realestate/updateinfo", payload1).then(
      (res) => {
        setIsLoading(false);
        loadData(data.id);
        setSubmitClicked(false);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updateProject = () => {
    if (data && data.id) {
      updateProjectData({
        ...data,
      });
    } else {
      addProject({
        ...data,
        created: userDetails && userDetails.user_id,
      });
    }
  };

  const addProject = (addData1) => {
    setIsLoading(true);
    const payload2 = JSON.parse(JSON.stringify({ ...addData1 }));
    if (
      !payload2.name ||
      !payload2.user_id ||
      !payload2.about_company.address ||
      !payload2.general_information.rera_no ||
      !payload2.total_area ||
      !payload2.total_plots ||
      !payload2.general_information.started_year ||
      !payload2.general_information.project_status
    ) {
      openSnackBar("Enter all fields.", "error");
      setIsLoading(false);
      return;
    }
    XHR.post("realestate_v1/add", payload2).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.id) {
          setData({
            ...data,
            id: res.data && res.data.id,
          });
          //   loadData(res.data.id);
          setSubmitClicked(true);
          navigate(`/addLayout`, {
            state: {
              id: res.data.id,
              isSubmited: true,
            },
          });
        } else {
          openSnackBar("Layout name already exists.", "error");
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const setAutoCompleteLocation = (locationData) => {
    if (locationData.gps_location) {
      setData({
        ...data,
        gps_location: locationData.gps_location,
        zoom_level: locationData.zoom_level,
      });
    }
    setOpenGoogleLocationPopup(false);
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <GoogleLocationPopup
        openFlag={openGoogleLocationPopup}
        setCloseFlag={setOpenGoogleLocationPopup}
        setAutoCompleteLocation={setAutoCompleteLocation}
        googleLocationData={(data && data) || {}}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={6} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              {submitClicked === false ? (
                <>
                  <div className="login-form-div">
                    <span className="login-form-heading">
                      Layout Overview ( 1 / 6 )
                    </span>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Layout Name"
                      variant="outlined"
                      required
                      className="login-inputWidth"
                      size="small"
                      value={(data && data.name) || ""}
                      onChange={(e) =>
                        handleParentChange("name", e.target.value)
                      }
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Layout Address with PIN code"
                      multiline
                      rows={4}
                      variant="outlined"
                      required
                      className="login-inputWidth"
                      size="small"
                      value={
                        (data &&
                          data.about_company &&
                          data.about_company.address) ||
                        ""
                      }
                      onChange={(event) =>
                        handleChange(
                          "about_company",
                          "address",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Google Map Location ( Click to set location )"
                      variant="outlined"
                      required
                      className="login-inputWidth"
                      size="small"
                      InputProps={{
                        endAdornment: <RemoveRedEyeIcon />,
                      }}
                      disabled={true}
                      onClick={() => {
                        setOpenGoogleLocationPopup(true);
                      }}
                      value={
                        data && data.gps_location
                          ? `${data.gps_location.lat}, ${data.gps_location.lng}`
                          : ""
                      }
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="RERA Number"
                      variant="outlined"
                      required
                      className="login-inputWidth"
                      size="small"
                      value={
                        (data &&
                          data.general_information &&
                          data.general_information.rera_no) ||
                        ""
                      }
                      onChange={(event) =>
                        handleChange(
                          "general_information",
                          "rera_no",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Other State Approval Name (Optional)"
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      helperText="Ex. CMDA in Tamilnadu, BDA in Karnataka"
                      value={
                        (data &&
                          data.about_company &&
                          data.about_company.approval_name) ||
                        ""
                      }
                      onChange={(event) =>
                        handleChange(
                          "about_company",
                          "approval_name",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="State Approval Number (Optional)"
                      variant="outlined"
                      size="small"
                      className="login-inputWidth"
                      value={
                        (data &&
                          data.about_company &&
                          data.about_company.approval_no) ||
                        ""
                      }
                      onChange={(event) =>
                        handleChange(
                          "about_company",
                          "approval_no",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="login-form-div-with-space">
                    <TextField
                      label="Total Area in Acres"
                      variant="outlined"
                      required
                      size="small"
                      value={(data && data.total_area) || ""}
                      onChange={(e) =>
                        handleParentChange("total_area", e.target.value)
                      }
                      type="number"
                      className={classes.input}
                    />
                    <TextField
                      label="Total Number of Plots"
                      variant="outlined"
                      required
                      size="small"
                      value={(data && data.total_plots) || ""}
                      onChange={(e) =>
                        handleParentChange("total_plots", e.target.value)
                      }
                      type="number"
                      className={classes.input}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Started Year"
                        inputFormat="YYYY"
                        views={["year"]}
                        minDate={
                          new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() - 10
                            )
                          )
                        }
                        maxDate={
                          new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() + 10
                            )
                          )
                        }
                        value={
                          (data &&
                            data.general_information &&
                            data.general_information.started_year) ||
                          ""
                        }
                        onChange={(newValue) =>
                          handleDateChange(
                            "general_information",
                            "started_year",
                            newValue
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="login-form-div">
                    <FormControl style={{ width: "70%" }}>
                      <Autocomplete
                        disablePortal
                        disabled={id}
                        id="combo-box-demo"
                        value={
                          (data &&
                            data.user_id &&
                            promoterOption.find(
                              (o) => o.id === parseInt(data.user_id)
                            )) ||
                          null
                        }
                        options={promoterOption}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            handleParentChange("user_id", newValue.id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Promoter" />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="login-form-div">
                    <FormControl>
                      <FormLabel>Project Status*</FormLabel>
                      <RadioGroup
                        row
                        value={
                          data &&
                          data.general_information &&
                          data.general_information.project_status
                        }
                        onChange={(event) =>
                          handleChange(
                            "general_information",
                            "project_status",
                            event.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="upcoming"
                          control={<Radio />}
                          label="UPCOMING"
                        />
                        <FormControlLabel
                          value="ongoing"
                          control={<Radio />}
                          label="ONGOING"
                        />
                        {/* <FormControlLabel
                          value="completed"
                          control={<Radio />}
                          label="COMPLETED"
                        /> */}
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="login-form-upload-div">
                    <label htmlFor="brochure-button-file">
                      <Button
                        variant="contained"
                        component="span"
                        size="small"
                        className="login-button"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload Brochure
                      </Button>
                    </label>
                    <input
                      accept="application/pdf"
                      style={{ display: "none" }}
                      id="brochure-button-file"
                      onChange={onBrochureFileChange}
                      type="file"
                    />
                    <div>
                      <span className="login-form-div-helper-text">
                        (PDF file less than 5MB)
                      </span>
                    </div>
                    {data &&
                    data.general_information &&
                    data.general_information.brochure ? (
                      <>
                        <label
                          htmlFor="brochure-button-file"
                          style={{ paddingTop: "10px" }}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            size="small"
                            className="login-button"
                            onClick={() =>
                              window.open(data.general_information.brochure)
                            }
                            endIcon={<RemoveRedEyeIcon />}
                          >
                            Preview File
                          </Button>
                        </label>
                        <div>
                          <span className="login-form-div-helper-text">
                            {uploadedFileName} uploaded successfully.
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>
                  {data && data.id ? (
                    <div className="profile-back-next-btn-cntr">
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={onBackBtnClk}
                        className="login-button"
                        startIcon={<WestIcon />}
                      >
                        Back
                      </Button>
                      <Button
                        variant="outlined"
                        size="medium"
                        className="login-button"
                        onClick={() => updateProject()}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={onNextBtnClk}
                        className="login-button"
                        endIcon={<EastIcon />}
                      >
                        Next
                      </Button>
                    </div>
                  ) : (
                    <div className="login-form-div" style={{ marginTop: "5%" }}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => updateProject()}
                        className="login-button"
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="login-form-div">
                    <span className="pdp-prj-by">
                      your layout has been submitted successfully.
                    </span>
                    <br />
                    <span>
                      You can add more details by clicking the “Continue” button
                      or you can add them later by clicking the “Skip for Now”
                      button.
                    </span>
                  </div>
                  <div className="profile-back-next-btn-cntr">
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        setSubmitClicked(false);
                      }}
                      className="login-button"
                      startIcon={<WestIcon />}
                    >
                      Back
                    </Button>
                    <Button
                      variant="outlined"
                      size="medium"
                      className="login-button"
                      onClick={() => {
                        navigate("/promoterWelcome");
                      }}
                    >
                      Skip for now
                    </Button>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={onNextBtnClk}
                      className="login-button"
                      endIcon={<EastIcon />}
                    >
                      Continue
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddLayoutStepOne;
