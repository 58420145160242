import { GET_ALL_ROLE, DELETE_ROLE, GET_ROLE_BY_ID } from "./actionType";
import XHR from "../../util/xhr";

export function getRoleList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/role/get", inputs).then(
      (res) => {
        dispatch({
          type: GET_ALL_ROLE,
          data: {
            role: res.data.role,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function deleteRole(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/role/delete", inputs).then(
      (res) => {
        dispatch({
          type: DELETE_ROLE,
          data: {
            role: res.data.role,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getRoleById(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/role/id", inputs).then(
      (res) => {
        dispatch({
          type: GET_ROLE_BY_ID,
          data: {
            role: res.data.role,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}
