import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import "../Home/PlotCard/PlotCard.css";
import "../SiteVisit/SiteVisit.css";
import "./Role.css";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import Dialog from "../Dialog";

// Store imports
import { getRoleList, deleteRole } from "../../store/role/actionCreator";
import RolePopup from "../Overlays/RolePopup/RolePopup";
import XHR from "../../util/xhr";

import { map } from "lodash";

const Role = (props) => {
  const navigate = useNavigate();

  const { getRoleList, role_list, deleteRole } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState(null);
  const [editRole, setEditRole] = React.useState(null);
  const [openRolePopup, setOpenRolePopup] = React.useState(false);
  const [dialogShow, setDialogShow] = React.useState(false);
  const [dialogProps, setDialogProps] = React.useState({});

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (userDetails) {
      getRoleList({
        promotor_id: userDetails.user_id,
      });
    }
  }, [userDetails]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const payload = {
      ...data,
      promotor_id: userDetails.user_id,
    };
    XHR.post("realestate_v1/role/save", payload).then(
      (res) => {
        if (res.data && res.data.status) {
          setIsLoading(false);
          if (userDetails) {
            getRoleList({
              promotor_id: userDetails.user_id,
            });
          }
          setOpenRolePopup(false);
          openSnackBar(`Role added / updated successfully`, "success");
        } else {
          setIsLoading(false);
          openSnackBar(`Role already exists, Please contact administrator`);
        }
      },
      (err) => {}
    );
  };

  const onDelete = (id) => {
    setDialogShow(true);
    setDialogProps({
      title: `Do you want to delete role?`,
      message: "",
      onClose: (yes) => {
        closeDialog(true);
      },
      button: [
        {
          title: "No",
          click: () => closeDialog(true),
        },
        {
          title: "Yes",
          click: () => deleteRoleData(id),
        },
      ],
    });
  };

  const deleteRoleData = (id) => {
    setIsLoading(true);
    closeDialog();
    deleteRole({ id }).then((res) => {
      if (res.status) {
        setIsLoading(false);
        // Delete success
        if (userDetails) {
          getRoleList({
            promotor_id: userDetails.user_id,
          });
        }
        openSnackBar("User Removed", "success");
      } else {
        setIsLoading(false);
        openSnackBar(`Unable to delete, Please contact administrator`);
      }
    });
  };

  const closeDialog = () => {
    setDialogShow(false);
    setTimeout(() => {
      setDialogProps({});
    }, 100);
  };

  return (
    <>
      <Dialog open={dialogShow} {...dialogProps} />
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <RolePopup
        openFlag={openRolePopup}
        setCloseFlag={setOpenRolePopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
        editRole={editRole}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid item lg={12} md={12} sm={12} xs={12} alignItems="center">
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={true}
              backRoute={(e) => backRoute(e, "promoters", {})}
            />
          </Grid>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-txt">role</span>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="flexEnd"
            alignContent="flex-end"
            direction="column"
            onClick={() => {
              setEditRole(null);
              setOpenRolePopup(true);
            }}
          >
            <IconButton aria-label="delete" style={{ marginRight: 20 }}>
              <ControlPointIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {role_list.length > 0 &&
              map(role_list, function (data, index) {
                return (
                  <Card className="box-card" key={index}>
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{ background: "#ffeedd", color: "#462B00" }}
                          aria-label="recipe"
                        >
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <>
                          <Typography className="box-text" gutterBottom>
                            {data.role_title}
                          </Typography>
                        </>
                      }
                      action={
                        <>
                          <IconButton
                            aria-label="delete"
                            style={{ marginRight: "auto" }}
                            onClick={(e) => onDelete(data.id)}
                          >
                            <DeleteIcon fontSize="large" />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            onClick={(e) => {
                              setOpenRolePopup(true);
                              setEditRole(data);
                            }}
                          >
                            <EditIcon fontSize="large" />
                          </IconButton>
                        </>
                      }
                    />
                  </Card>
                );
              })}
            {role_list && role_list.length === 0 && (
              <Card className="box-card" key={0}>
                <CardContent
                  className="box-content"
                  style={{ textAlign: "center" }}
                >
                  <Typography className="box-text" gutterBottom>
                    No Role
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "42%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role_list: state.role.role_list,
  };
};
const mapDispatchToProps = {
  getRoleList,
  deleteRole,
};
export default connect(mapStateToProps, mapDispatchToProps)(Role);
