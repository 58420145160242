import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import SaveIcon from "@mui/icons-material/Save";

//Custom imports
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import * as CSS from "../style";

import { baseUrl } from "../../../../util/config";

//Other lib imports
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddQuery = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [inputObj, setInputObj] = useState({
    question: "",
    answer: "",
  });

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const doSubmit = () => {
    if (!inputObj.question) {
      openSnackBar("Enter enter the Question", "error");
      return;
    }

    const payload = {
      ...inputObj,
      mpdp_mapping_id: state.data.data.display_id,
    };
    axios
      .post(`${baseUrl}/leads/query/update`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Added Successfully", "success");
            doRoute();
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          //console.log("err", err);
        }
      );
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const doRoute = () => {
    navigate(`/sharedlayoutdetails`, {
      state: state.data,
    });
  };

  const addQueryInputChange = (e) => {
    const updatedInputObj = { ...inputObj };
    updatedInputObj[e.target.name] = e.target.value;
    setInputObj({ ...updatedInputObj });
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Add Query</span>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Question asked by client (max 1500 characters)"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  name={"question"}
                  onChange={addQueryInputChange}
                  value={inputObj.question}
                  inputProps={{ maxLength: 1500 }}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Answer given by you (max 1500 characters)"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  name={"answer"}
                  onChange={addQueryInputChange}
                  value={inputObj.answer}
                  inputProps={{ maxLength: 1500 }}
                />
              </div>

              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={(e) =>
                      backRoute(e, "querylayoutdetails", state.data)
                    }
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default AddQuery;
