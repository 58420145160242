import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import SaveIcon from "@mui/icons-material/Save";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//Custom imports
import moment from "moment";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import * as CSS from "../style";

import { baseUrl } from "../../../../util/config";

//Other lib imports
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddContactLog = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [contactData, setContactData] = useState({
    contact_by: "",
    contact_date: moment(new Date()).format("YYYY-MM-DD"),
    contact_time: "",
    response_status: "1",
    preferred_time: "",
    interest_level: "",
  });
  const [contactByData, setContactByData] = useState([
    {
      id: 1,
      title: "MANUAL CALL",
    },
    {
      id: 2,
      title: "WHATSAPP CALL",
    },
    {
      id: 3,
      title: "WHATSAPP TEXT",
    },
    {
      id: 4,
      title: "EMAIL",
    },
    {
      id: 5,
      title: "AUTOMATED CALL",
    },
  ]);

  const [interestData, setInterestData] = useState([
    {
      id: 1,
      title: "HIGH: READY TO BUY",
    },
    {
      id: 2,
      title: "MEDIUM: LOOKING TO BUY",
    },
    {
      id: 3,
      title: "LOW: JUST CURIOUS",
    },
  ]);

  const handleChange = (input, value) => {
    const newDate = moment(new Date(value)).format("YYYY-MM-DD");
    const updatedData = { ...contactData };
    updatedData[input] = newDate;
    setContactData(updatedData);
  };

  const plotsModalOptionChange = (e) => {
    const modifiedValue = e.target.value;
    const updatedData = { ...contactData };
    updatedData["response_status"] = modifiedValue;
    setContactData(updatedData);
  };

  const handleInputChange = (e) => {
    let modifiedKey = "";
    let modifiedValue = "";
    modifiedKey = e.target.name;
    modifiedValue = e.target.value;
    const updatedData = { ...contactData };
    updatedData[modifiedKey] = modifiedValue;
    setContactData(updatedData);
  };

  const doRoute = () => {
    navigate(`/leadsdetails`, {
      state: state.data,
    });
  };

  const doBackRoute = () => {
    let url = "";
    if (
      state.data.layoutDetails &&
      state.data.layoutDetails.type === "NOTCONTACTED"
    ) {
      url = "leadsnotcontacted";
    } else if (
      state.data.layoutDetails &&
      state.data.layoutDetails.type === "CONTACTEDNORESPONSE"
    ) {
      url = "leadsnotcontacted";
    } else {
      url = "leadscontacted";
    }
    if (url !== "") {
      navigate(`/${url}`, {
        state: {
          id: state.data.layoutDetails.id,
          layout_name: state.data.layoutDetails.layout_name,
          layout_id: state.data.layoutDetails.id,
        },
      });
    }
  };

  const doSubmit = () => {
    if (
      !contactData.contact_date ||
      !contactData.contact_by ||
      !contactData.contact_time ||
      !contactData.response_status
    ) {
      openSnackBar("Enter all inputs", "error");
      return;
    }

    // Submit the request
    onSubmit(contactData);
  };

  const onSubmit = (data) => {
    //console.log(data);
    const payload = {
      ...data,
      mpdp_mapping_id: state.data.data.display_id,
    };
    axios.post(`${baseUrl}/leads/addcontactlog`, payload).then(
      (res) => {
        if (res.data && res.data.status) {
          openSnackBar("Added Successfully", "success");
          doBackRoute();
          // Redirect
        }
      },
      (err) => {
        openSnackBar("Failed to submit", "error");
        //console.log("err", err);
      }
    );
  };

  const handleSelectChange = (e, fields) => {
    if (e.target.value) {
      setContactData({
        ...contactData,
        [fields]: e.target.value,
      });
    }
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Add Contact Log</span>
              </div>
              <div className="login-form-div">
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="made-contact-by">
                    {"Made contact by *"}
                  </InputLabel>
                  <Select
                    labelId="made-contact-by"
                    id="made-contact-by-select"
                    value={
                      (contactData && parseInt(contactData.contact_by)) || ""
                    }
                    label="Made contact by *"
                    onChange={(e) => {
                      handleSelectChange(e, "contact_by");
                    }}
                  >
                    {contactByData.map((option, index) => (
                      <MenuItem value={option.id}>{option.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="login-form-div">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  style={{ width: "100%" }}
                >
                  <DesktopDatePicker
                    minDate={new Date()}
                    label="Date"
                    inputFormat="DD/MM/YYYY"
                    value={contactData.contact_date}
                    onChange={(newValue) => {
                      handleChange("contact_date", newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="login-form-div">
                <TextField
                  id="time"
                  label="Time"
                  type="time"
                  name={"contact_time"}
                  value={(contactData && contactData.contact_time) || ""}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 150 }}
                />
              </div>
              <div className="login-form-div">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={contactData.response_status}
                  onChange={plotsModalOptionChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="RESPONDED"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="NOT RESPONDED"
                  />
                </RadioGroup>
              </div>
              {contactData && contactData.response_status === "1" && (
                <>
                  <div className="login-form-div">
                    <TextField
                      id="preferred_time"
                      label="Preferred contact time"
                      type="time"
                      name={"preferred_time"}
                      value={(contactData && contactData.preferred_time) || ""}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      sx={{ width: 150 }}
                    />
                  </div>
                  <div className="login-form-div">
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="Interest-level">
                        {"Interest level"}
                      </InputLabel>
                      <Select
                        labelId="Interest-level"
                        id="Interest-level-select"
                        value={
                          (contactData &&
                            parseInt(contactData.interest_level)) ||
                          ""
                        }
                        label="Made contact by *"
                        onChange={(e) => {
                          handleSelectChange(e, "interest_level");
                        }}
                      >
                        {interestData.map((option, index) => (
                          <MenuItem value={option.id}>{option.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={doRoute}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default AddContactLog;
