import { GET_ALL_EMPLOYEES, GET_EMPLOYEES_BY_ID } from "./actionType";

export const initialState = {
  employee_list: [],
  employee: {},
};

const layoutReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_ALL_EMPLOYEES: {
      const newState = {
        ...state,
        employee_list: data.users,
      };
      return newState;
    }

    case GET_EMPLOYEES_BY_ID: {
      const newState = {
        ...state,
        employee: data.users.length > 0 ? data.users[0] : {},
      };
      return newState;
    }

    default:
      return state;
  }
};

export default layoutReducer;
