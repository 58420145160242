import styled from 'styled-components';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 10px;
    border-radius: 4px;
    flex: none;
    order: 0;
`;

const CardContainerTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    font-variant: small-caps;
    color: black;
    align-self: center;
`;

const CardContainerContent = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 2px;
    color: black;
`;

const Sqft = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 14px;
    text-align: center;
    color: #382F24;
`;

const ChipContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 2px;
    background: #fff;
    border: ${props => props.plotStatus === 'unsold' ? '2px solid #52643E' : props.plotStatus === 'sold' ? '2px solid #BA1A1A' : '2px solid #543500'};
    border-radius: 32px;
    margin-bottom: 6px;
`;

const ChipContent = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.25px;
    font-variant: small-caps;
    color: ${props => props.plotStatus === 'unsold' ? '#52643E' : props.plotStatus === 'sold' ? '#BA1A1A' : '#543500'};
`;

const CardMessage = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #666666;
`;

const CustomizedDialog = styled(Dialog)`
    & .MuiPaper-root {
        border: 1px solid #FFEEDD;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        padding: 20px;
    }
`;

const UserDialog = styled(Dialog)`
    & .MuiPaper-root {
        border: 1px solid #FFEEDD;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        padding: 20px;
        width: 90%;
    }
`;

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    color: #000000;

    .title {
        font-size: 21px;
        line-height: 25px;
    }

    .info {
        font-size: 12px;
        line-height: 14px;
        opacity: 0.7;
    }
`;

const CloseBtn = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
`;

const customBtn = styled(Button)`
    background-color: #ffddb5 !important;
    color: #835400 !important;
    border-color: wheat !important;
    text-transform: none !important;
    align-self: center;
    
`;

const submitBtn = styled(Button)`
    background-color: #ffddb5 !important;
    color: #835400 !important;
    border-color: wheat !important;
    text-transform: none !important;
    width: 25%;
    place-self: center;
`;

const customAnchor = styled.a`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #835400;
    text-decoration: none;
`;

const errorAlert = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-decoration: none;
    color: red;
    display: block;
    word-wrap: break-word;
    padding-top: 24px;
}
`;

const CardContainerLabel = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    color: black;
`;

const youtubeHelpText = styled.div`
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: -0.25px;
    color: #000000;
    padding: 0px 10px;
`;

export { 
    CardContainer, 
    CardContainerTitle,
    CardContainerContent,
    Sqft,
    ChipContainer,
    ChipContent,
    CardMessage,
    CustomizedDialog,
    TitleContainer,
    CloseBtn,
    customBtn,
    submitBtn,
    customAnchor,
    errorAlert,
    UserDialog,
    CardContainerLabel,
    youtubeHelpText
}