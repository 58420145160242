import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import "../Home/PlotCard/PlotCard.css";
import "../Home/Home.css";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import EmployeePopup from "../Overlays/EmployeePopup/EmployeePopup";
import XHR from "../../util/xhr";

import { map } from "lodash";

const OmtTeam = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // console.log(
  //   "props.location.state.newId",
  //   state && state.id,
  //   state && state.layout_name
  // );

  const [userDetails, setUserDetails] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();
  const [openUserPopup, setOpenUserPopup] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  const menuOptions = [
    {
      menu_url: "addPromoter",
      menu_name: "Add Promoter",
    },
    {
      menu_url: "addLayout",
      menu_name: "Add Layouts",
    },
    {
      menu_url: "projects",
      menu_name: "View Layouts",
    },
  ];

  const doRoute = (e, menu_url) => {
    e.stopPropagation();
    if (menu_url !== "" && menu_url !== "addemployee") {
      navigate(`/${menu_url}`, {
        state: { backUrl: menu_url === "projects" ? "promoters" : "" },
      });
    } else {
      setOpenUserPopup(true);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    XHR.post("realestate_v1/user/add", data).then(
      (res) => {
        if (res.data && res.data.status) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
          openSnackBar(`User already exists, Please contact administrator`);
        }
      },
      (err) => {}
    );
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <EmployeePopup
        openFlag={openUserPopup}
        setCloseFlag={setOpenUserPopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader />
          </Grid>
        </Grid>

        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-header">welcome</span>
        </Grid>

        {userDetails && userDetails.user_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {userDetails.user_name.toLowerCase()}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {menuOptions.length > 0 &&
              map(menuOptions, function (data, index) {
                return (
                  <Button
                    key={index}
                    variant="outlined"
                    className="pc-tlt-button"
                    onClick={(e) => doRoute(e, data.menu_url)}
                  >
                    {data.menu_name}
                  </Button>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "42%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </>
  );
};

export default OmtTeam;
