import { useState, useEffect } from "react";

//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { map } from "lodash";
//Custom imports
import * as CSS from "../Style.js";

const LeadTrackingPopup = (props) => {
  const { openFlag, setCloseFlag } = props;

  const { open, type, data } = openFlag;

  const getTitle = (content_type) => {
    if (content_type === 1) {
      return "View Leads Details";
    } else if (content_type === 2) {
      return "View Leads Expectation";
    } else if (content_type === 3) {
      return "View Shared Details";
    } else if (content_type === 4) {
      return "Site Visit Status";
    }

    return "";
  };

  const getGender = (gender) => {
    if (gender == "M") {
      return "Male";
    } else if (gender == "F") {
      return "Female";
    } else {
      return "Others";
    }
  };

  const getDirection = (direction) => {
    if (direction == "N") {
      return "North";
    } else if (direction == "S") {
      return "South";
    } else if (direction == "E") {
      return "East";
    } else if (direction == "W") {
      return "West";
    } else if (direction == "NE") {
      return "North East";
    } else if (direction == "NW") {
      return "North West";
    } else if (direction == "SE") {
      return "South East";
    } else if (direction == "SW") {
      return "South West";
    }
  };

  return (
    <>
      <CSS.CustomizedDialog
        open={open}
        onClose={() =>
          setCloseFlag({
            open: false,
            type: "",
            data: null,
          })
        }
        maxWidth={"lg"}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="1em 0 1em 0;"
        >
          <CSS.CloseBtn>
            <IconButton
              aria-label="closePopup"
              onClick={() =>
                setCloseFlag({
                  open: false,
                  type: "",
                  data: null,
                })
              }
            >
              <HighlightOffIcon />
            </IconButton>
          </CSS.CloseBtn>
          <CSS.TitleContainer>
            <span className="title" style={{ fontWeight: "bolder" }}>
              {getTitle(type)}
            </span>
          </CSS.TitleContainer>
          {type == 1 && (
            <CSS.CardContainer>
              <CSS.CardContainerLabel>
                Collected By: {data.client_expectation_by || ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Name: {data.client_details.name || ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Email Id: {data.client_details.email_id || ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Mobile No: {data.client_details.mobile_no || ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Location: {data.client_details.location || ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Address: {data.client_details.address || ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Gender: {getGender(data.client_details.gender)}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Marital Status:{" "}
                {data.client_details.marital_status == "M"
                  ? "Married"
                  : data.client_details.marital_status == "S"
                  ? "Single"
                  : ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Age: {data.client_details.age || ""}
              </CSS.CardContainerLabel>

              <CSS.CardContainerLabel>
                Kids: {data.client_details.kids || ""}
              </CSS.CardContainerLabel>

              <CSS.CardContainerLabel>
                Profession: {data.client_details.profession}
              </CSS.CardContainerLabel>

              <CSS.CardContainerLabel>
                Income per annum: {data.client_details.income_per_annum}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel>
                Preferred communication channel:{" "}
                {data.client_details.preferred_communication}
              </CSS.CardContainerLabel>
            </CSS.CardContainer>
          )}

          {type == 2 && (
            <>
              {data.client_expectation &&
                data.client_expectation.length > 0 &&
                map(data.client_expectation, function (cdata, index) {
                  return (
                    <Card key={index}>
                      <CardHeader
                        title={
                          <>
                            <Typography className="box-text" gutterBottom>
                              Collected by : {data.client_expectation_by || ""}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Preferred Property Location :{" "}
                              {cdata.property_location || ""}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Budget :{" "}
                              {`${cdata.budget_min} to ${cdata.budget_max} ` ||
                                ""}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Area in Sq.ft. :{" "}
                              {`${cdata.area_in_sqft_min} to ${cdata.area_in_sqft_max} ` ||
                                ""}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Purpose : {cdata.purpose || ""}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Corner Plot : {cdata.corner_plot || ""}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Direction facing :{" "}
                              {map(
                                cdata.direction_facing,
                                function (direction) {
                                  return getDirection(direction) + ", ";
                                }
                              )}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Expect loan : {cdata.expect_loan || ""}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Need house construction support :{" "}
                              {cdata.home_construction_support || ""}
                            </Typography>
                            <Typography className="box-text" gutterBottom>
                              Urgency to buy :{" "}
                              {`${cdata.urgency_to_buy} Months ` || ""}
                            </Typography>
                          </>
                        }
                      />
                    </Card>
                  );
                })}
              {data.client_expectation &&
                data.client_expectation.length === 0 && (
                  <Card className="box-card" key={0}>
                    <CardContent
                      className="box-content"
                      style={{ textAlign: "center" }}
                    >
                      <Typography className="box-text" gutterBottom>
                        No Data
                      </Typography>
                    </CardContent>
                  </Card>
                )}
            </>
          )}

          {type == 3 && (
            <CSS.CardContainer>
              <CSS.CardContainerLabel>
                Collected By: {data.details_shared_by || ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel
                style={{
                  fontWeight: "600",
                  textAlign: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                  textTransform: "uppercase",
                }}
              >
                list of Shared Info
              </CSS.CardContainerLabel>
              {data.shared_details &&
                data.shared_details.detailsShared &&
                data.shared_details.detailsShared.length > 0 &&
                map(data.shared_details.detailsShared, function (sdata, index) {
                  return sdata.value ? (
                    <CSS.CardContainerLabel>
                      {sdata.label}
                    </CSS.CardContainerLabel>
                  ) : null;
                })}
            </CSS.CardContainer>
          )}

          {type == 4 && (
            <CSS.CardContainer>
              <CSS.CardContainerLabel>
                Supported By:{" "}
                {data.site_visit_details.supportPerson === "myself"
                  ? "myself"
                  : data.site_visit_details.personName || ""}
              </CSS.CardContainerLabel>
              <CSS.CardContainerLabel
                style={{
                  fontWeight: "600",
                  textAlign: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                  textTransform: "uppercase",
                }}
              >
                Plots interested in buying
              </CSS.CardContainerLabel>
              {data.site_visit_details &&
                data.site_visit_details.buyingInterest &&
                data.site_visit_details.buyingInterest.length > 0 &&
                map(
                  data.site_visit_details.buyingInterest,
                  function (bdata, index) {
                    return bdata ? (
                      <CSS.CardContainerLabel>{bdata}</CSS.CardContainerLabel>
                    ) : null;
                  }
                )}
            </CSS.CardContainer>
          )}
        </Grid>
      </CSS.CustomizedDialog>
    </>
  );
};

export default LeadTrackingPopup;
