import { GET_ALL_EMPLOYEES, DELETE_EMPLOYEES, GET_EMPLOYEES_BY_ID } from "./actionType";
import XHR from "../../util/xhr";

export function getEmployeeList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate/promoters/users", inputs).then(
      (res) => {
        dispatch({
          type: GET_ALL_EMPLOYEES,
          data: {
            users: res.data.users,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function deleteEmployee(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate/company/delete", inputs).then(
      (res) => {
        dispatch({
          type: DELETE_EMPLOYEES,
          data: {
            users: res.data.users,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getEmployeeById(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/user/id", inputs).then(
      (res) => {
        dispatch({
          type: GET_EMPLOYEES_BY_ID,
          data: {
            users: res.data.users,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}
