import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import SaveIcon from "@mui/icons-material/Save";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { baseUrl } from "../../../../util/config";

//Custom imports
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import * as CSS from "../style";

//Other lib imports
import axios from "axios";

const ConfirmClientApproval = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [inputObj, setInputObj] = useState({
    clientApproved: "no",
    approvalDate: null,
    correctionsDate: null,
    corrections: "",
  });

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [dataDetails, setDataDetails] = React.useState(null);
  const [leadsDetails, setLeadsDetails] = React.useState(null);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  useEffect(() => {
    if (state.layoutDetails) {
      setLayoutDetails({
        ...layoutDetails,
        ...state.layoutDetails,
      });
    }

    if (state.data) {
      setDataDetails({
        ...dataDetails,
        ...state.data,
      });
    }
  }, [state]);

  useEffect(() => {
    if (layoutDetails && layoutDetails.id) {
      const payload = {
        layout_id: layoutDetails.id,
        mpdp_mapping_id: state.data.display_id,
      };
      axios.post(`${baseUrl}/leads/list`, payload).then(
        (res) => {
          console.log("res", res.data && res.data);
          if (res.data && res.data.leads_list) {
            if (res.data.leads_list.length > 0) {
              setLeadsDetails(res.data.leads_list[0]);
            }
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  }, [layoutDetails]);

  const doSubmit = () => {
    if (inputObj.clientApproved === "yes") {
      if (!inputObj.approvalDate) {
        openSnackBar("Please select approval date", "error");
        return;
      }
    } else if (inputObj.clientApproved === "no") {
      if (!inputObj.correctionsDate || !inputObj.corrections) {
        openSnackBar("Please fill all data", "error");
        return;
      }
    } else {
      openSnackBar("Please fill all data", "error");
      return;
    }

    const payload = {
      is_approved: inputObj.clientApproved === "yes" ? 3 : 2,
      type: "CA",
      data: {
        ...inputObj,
      },
      mpdp_mapping_id: state.data.display_id,
    };
    axios
      .post(
        `${baseUrl}/leads/conversion/document/update`,
        payload
      )
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          //console.log("err", err);
        }
      );
  };

  const backRoute = (e, url, data = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: {
          layoutDetails: {
            id: layoutDetails.id,
            layout_name: layoutDetails.layout_name,
            layout_id: layoutDetails.id,
            type: layoutDetails.type,
          },
          data: data,
        },
      });
    }
  };

  const doRoute = (e, menu_url, data = {}) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: data,
      });
    }
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                  Confirm Client Approval
                </span>
              </div>
              <div className="login-form-div">
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="made-contact-by">
                    {"Client Approved"}
                  </InputLabel>
                  <Select
                    labelId="made-contact-by"
                    id="made-contact-by-select"
                    label="Client Approved"
                    onChange={(e) =>
                      setInputObj({
                        ...inputObj,
                        clientApproved: e.target.value,
                      })
                    }
                    value={inputObj.clientApproved}
                  >
                    <MenuItem value={"yes"}>yes</MenuItem>
                    <MenuItem value={"no"}>no</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputObj.clientApproved === "yes" ? (
                <>
                  <div className="login-form-div">
                    <TextField
                      label="Approval date"
                      required={true}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setInputObj({
                          ...inputObj,
                          approvalDate: e.target.value,
                        })
                      }
                      value={inputObj.approvalDate}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="login-form-div">
                    <TextField
                      label="Corrections given date"
                      required={true}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setInputObj({
                          ...inputObj,
                          correctionsDate: e.target.value,
                        })
                      }
                      value={inputObj.correctionsDate}
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Corrections (max 1500 characters)"
                      multiline
                      rows={4}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      name={"question"}
                      onChange={(e) =>
                        setInputObj({
                          ...inputObj,
                          corrections: e.target.value,
                        })
                      }
                      value={inputObj.corrections}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                </>
              )}

              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={(e) =>
                      doRoute(e, "registrationdocuments", {
                        id: layoutDetails.id,
                        layout_name: layoutDetails.layout_name,
                        layout_id: layoutDetails.id,
                        type: "CRD",
                      })
                    }
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default ConfirmClientApproval;
