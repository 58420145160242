import { useState, useEffect } from "react";
//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";

//Custom imports
import * as CSS from "../Style.js";

const PlotsPopup = (props) => {
  const { openFlag, setCloseFlag, onSubmit, openSnackBar, details, userList } =
    props;
  const [plotsPopupData, setPlotsPopupData] = useState({
    plot_status: 0,
    uploaded_id: 0,
    buyer_name: "",
    buyer_mobile: "",
    buyer_email: "",
    blocked_by_promoter: false,
    seller: [],
  });

  const plotsModalOptionChange = (e) => {
    if (details && details.plot_status == 2 && e.target.value == 0) {
      const response = window.confirm(
        "Are you sure you want to do delete buyer and seller details?"
      );
      if (response) {
        const updatedData = { ...plotsPopupData };
        updatedData["plot_status"] = parseInt(e.target.value);
        setPlotsPopupData(updatedData);
      } else {
        const updatedData = { ...plotsPopupData };
        updatedData["plot_status"] = parseInt(details.plot_status);
        setPlotsPopupData(updatedData);
      }
    } else if (details && details.plot_status == 2 && e.target.value == 1) {
      const response = window.confirm("Bought by same Buyer?");
      if (response) {
        const updatedData = { ...plotsPopupData };
        updatedData["plot_status"] = parseInt(e.target.value);
        setPlotsPopupData(updatedData);
      } else {
        const updatedData = { ...plotsPopupData };
        updatedData["plot_status"] = parseInt(0);
        setPlotsPopupData(updatedData);
      }
    } else {
      const updatedData = { ...plotsPopupData };
      updatedData["plot_status"] = parseInt(e.target.value);
      setPlotsPopupData(updatedData);
    }
  };

  const plotsModalInputChange = (e) => {
    let modifiedKey = "";
    let modifiedValue = "";
    modifiedKey = e.target.name;
    modifiedValue = e.target.value;
    const updatedData = { ...plotsPopupData };
    updatedData[modifiedKey] = modifiedValue;
    setPlotsPopupData(updatedData);
  };

  const checkboxChange = (e) => {
    let modifiedKey = "";
    let modifiedValue = "";
    modifiedKey = "blocked_by_promoter";
    modifiedValue = e.target.checked;
    const updatedData = { ...plotsPopupData };
    updatedData[modifiedKey] = modifiedValue;
    setPlotsPopupData(updatedData);
  };

  useEffect(() => {
    if (details && details.plot_status !== "") {
      setPlotsPopupData({
        ...plotsPopupData,
        plot_status: details.plot_status,
        uploaded_id: details.id,
        buyer_name: details.buyer_name,
        buyer_mobile: details.buyer_mobile,
        buyer_email: details.buyer_email,
        seller: details.seller,
        blocked_by_promoter: details.blocked_by_promoter == 0 ? false : true,
      });
    }
  }, [details]);

  const doSubmit = () => {
    if (
      plotsPopupData.plot_status != 0 &&
      !plotsPopupData.blocked_by_promoter &&
      (plotsPopupData.buyer_name === "" ||
        plotsPopupData.buyer_email === "" ||
        plotsPopupData.buyer_mobile === "")
    ) {
      openSnackBar("Please fill all input", "error");
      return;
    }
    let inputdata = plotsPopupData;
    if (plotsPopupData.plot_status == 0) {
      inputdata.buyer_email = "";
      inputdata.buyer_name = "";
      inputdata.buyer_mobile = "";
      inputdata.seller = [];
      inputdata.blocked_by_promoter = false;
    }
    onSubmit(inputdata);
  };

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <Grid
        container
        direction="column"
        gap="1em"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.CardContainer>
          <CSS.CardContainerTitle>status</CSS.CardContainerTitle>
        </CSS.CardContainer>
        <FormControl style={{ paddingLeft: 24 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={plotsPopupData.plot_status}
            name="radio-buttons-group"
            onChange={plotsModalOptionChange}
          >
            <FormControlLabel
              disabled={details && details.plot_status == 1}
              value={0}
              control={<Radio />}
              label="Available"
            />
            <FormControlLabel
              disabled={details && details.plot_status == 1}
              value={2}
              control={<Radio />}
              label="Booked"
            />
            <FormControlLabel
              disabled={details && details.plot_status == 1}
              value={1}
              control={<Radio />}
              label="Sold"
            />
          </RadioGroup>
        </FormControl>
        {plotsPopupData.plot_status != 0 && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={plotsPopupData.blocked_by_promoter}
                  onChange={(e) => checkboxChange(e)}
                />
              }
              label={"Blocked by promoter"}
            />

            <CSS.CardContainer>
              <CSS.CardContainerTitle>
                buyer details{" "}
                {!plotsPopupData.blocked_by_promoter && (
                  <span style={{ color: "red" }}>mandatory</span>
                )}
              </CSS.CardContainerTitle>
            </CSS.CardContainer>

            <TextField
              name={"buyer_name"}
              onChange={plotsModalInputChange}
              value={plotsPopupData.buyer_name}
              disabled={details && details.plot_status == 1}
              label="Buyer Name"
              fullWidth={true}
            />
            <TextField
              name={"buyer_email"}
              onChange={plotsModalInputChange}
              value={plotsPopupData.buyer_email}
              disabled={details && details.plot_status == 1}
              label="Buyer Email"
              fullWidth={true}
            />
            <TextField
              name={"buyer_mobile"}
              onChange={plotsModalInputChange}
              value={plotsPopupData.buyer_mobile}
              disabled={details && details.plot_status == 1}
              label="Buyer Mobile"
              fullWidth={true}
            />
            <CSS.CardContainer>
              <CSS.CardContainerTitle>seller details</CSS.CardContainerTitle>
            </CSS.CardContainer>
            <FormControl style={{ width: "70%" }}>
              <Select
                closeMenuOnSelect={false}
                isMulti
                options={userList}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                defaultValue={plotsPopupData.seller}
                isDisabled={
                  !plotsPopupData.buyer_name ||
                  !plotsPopupData.buyer_email ||
                  !plotsPopupData.buyer_mobile ||
                  (details && details.plot_status == 1)
                }
                onChange={(event) => {
                  var keyArray = event.map(({ id, name }) => ({ id, name }));
                  const updatedData = { ...plotsPopupData };
                  updatedData["seller"] = keyArray;
                  setPlotsPopupData(updatedData);
                }}
              />
            </FormControl>
          </>
        )}

        <CSS.customBtn
          variant="outlined"
          onClick={doSubmit}
          style={{ alignSelf: "center" }}
        >
          Save
        </CSS.customBtn>
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default PlotsPopup;
