import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TuneIcon from "@mui/icons-material/Tune";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import SaveIcon from "@mui/icons-material/Save";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import dayjs from "dayjs";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

//Custom imports
import HomeHeader from "../../../HeaderMenu/HomeHeader";
import "../../../Home/PlotCard/PlotCard.css";
import "../../Leads.css";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import moment from "moment";

//Other lib imports
import axios from "axios";

import { baseUrl } from "../../../../util/config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, poltName, theme) {
  console.log("name ===", name, poltName);
  return {
    fontWeight:
      poltName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const PurchaseDetails = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const theme = useTheme();

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);
  const [dataDetails, setDataDetails] = React.useState(null);
  const [leadsDetails, setLeadsDetails] = React.useState(null);

  const [reason, setReason] = React.useState("");
  const [reasonArr, setReasonArr] = React.useState([]);

  const [holdReason, setHoldReason] = React.useState("");
  const [holdReasonArr, setHoldReasonArr] = React.useState([]);

  const [inputObj, setInputObj] = React.useState({
    plots: [],
    buyingInfo: [
      {
        label: "Booking amount",
        checked: false,
      },
      {
        label: "Allotment amount",
        checked: false,
      },
      {
        label: "Documents required",
        checked: false,
      },
      {
        label: "Full amount",
        checked: false,
      },
    ],
    tentativeRegDate: null,
    takingLoan: "yes",
    is_purchase_decision: "",
    dateTime: null,
  });
  const [plotsDetails, setPlotsDetails] = React.useState([]);
  const [poltName, setPlotName] = React.useState([]);

  const addReason = () => {
    if (!reason) {
      openSnackBar("Please enter the reason", "error");
      return;
    }
    setReasonArr([...reasonArr, reason]);
    setReason("");
  };

  const addHoldReason = () => {
    if (!holdReason) {
      openSnackBar("Please enter the reason", "error");
      return;
    }
    setHoldReasonArr([...holdReasonArr, holdReason]);
    setHoldReason("");
  };

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state.layoutDetails) {
      setLayoutDetails({
        ...layoutDetails,
        ...state.layoutDetails,
      });
    }

    if (state.data) {
      setDataDetails({
        ...dataDetails,
        ...state.data,
      });
    }
  }, [state]);

  React.useEffect(() => {
    if (layoutDetails && layoutDetails.id) {
      const payload = {
        layout_id: layoutDetails.id,
        mpdp_mapping_id: state.data.display_id,
      };
      axios.post(`${baseUrl}/leads/list`, payload).then(
        (res) => {
          console.log("res", res.data && res.data);
          if (res.data && res.data.leads_list) {
            if (res.data.leads_list.length > 0) {
              setLeadsDetails(res.data.leads_list[0]);
            }
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  }, [layoutDetails]);

  React.useEffect(() => {
    if (leadsDetails && leadsDetails.is_purchase_decision) {
      setInputObj({
        ...inputObj,
        is_purchase_decision: leadsDetails.is_purchase_decision,
        ...leadsDetails.purchase_details,
      });
    }
    if (leadsDetails && leadsDetails.purchase_details.reason) {
      setReasonArr(leadsDetails.purchase_details.reason);
    }
    if (leadsDetails && leadsDetails.purchase_details.holdReason) {
      setHoldReasonArr(leadsDetails.purchase_details.holdReason);
    }

    if (leadsDetails && leadsDetails.purchase_details) {
      setPlotName(leadsDetails.purchase_details.plots || []);
    }

    if (leadsDetails && leadsDetails.plots.length > 0) {
      setPlotsDetails(leadsDetails.plots);
    }
  }, [leadsDetails]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const plotsChange = (e, value) => {
    let updatedPlot = inputObj;
    let plotsData = updatedPlot["plots"];
    if (e.target.checked) {
      plotsData.push(value);
    } else {
      const index = plotsData.indexOf(value);
      if (index > -1) {
        plotsData.splice(index, 1);
      }
    }
    setInputObj({ ...inputObj, plots: plotsData });
  };

  const buyingInfoChange = (e, index) => {
    let updatedBuyingInfo = [...inputObj.buyingInfo];
    updatedBuyingInfo[index].checked = e.target.checked;
    setInputObj({ ...inputObj, buyingInfo: updatedBuyingInfo });
  };

  const onSubmit = () => {
    const outputObj = { ...inputObj, plots: [...poltName] };
    if (!outputObj.is_purchase_decision) {
      openSnackBar("Please select the option", "error");
      return;
    }

    if (outputObj.is_purchase_decision === "2" && reasonArr.length === 0) {
      openSnackBar("Please enter the reason", "error");
      return;
    }

    if (outputObj.is_purchase_decision === "3" && !outputObj.dateTime) {
      openSnackBar("Please select followupDate", "error");
      return;
    }

    if (
      outputObj.is_purchase_decision === "1" &&
      (outputObj.plots.length === 0 ||
        !outputObj.buyingInfo.some(({ checked }) => checked) ||
        !outputObj.takingLoan)
    ) {
      openSnackBar("Please Enter all fields", "error");
      return;
    }

    const payload = {
      is_purchase_decision: outputObj.is_purchase_decision,
      data: {
        ...outputObj,
        reason: reasonArr,
        holdReason: holdReasonArr,
      },
      mpdp_mapping_id: state.data.display_id,
      user_id: userDetails && userDetails.user_id,
    };
    axios
      .post(`${baseUrl}/leads/purchase/update`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          //console.log("err", err);
        }
      );
  };

  const doRoute = (e, menu_url, state) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: {
          id: layoutDetails.id,
          layout_name: layoutDetails.layout_name,
          project_hash: layoutDetails.project_hash,
          data: state,
        },
      });
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPlotName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  console.log("layoutDetails", layoutDetails, reasonArr);

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={false}
              backRoute={(e) =>
                backRoute(e, "sharedlayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  type: layoutDetails.type,
                })
              }
            />
          </Grid>
        </Grid>

        {userDetails && userDetails.user_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {userDetails.user_name.toLowerCase()}
            </span>
          </Grid>
        )}

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 1em"
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "leads", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Leads Home
            </Link>
            {/* <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "purchase", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Purchase Decision
            </Link> */}
            {/* {layoutDetails && layoutDetails.type !== "NCSV" && (
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb breadcrumb-underline"
                onClick={(e) =>
                  backRoute(e, "sitevisitcontactlayout", {
                    id: layoutDetails.id,
                    layout_name: layoutDetails.layout_name,
                    layout_id: layoutDetails.id,
                  })
                }
              >
                Scheduled Site Visit
              </Link>
            )} */}
            {/* {layoutDetails && layoutDetails.type === "PDP" && (
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb breadcrumb-underline"
                onClick={(e) =>
                  backRoute(e, "purchaselist", {
                    id: layoutDetails.id,
                    layout_name: layoutDetails.layout_name,
                    layout_id: layoutDetails.id,
                    type: layoutDetails.type,
                  })
                }
              >
                No of Leads Decision is Pending
              </Link>
            )} */}
            {layoutDetails && (
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb breadcrumb-underline"
                onClick={(e) =>
                  backRoute(e, "purchaselist", {
                    id: layoutDetails.id,
                    layout_name: layoutDetails.layout_name,
                    layout_id: layoutDetails.id,
                    type: layoutDetails.type,
                  })
                }
              >
                {layoutDetails && layoutDetails.type === "PDP"
                  ? "No of Leads Decision is Pending"
                  : layoutDetails && layoutDetails.type === "PDB"
                  ? "No of Leads Decided to Buy"
                  : layoutDetails && layoutDetails.type === "PDNB"
                  ? "No of Leads Not Interested to Buy"
                  : "No of Leads Put The Decision On Hold"}
              </Link>
            )}

            <Typography color="text.primary" className="breadcrumb">
              {dataDetails && dataDetails.display_mobile}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            <Button variant="outlined" className="pc-leads-button">
              {dataDetails && dataDetails.display_mobile}
            </Button>
          </Grid>
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {dataDetails && (
              <Card className="box-card">
                <CardHeader
                  title={
                    <>
                      {dataDetails.display_name && (
                        <Typography className="box-text" gutterBottom>
                          Name : {dataDetails.display_name}
                        </Typography>
                      )}
                      <Typography className="box-text" gutterBottom>
                        Mobile No : {dataDetails.display_mobile}
                      </Typography>
                      {dataDetails.display_name && (
                        <Typography className="box-text" gutterBottom>
                          Email Id : {dataDetails.display_email}
                        </Typography>
                      )}
                      <Typography className="box-text" gutterBottom>
                        Date time :{" "}
                        {moment(new Date(dataDetails.req_datetime)).format(
                          "DD/MM/YYYY h:m A"
                        )}
                      </Typography>
                      <Typography className="box-text" gutterBottom>
                        Leads Through : {dataDetails.leads_through}
                      </Typography>
                      {dataDetails.type === "L" && (
                        <>
                          <Typography className="box-text" gutterBottom>
                            Leads Through Name : {dataDetails.leads_name}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Leads Through Email : {dataDetails.leads_email}
                          </Typography>
                          {dataDetails.leads_mobile && (
                            <Typography className="box-text" gutterBottom>
                              Leads Through Mobile : {dataDetails.leads_mobile}
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  }
                />
              </Card>
            )}
          </Grid>
          <Grid container spacing={2} style={{ width: "90%" }}>
            <Grid item xs={9}>
              <div>
                <span className="login-form-heading">Purchase decision?</span>
              </div>
              <div>
                <RadioGroup
                  columns
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={inputObj.is_purchase_decision}
                  onChange={(e) =>
                    setInputObj({
                      ...inputObj,
                      is_purchase_decision: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Decided to Buy"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Not Interested to Buy"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Put The Decision On Hold"
                  />
                </RadioGroup>
              </div>
            </Grid>
            <Grid item xs={9}>
              {inputObj && parseInt(inputObj.is_purchase_decision) === 2 && (
                <>
                  <div>
                    <span className="login-form-heading">
                      Reasons For No of Leads Not Interested to Buy
                    </span>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Reason (max 1500 characters)"
                      multiline
                      rows={4}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      name={"reason"}
                      onChange={(e) => setReason(e.target.value)}
                      value={reason}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                  <div className="login-form-div">
                    <Button
                      variant="outlined"
                      size="medium"
                      className="login-button"
                      onClick={(e) => addReason()}
                    >
                      Add Reason
                    </Button>
                  </div>
                  <div className="login-form-div">
                    <ul style={{ textAlign: "left" }}>
                      {reasonArr &&
                        reasonArr.map((element) => <li>{element}</li>)}
                    </ul>
                  </div>
                </>
              )}
              {inputObj && parseInt(inputObj.is_purchase_decision) === 3 && (
                <>
                  <div>
                    <span className="login-form-heading">
                      Reasons For On Hold
                    </span>
                  </div>
                  <div className="login-form-div">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Follow up Date, Time"
                        value={inputObj.dateTime}
                        onChange={(newValue) => {
                          setInputObj({ ...inputObj, dateTime: newValue });
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Reason (max 1500 characters)"
                      multiline
                      rows={4}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      name={"reason"}
                      onChange={(e) => setHoldReason(e.target.value)}
                      value={holdReason}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                  <div className="login-form-div">
                    <Button
                      variant="outlined"
                      size="medium"
                      className="login-button"
                      onClick={(e) => addHoldReason()}
                    >
                      Add Reason
                    </Button>
                  </div>
                  <div className="login-form-div">
                    <ul style={{ textAlign: "left" }}>
                      {holdReasonArr &&
                        holdReasonArr.map((element) => <li>{element}</li>)}
                    </ul>
                  </div>
                </>
              )}
              {inputObj && parseInt(inputObj.is_purchase_decision) === 1 && (
                <>
                  <div>
                    <span className="login-form-heading">Buying info</span>
                  </div>
                  <div className="login-form-div">
                    <FormControl>
                      <InputLabel id="demo-multiple-chip-label">
                        Plots selected*
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={poltName}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {plotsDetails.map((data) => (
                          <MenuItem
                            key={data.name}
                            value={data.name}
                            style={getStyles(data.name, poltName, theme)}
                          >
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {/* <div className="login-form-div">
                    <div>
                      <FormControl>
                        <FormLabel>Plots selected*</FormLabel>
                      </FormControl>
                    </div>
                    <div
                      style={{ display: "flex", flexFlow: "row wrap", gap: 10 }}
                    >
                      {plotsDetails &&
                        plotsDetails.map((item, index) => {
                          return (
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={inputObj.plots.includes(item.id)}
                                    onChange={(e) => plotsChange(e, item.id)}
                                  />
                                }
                                label={item.name}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div> */}
                  <div className="login-form-div">
                    <div>
                      <FormControl>
                        <FormLabel>Convey buying information*</FormLabel>
                      </FormControl>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {inputObj.buyingInfo.map((item, index) => {
                        return (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item.checked}
                                  onChange={(e) => buyingInfoChange(e, index)}
                                />
                              }
                              label={item.label}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Tentative date for registration"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      onChange={(e) =>
                        setInputObj({
                          ...inputObj,
                          tentativeRegDate: e.target.value,
                        })
                      }
                      value={inputObj.tentativeRegDate}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                  <div className="login-form-div">
                    <InputLabel>{"Taking loan *"}</InputLabel>
                    <RadioGroup
                      row
                      style={{
                        display: "flex",
                      }}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={inputObj.takingLoan}
                      onChange={(e) =>
                        setInputObj({ ...inputObj, takingLoan: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                </>
              )}
              <div style={{ marginTop: 30, width: 200 }}>
                <Button
                  variant="outlined"
                  className="pc-tlt-button"
                  onClick={(e) => onSubmit()}
                >
                  {"SUBMIT"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leads_received: state.layout.leads_received,
  };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDetails);
