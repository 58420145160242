import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import AssignmentIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RateReviewIcon from '@mui/icons-material/RateReview';
import CommuteIcon from '@mui/icons-material/Commute';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Tooltip from '@mui/material/Tooltip';

//Custom imports
import * as CSS from "./Style.js";

const clientId = "749795274272-rbo6pubta1t12egbbbs96lj80mdssumj.apps.googleusercontent.com";

const LeadsHeader = (props) => {
  const navigate = useNavigate();

  const { showBack = false, backRoute } = props;

  const menuOptions = ["Logout"];

  const [loginState, setLoginState] = useState({
    isLogin: false,
    email: "",
    imageUrl: "",
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClose = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  const onLogout = () => {
    //console.log("Logged out Success");
    // alert("Logged out Successfully ✌");
    localStorage.clear();
    window.location.href = "/mportal";
  };

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userDetails = token !== "" ? JSON.parse(token) : {};
    if (userDetails && userDetails.isLogined) {
      setLoginState({
        ...loginState,
        isLogin: true,
        email: userDetails.email,
      });
    } else {
      navigate("/login", {
        state: {},
      });
    }
  }, []);

  const menuNavigate = (menuName) => {
    if (menuName === "Logout") {
      onLogout();
    }
  };

  return (
    <CSS.BtnContainer>
      <IconButton
        className={"iconBtns"}
        onClick={() =>
          navigate("/", {
            state: {},
          })
        }
      >
        <HomeIcon />
      </IconButton>
      {/* <Tooltip title="LEADS RECEIVED">
        <IconButton className={"iconBtns"}>
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="COLLECT INFORMATION">
        <IconButton className={"iconBtns"}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="SHARED LAYOUT DETAILS">
        <IconButton className={"iconBtns"}>
          <FmdGoodIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="OTHER QUERIES">
        <IconButton className={"iconBtns"}>
          <PsychologyAltIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="POSSIBLE FOR SITE VISIT">
        <IconButton className={"iconBtns"}>
          <CommuteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="PURCHASE DECISION">
        <IconButton className={"iconBtns"}>
          <AttachMoneyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="CONVERSATION FOLLOW UP">
        <IconButton className={"iconBtns"}>
          <SupportAgentIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="POST PURCHASE FEEDBACK">
        <IconButton className={"iconBtns"}>
          <RateReviewIcon />
        </IconButton>
      </Tooltip> */}

      {loginState.isLogin && (
        <Avatar
          alt={(loginState && loginState.email) || ""}
          src={(loginState && loginState.imageUrl) || ""}
          onClick={() => setMenuOpen(true)}
          // onMouseOver={() => setMenuOpen(true)}
          ref={menuRef}
        />
      )}
      {showBack && (
        <IconButton className={"iconBtns"} onClick={backRoute}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={menuOpen}
        role={undefined}
        anchorEl={menuRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        menuNavigate(option);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </CSS.BtnContainer>
  );
};

export default LeadsHeader;
