import { GET_ALL_ROLE, GET_ROLE_BY_ID } from "./actionType";

export const initialState = {
  role_list: [],
  role: {},
};

const layoutReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_ALL_ROLE: {
      const newState = {
        ...state,
        role_list: data.role,
      };
      return newState;
    }

    case GET_ROLE_BY_ID: {
      const newState = {
        ...state,
        role: data.role.length > 0 ? data.role[0] : {},
      };
      return newState;
    }

    default:
      return state;
  }
};

export default layoutReducer;
