import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TuneIcon from "@mui/icons-material/Tune";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import SaveIcon from "@mui/icons-material/Save";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import dayjs from "dayjs";

//Custom imports
import HomeHeader from "../../../HeaderMenu/HomeHeader";
import "../../../Home/PlotCard/PlotCard.css";
import "../../Leads.css";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import moment from "moment";

//Other lib imports
import axios from "axios";

import { baseUrl } from "../../../../util/config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SiteVisitDetails = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);
  const [dataDetails, setDataDetails] = React.useState(null);
  const [leadsDetails, setLeadsDetails] = React.useState(null);

  const [reason, setReason] = React.useState("");
  const [reasonArr, setReasonArr] = React.useState([]);

  const [inputObj, setInputObj] = React.useState({
    is_schedule_site_visit: "",
    followupDate: null,
    comments: "",
    dateTime: null,
    datetime: null,
    noPeople: 1,
    transportation: "own",
    comingFrom: "",
  });

  const addReason = () => {
    if (!reason) {
      openSnackBar("Please enter the reason", "error");
      return;
    }
    setReasonArr([...reasonArr, reason]);
    setReason("");
  };

  const doReasonSubmit = () => {
    console.log("reasonArr");
    console.log(reasonArr);
  };

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state.layoutDetails) {
      setLayoutDetails({
        ...layoutDetails,
        ...state.layoutDetails,
      });
    }

    if (state.data) {
      setDataDetails({
        ...dataDetails,
        ...state.data,
      });
    }
  }, [state]);

  React.useEffect(() => {
    if (layoutDetails && layoutDetails.id) {
      const payload = {
        layout_id: layoutDetails.id,
        mpdp_mapping_id: state.data.display_id,
      };
      axios.post(`${baseUrl}/leads/list`, payload).then(
        (res) => {
          console.log("res", res.data && res.data);
          if (res.data && res.data.leads_list) {
            if (res.data.leads_list.length > 0) {
              setLeadsDetails(res.data.leads_list[0]);
            }
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  }, [layoutDetails]);

  React.useEffect(() => {
    if (leadsDetails && leadsDetails.is_contact_site_visit) {
      setInputObj({
        ...inputObj,
        is_schedule_site_visit: leadsDetails.is_contact_site_visit,
        ...leadsDetails.site_visit_details,
      });
    }
    if (leadsDetails && leadsDetails.site_visit_details) {
      setReasonArr(leadsDetails.site_visit_details.reason);
    }
  }, [leadsDetails]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = () => {
    if (!inputObj.is_schedule_site_visit) {
      openSnackBar("Please select the option", "error");
      return;
    }

    if (inputObj.is_schedule_site_visit === "2" && reasonArr.length === 0) {
      openSnackBar("Please enter the reason", "error");
      return;
    }

    if (inputObj.is_schedule_site_visit === "3" && !inputObj.followupDate) {
      openSnackBar("Please select followupDate", "error");
      return;
    }

    if (
      inputObj.is_schedule_site_visit === "1" &&
      (!inputObj.dateTime ||
        !inputObj.noPeople ||
        !inputObj.transportation ||
        !inputObj.comingFrom)
    ) {
      openSnackBar("Please Enter all fields", "error");
      return;
    }

    let payload;

    payload = {
      is_schedule_site_visit: inputObj.is_schedule_site_visit,
      data: {
        ...inputObj,
        reason: reasonArr,
      },
      mpdp_mapping_id: state.data.display_id,
      user_id: userDetails && userDetails.user_id,
    };

    axios
      .post(`${baseUrl}/leads/sitevisit/update`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          //console.log("err", err);
        }
      );
  };

  const doRoute = (e, menu_url, state) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: {
          id: layoutDetails.id,
          layout_name: layoutDetails.layout_name,
          project_hash: layoutDetails.project_hash,
          data: state,
        },
      });
    }
  };

  console.log("layoutDetails", layoutDetails);

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={false}
              backRoute={(e) =>
                backRoute(e, "sharedlayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  type: layoutDetails.type,
                })
              }
            />
          </Grid>
        </Grid>

        {userDetails && userDetails.user_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {userDetails.user_name.toLowerCase()}
            </span>
          </Grid>
        )}

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 1em"
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "leads", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Leads Home
            </Link>
            {/* <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "sitevisitlayout", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Possible for Site Visit
            </Link> */}
            {/* {layoutDetails && layoutDetails.type !== "NCSV" && (
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb breadcrumb-underline"
                onClick={(e) =>
                  backRoute(e, "sitevisitcontactlayout", {
                    id: layoutDetails.id,
                    layout_name: layoutDetails.layout_name,
                    layout_id: layoutDetails.id,
                  })
                }
              >
                Scheduled Site Visit
              </Link>
            )} */}
            {layoutDetails && layoutDetails.type === "NCSV" && (
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb breadcrumb-underline"
                onClick={(e) =>
                  backRoute(e, "sitevisitlist", {
                    id: layoutDetails.id,
                    layout_name: layoutDetails.layout_name,
                    layout_id: layoutDetails.id,
                    type: layoutDetails.type,
                  })
                }
              >
                Not Contacted to Schedule Site Visit
              </Link>
            )}
            {layoutDetails && layoutDetails.type !== "NCSV" && (
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb breadcrumb-underline"
                onClick={(e) =>
                  backRoute(e, "sitevisitlist", {
                    id: layoutDetails.id,
                    layout_name: layoutDetails.layout_name,
                    layout_id: layoutDetails.id,
                    type: layoutDetails.type,
                  })
                }
              >
                {layoutDetails && layoutDetails.type === "CSVNI"
                  ? "Not Interested in Site Visit"
                  : layoutDetails && layoutDetails.type === "CSVI"
                  ? "Interested in Site Visit"
                  : layoutDetails && layoutDetails.type === "CSVCL"
                  ? "Call Later in Site Visit"
                  : ""}
              </Link>
            )}

            <Typography color="text.primary" className="breadcrumb">
              {dataDetails && dataDetails.display_mobile}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            <Button variant="outlined" className="pc-leads-button">
              {dataDetails && dataDetails.display_mobile}
            </Button>
          </Grid>
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {dataDetails && (
              <Card className="box-card">
                <CardHeader
                  title={
                    <>
                      {dataDetails.display_name && (
                        <Typography className="box-text" gutterBottom>
                          Name : {dataDetails.display_name}
                        </Typography>
                      )}
                      <Typography className="box-text" gutterBottom>
                        Mobile No : {dataDetails.display_mobile}
                      </Typography>
                      {dataDetails.display_name && (
                        <Typography className="box-text" gutterBottom>
                          Email Id : {dataDetails.display_email}
                        </Typography>
                      )}
                      <Typography className="box-text" gutterBottom>
                        Date time :{" "}
                        {moment(new Date(dataDetails.req_datetime)).format(
                          "DD/MM/YYYY h:m A"
                        )}
                      </Typography>
                      <Typography className="box-text" gutterBottom>
                        Leads Through : {dataDetails.leads_through}
                      </Typography>
                      {dataDetails.type === "L" && (
                        <>
                          <Typography className="box-text" gutterBottom>
                            Leads Through Name : {dataDetails.leads_name}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Leads Through Email : {dataDetails.leads_email}
                          </Typography>
                          {dataDetails.leads_mobile && (
                            <Typography className="box-text" gutterBottom>
                              Leads Through Mobile : {dataDetails.leads_mobile}
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  }
                />
              </Card>
            )}
          </Grid>
          <Grid container spacing={2} style={{ width: "90%" }}>
            <Grid item xs={3}>
              <div>
                <span className="login-form-heading">
                  Interested in Site Visit?
                </span>
              </div>
              <div>
                <RadioGroup
                  columns
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={inputObj.is_schedule_site_visit}
                  onChange={(e) =>
                    setInputObj({
                      ...inputObj,
                      is_schedule_site_visit: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Interested"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Not Interest"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Call Later"
                  />
                  <FormControlLabel
                    value={4}
                    control={<Radio />}
                    label="Proceed for Booking"
                  />
                </RadioGroup>
              </div>
              <div style={{ marginTop: 30 }}>
                <Button
                  variant="outlined"
                  className="pc-tlt-button"
                  onClick={(e) => onSubmit()}
                >
                  {"SUBMIT"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={9}>
              {inputObj && parseInt(inputObj.is_schedule_site_visit) === 2 && (
                <>
                  <div>
                    <span className="login-form-heading">Add Reason</span>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Reason (max 1500 characters)"
                      multiline
                      rows={4}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      name={"reason"}
                      onChange={(e) => setReason(e.target.value)}
                      value={reason}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                  <div className="login-form-div">
                    <Button
                      variant="outlined"
                      size="medium"
                      className="login-button"
                      onClick={(e) => addReason()}
                    >
                      Add Reason
                    </Button>
                  </div>
                  <div className="login-form-div">
                    <ul style={{ textAlign: "left" }}>
                      {reasonArr.map((element) => (
                        <li>{element}</li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
              {inputObj && parseInt(inputObj.is_schedule_site_visit) === 3 && (
                <>
                  <div>
                    <span className="login-form-heading">
                      Follow-up for Site visit
                    </span>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Follow-up date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="login-inputWidth"
                      size="small"
                      name={"reason"}
                      onChange={(e) =>
                        setInputObj({
                          ...inputObj,
                          followupDate: e.target.value,
                        })
                      }
                      value={inputObj.followupDate}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Clients comment, if any (max 1500 characters)"
                      multiline
                      rows={4}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      name={"reason"}
                      onChange={(e) =>
                        setInputObj({ ...inputObj, comments: e.target.value })
                      }
                      value={inputObj.comments}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                </>
              )}
              {inputObj && parseInt(inputObj.is_schedule_site_visit) === 1 && (
                <>
                  <div>
                    <span className="login-form-heading">
                      Schedule Site Visit
                    </span>
                  </div>
                  <div className="login-form-div">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        inputFormat="DD/MM/YYYY HH:mm:ss"
                        renderInput={(props) => <TextField {...props} />}
                        label="Site Visit Date, Time"
                        value={inputObj.dateTime}
                        onChange={(newValue) => {
                          let datetime = "";
                          if (newValue.$d) {
                            datetime = newValue.format("YYYY-MM-DD HH:mm:ss");
                          }
                          setInputObj({
                            ...inputObj,
                            dateTime: newValue,
                            datetime: datetime,
                          });
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Number of people coming"
                      type="number"
                      InputProps={{ inputProps: { min: 1, max: 10 } }}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      name={"address"}
                      onChange={(e) =>
                        setInputObj({ ...inputObj, noPeople: e.target.value })
                      }
                      value={inputObj.noPeople}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                  <div className="login-form-div">
                    <FormControl style={{ width: "70%" }}>
                      <InputLabel>Transportation</InputLabel>
                      <Select
                        input={<OutlinedInput label="Transportation" />}
                        MenuProps={MenuProps}
                        name={"Transportation"}
                        onChange={(e) =>
                          setInputObj({
                            ...inputObj,
                            transportation: e.target.value,
                          })
                        }
                        value={inputObj.transportation}
                      >
                        <MenuItem value={"own"}>Own Vehicle</MenuItem>
                        <MenuItem value={"need"}>Need Vehicle</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Where are they coming from (max 1500 characters)"
                      multiline
                      rows={4}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      name={"address"}
                      onChange={(e) =>
                        setInputObj({ ...inputObj, comingFrom: e.target.value })
                      }
                      value={inputObj.comingFrom}
                      inputProps={{ maxLength: 1500 }}
                    />
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leads_received: state.layout.leads_received,
  };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SiteVisitDetails);
