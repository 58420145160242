import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TuneIcon from "@mui/icons-material/Tune";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CircularProgress from "@mui/material/CircularProgress";

//Custom imports
import HomeHeader from "../../../HeaderMenu/HomeHeader";
import "../../../Home/PlotCard/PlotCard.css";
import "../../Leads.css";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import XHR from "../../../../util/pxhr";
import Snackbar from "../../../snackbar";
import moment from "moment";

import { baseUrl } from "../../../../util/config";

//Other lib imports
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PurchaseFeedbackDetails = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);
  const [dataDetails, setDataDetails] = React.useState(null);
  const [leadsDetails, setLeadsDetails] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadedFileName, setUploadedFileName] = React.useState("");

  const [inputObj, setInputObj] = React.useState({
    feedback: "",
    youtubeLink: "",
    client_image: "",
    is_feedback_decision: "",
  });

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state.layoutDetails) {
      setLayoutDetails({
        ...layoutDetails,
        ...state.layoutDetails,
      });
    }

    if (state.data) {
      setDataDetails({
        ...dataDetails,
        ...state.data,
      });
    }
  }, [state]);

  React.useEffect(() => {
    if (layoutDetails && layoutDetails.id) {
      const payload = {
        layout_id: layoutDetails.id,
        mpdp_mapping_id: state.data.display_id,
      };
      axios.post(`${baseUrl}/leads/list`, payload).then(
        (res) => {
          console.log("res", res.data && res.data);
          if (res.data && res.data.leads_list) {
            if (res.data.leads_list.length > 0) {
              setLeadsDetails(res.data.leads_list[0]);
            }
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  }, [layoutDetails]);

  React.useEffect(() => {
    if (leadsDetails && leadsDetails.is_feedback_decision) {
      setInputObj({
        ...inputObj,
        is_feedback_decision: leadsDetails.is_feedback_decision,
        ...leadsDetails.feedback_data,
      });
    }
  }, [leadsDetails]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = () => {
    if (!inputObj.is_feedback_decision) {
      openSnackBar("Please select the option", "error");
      return;
    }

    const payload = {
      is_feedback_decision: inputObj.is_feedback_decision,
      feedback_data: {},
      mpdp_mapping_id: state.data.display_id,
      user_id: userDetails && userDetails.user_id,
    };
    axios
      .post(`${baseUrl}/leads/feedback/update`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          //console.log("err", err);
        }
      );
  };

  const doRoute = (e, menu_url, state) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: {
          id: layoutDetails.id,
          layout_name: layoutDetails.layout_name,
          project_hash: layoutDetails.project_hash,
          data: state,
        },
      });
    }
  };

  const onBrochureFileChange = (event) => {
    setIsLoading(true);
    const files = event.target.files[0];
    const maxAllowedSize = 5 * 1024 * 1024;
    if (files.size > maxAllowedSize) {
      openSnackBar("Please upload file less than 2MB", "error");
      return;
    }
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", files);
    formData.append("type", "I");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setIsLoading(false);
        setUploadedFileName(files.name);
        setInputObj({
          ...inputObj,
          client_image: (res.data && res.data.url) || "",
        });
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  console.log("layoutDetails", inputObj);

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={false}
              backRoute={(e) =>
                backRoute(e, "sharedlayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  type: layoutDetails.type,
                })
              }
            />
          </Grid>
        </Grid>

        {userDetails && userDetails.user_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {userDetails.user_name.toLowerCase()}
            </span>
          </Grid>
        )}

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 1em"
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "leads", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Leads Home
            </Link>
            {/* <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "feedback", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Purchase Feedback
            </Link> */}
            {layoutDetails && (
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb breadcrumb-underline"
                onClick={(e) =>
                  backRoute(e, "feedbacklist", {
                    id: layoutDetails.id,
                    layout_name: layoutDetails.layout_name,
                    layout_id: layoutDetails.id,
                    type: layoutDetails.type,
                  })
                }
              >
                {layoutDetails && layoutDetails.type === "PFP"
                  ? "No of Leads Feedback Received"
                  : layoutDetails && layoutDetails.type === "PFR"
                  ? "No of Leads Feedback Received"
                  : ""}
              </Link>
            )}

            <Typography color="text.primary" className="breadcrumb">
              {dataDetails && dataDetails.display_mobile}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            <Button variant="outlined" className="pc-leads-button">
              {dataDetails && dataDetails.display_mobile}
            </Button>
          </Grid>
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {dataDetails && (
              <Card className="box-card">
                <CardHeader
                  title={
                    <>
                      {dataDetails.display_name && (
                        <Typography className="box-text" gutterBottom>
                          Name : {dataDetails.display_name}
                        </Typography>
                      )}
                      <Typography className="box-text" gutterBottom>
                        Mobile No : {dataDetails.display_mobile}
                      </Typography>
                      {dataDetails.display_name && (
                        <Typography className="box-text" gutterBottom>
                          Email Id : {dataDetails.display_email}
                        </Typography>
                      )}
                      <Typography className="box-text" gutterBottom>
                        Date time :{" "}
                        {moment(new Date(dataDetails.req_datetime)).format(
                          "DD/MM/YYYY h:m A"
                        )}
                      </Typography>
                      <Typography className="box-text" gutterBottom>
                        Leads Through : {dataDetails.leads_through}
                      </Typography>
                      {dataDetails.type === "L" && (
                        <>
                          <Typography className="box-text" gutterBottom>
                            Leads Through Name : {dataDetails.leads_name}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Leads Through Email : {dataDetails.leads_email}
                          </Typography>
                          {dataDetails.leads_mobile && (
                            <Typography className="box-text" gutterBottom>
                              Leads Through Mobile : {dataDetails.leads_mobile}
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  }
                />
              </Card>
            )}
          </Grid>
          <Grid container spacing={2} style={{ width: "90%" }}>
            <Grid item xs={4}>
              <div>
                <span className="login-form-heading">
                  Purchase Feedback Shared?
                </span>
              </div>
              <div>
                <RadioGroup
                  columns
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={inputObj.is_feedback_decision}
                  onChange={(e) =>
                    setInputObj({
                      ...inputObj,
                      is_feedback_decision: e.target.value,
                    })
                  }
                >
                  <FormControlLabel value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel value={2} control={<Radio />} label="No" />
                </RadioGroup>
              </div>
              <div style={{ marginTop: 30 }}>
                <Button
                  variant="outlined"
                  className="pc-tlt-button"
                  onClick={(e) => onSubmit()}
                >
                  {"SUBMIT"}
                </Button>
              </div>
            </Grid>
            {/* <Grid item xs={9}>
              {inputObj && parseInt(inputObj.is_feedback_decision) === 1 && (
                <>
                  <div className="login-form-div">
                    <span className="login-form-heading">
                      Post Purchase Feedback
                    </span>
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Feedback"
                      multiline
                      rows={4}
                      required={true}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      onChange={(e) =>
                        setInputObj({ ...inputObj, feedback: e.target.value })
                      }
                      value={inputObj.feedback}
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="Youtube Link"
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      onChange={(e) =>
                        setInputObj({
                          ...inputObj,
                          youtubeLink: e.target.value,
                        })
                      }
                      value={inputObj.youtubeLink}
                    />
                  </div>
                  <div
                    className="login-form-upload-div"
                    style={{ display: "flex" }}
                  >
                    <div style={{ marginRight: 20 }}>
                      <label htmlFor="brochure-button-file">
                        <Button
                          variant="contained"
                          component="span"
                          size="small"
                          className="login-button"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Client Image
                        </Button>
                      </label>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="brochure-button-file"
                        onChange={onBrochureFileChange}
                        type="file"
                      />
                      <div style={{ marginTop: 20 }}>
                        <span className="login-form-div-helper-text">
                          (Image file less than 5MB)
                        </span>
                      </div>
                    </div>
                    {inputObj && inputObj.client_image ? (
                      <div style={{ marginRight: 20 }}>
                        <label
                          htmlFor="brochure-button-file"
                          style={{ paddingTop: "10px" }}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            size="small"
                            className="login-button"
                            onClick={() => window.open(inputObj.client_image)}
                            endIcon={<RemoveRedEyeIcon />}
                          >
                            Preview Image
                          </Button>
                        </label>
                        {uploadedFileName && (
                          <div style={{ marginTop: 20 }}>
                            <span className="login-form-div-helper-text">
                              {uploadedFileName} uploaded successfully.
                            </span>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </>
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leads_received: state.layout.leads_received,
  };
};
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseFeedbackDetails);
