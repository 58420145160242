import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import "../Home/PlotCard/PlotCard.css";
import "../Home/Home.css";

import { map, includes } from "lodash";
import XHR from "../../util/xhr";

const LayoutDetails = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // console.log(
  //   "props.location.state.newId",
  //   state && state
  // );

  const [userDetails, setUserDetails] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (userDetails && userDetails.user_type === "D") {
      let data = {
        user_id: userDetails.user_id,
      };
      setIsLoading(true);
      XHR.post("realestate_v1/user/id", data).then(
        (res) => {
          //console.log("res.data", res.data);
          if (res.data && res.data.users && res.data.users.length > 0) {
            setIsLoading(false);
            setUser(res.data.users[0]);
          } else {
            setIsLoading(false);
          }
        },
        (err) => {}
      );
    }
  }, [userDetails]);

  React.useEffect(() => {
    if (state) {
      setLayoutDetails({
        ...layoutDetails,
        ...state,
      });
    }
  }, [state]);

  const menuOptions = [
    {
      menu_id: 1,
      menu_url: "favourite",
      menu_name: "Favourite",
    },
    {
      menu_id: 2,
      menu_url: "sitevisit",
      menu_name: "Site Visit Request",
    },
    {
      menu_id: 3,
      menu_url: "enqueries",
      menu_name: "Enqueries",
    },
    {
      menu_id: 4,
      menu_url: "plot",
      menu_name: "Change Plot Status",
    },
    {
      menu_id: 5,
      menu_url: "history",
      menu_name: "OTP validated Mobile No",
    },
    {
      menu_id: 6,
      menu_url: "google",
      menu_name: "Google Login",
    },
    {
      menu_id: 7,
      menu_url: "leads",
      menu_name: "Leads",
    },
    {
      menu_id: 7,
      menu_url: "leadstracking",
      menu_name: "Individual Lead Tracking",
    },
  ];

  const doRoute = (e, menu_url) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: {
          id: layoutDetails.id,
          layout_name: layoutDetails.layout_name,
          project_hash: layoutDetails.project_hash,
        },
      });
    }
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={true}
              backRoute={(e) => backRoute(e, "projects", {})}
            />
          </Grid>
        </Grid>

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}

        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-txt">select report</span>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {menuOptions.length > 0 &&
              map(menuOptions, function (data, index) {
                return (userDetails &&
                  userDetails.user_type === "D" &&
                  user &&
                  includes(user.menuIds, data.menu_id)) ||
                  (userDetails && userDetails.user_type !== "D") ? (
                  <Button
                    key={index}
                    variant="outlined"
                    className="pc-tlt-button"
                    onClick={(e) => doRoute(e, data.menu_url)}
                  >
                    {data.menu_name}
                  </Button>
                ) : null;
              })}
          </Grid>
        </Grid>
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "42%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </>
  );
};

export default LayoutDetails;
