import {
  GET_ALL_LAYOUT,
  GET_PROMOTORS_DETAILS,
  GET_SITE_VISIT_DETAILS,
  GET_FAVOURITE_DETAILS,
  GET_ENQUERIES_DETAILS,
  GET_PLOTS_DETAILS,
  GET_HISTORY_DETAILS,
  GET_GOOGLE_DETAILS,
  GET_ALL_USER
} from "./actionType";

export const initialState = {
  list: [],
  promoters: {},
  sitevisit: [],
  favourite: [],
  enqueries: [],
  plots: [],
  hDetails: [],
  google: [],
  leads_received: [],
  user_list: []
};

const layoutReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_ALL_LAYOUT: {
      const newState = {
        ...state,
        list: data.projects,
      };
      return newState;
    }

    case GET_ALL_USER: {
      const newState = {
        ...state,
        user_list: data.users,
      };
      return newState;
    }

    case GET_PROMOTORS_DETAILS: {
      const newState = {
        ...state,
        promoters: data.promoters,
      };
      return newState;
    }

    case GET_SITE_VISIT_DETAILS: {
      const newState = {
        ...state,
        sitevisit: data.sitevisit,
      };
      return newState;
    }
    case GET_FAVOURITE_DETAILS: {
      const newState = {
        ...state,
        favourite: data.favourite,
      };
      return newState;
    }
    case GET_ENQUERIES_DETAILS: {
      const newState = {
        ...state,
        enqueries: data.enqueries,
      };
      return newState;
    }
    case GET_PLOTS_DETAILS: {
      const newState = {
        ...state,
        plots: data.plots,
      };
      return newState;
    }
    case GET_HISTORY_DETAILS: {
      const newState = {
        ...state,
        hDetails: data.hDetails,
      };
      return newState;
    }
    case GET_GOOGLE_DETAILS: {
      const newState = {
        ...state,
        google: data.google,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default layoutReducer;
