import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TuneIcon from "@mui/icons-material/Tune";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import "../Home/PlotCard/PlotCard.css";
import "../SiteVisit/SiteVisit.css";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import moment from "moment";

// Store imports
import { getFavourite } from "../../store/layout/actionCreator";
import FilterPopup from "../Overlays/FilterPopup/FilterPopup";

import { map } from "lodash";

const Favourite = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { getFavourite, favourite } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);
  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state) {
      setLayoutDetails({
        ...layoutDetails,
        ...state,
      });
      getFavourite({
        project_id: state.id,
      });
    }
  }, [state]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = (data) => {
    getFavourite({
      project_id: layoutDetails.id,
      ...data,
    });
    setOpenFilterPopup(false);
    setFilterCond(data);
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <FilterPopup
        openFlag={openFilterPopup}
        setCloseFlag={setOpenFilterPopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={true}
              backRoute={(e) =>
                backRoute(e, "layoutdetails", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  project_hash: layoutDetails.project_hash,
                })
              }
            />
          </Grid>
        </Grid>

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}

        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-txt">favourite</span>
        </Grid>

        {filterCond && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-filter">
              filter date :{" "}
              {moment(new Date(filterCond.from)).format("DD/MM/YYYY")} to{" "}
              {moment(new Date(filterCond.to)).format("DD/MM/YYYY")}
            </span>
          </Grid>
        )}

        {favourite && favourite.length > 0 && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-filter">
              no. of records : {favourite.length}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {favourite && favourite.length > 0 &&
              map(favourite, function (data, index) {
                return (
                  <Card className="box-card" key={index}>
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{ background: "#ffeedd", color: "#462B00" }}
                          aria-label="recipe"
                        >
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <>
                          <Typography className="box-text" gutterBottom>
                            Customer Email : {data.email}
                          </Typography>
                          {data.favPlotsDetails &&
                            data.favPlotsDetails.map((favData, index) => (
                              <>
                                <Typography className="box-text" gutterBottom>
                                  Added Date : {favData.favDate}
                                </Typography>
                                <Typography className="box-text" gutterBottom>
                                  Added Plots : {favData.plot_no}
                                </Typography>
                              </>
                            ))}
                        </>
                      }
                    />
                  </Card>
                );
              })}
            {favourite && favourite.length === 0 && (
              <Card className="box-card" key={0}>
                <CardContent
                  className="box-content"
                  style={{ textAlign: "center" }}
                >
                  <Typography className="box-text" gutterBottom>
                    No Data
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Fab
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 200,
          background: "#462B00",
          color: "#fff",
        }}
        aria-label={"filter"}
        onClick={(e) => setOpenFilterPopup(true)}
      >
        <TuneIcon />
      </Fab>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    favourite: state.layout.favourite,
  };
};
const mapDispatchToProps = {
  getFavourite,
};
export default connect(mapStateToProps, mapDispatchToProps)(Favourite);
