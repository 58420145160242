import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TuneIcon from "@mui/icons-material/Tune";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MessageIcon from "@mui/icons-material/Message";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import QueriesPopup from "../Overlays/QueriesPopup/QueriesPopup";
import "../Home/PlotCard/PlotCard.css";
import "../SiteVisit/SiteVisit.css";
import "./Enqueries.css";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import moment from "moment";
import axios from "axios";
import XHR from "../../util/xhr";

import { baseUrlPlotsApi } from "../../util/config";

// Store imports
import { getEnqueries } from "../../store/layout/actionCreator";
import FilterPopup from "../Overlays/FilterPopup/FilterPopup";

import { map } from "lodash";

const Enqueries = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { getEnqueries, enqueries } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);
  const [messages, setMessages] = React.useState(null);
  const [details, setDetails] = React.useState(null);
  const [openQueriesPopup, setOpenQueriesPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isActionEnabled, setIsActionEnabled] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (userDetails && userDetails.user_type === "D") {
      let data = {
        user_id: userDetails.user_id,
      };
      setIsLoading(true);
      XHR.post("realestate_v1/user/id", data).then(
        (res) => {
          //console.log("res.data", res.data);
          if (res.data && res.data.users && res.data.users.length > 0) {
            setIsLoading(false);
            setIsActionEnabled(
              res.data.users[0].mappedActionMenu.length > 0 ? true : false
            );
            //setUser(res.data.users[0]);
          } else {
            setIsLoading(false);
          }
        },
        (err) => {}
      );
    } else {
      setIsActionEnabled(true);
    }
  }, [userDetails]);

  React.useEffect(() => {
    if (state) {
      setLayoutDetails({
        ...layoutDetails,
        ...state,
      });
      getEnqueries({
        project_id: state.id,
      });
    }
  }, [state]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = (data) => {
    getEnqueries({
      project_id: layoutDetails.id,
      ...data,
    });
    setOpenFilterPopup(false);
    setFilterCond(data);
  };

  const onQueriesSubmit = (data) => {
    //console.log(data);
    const payload = {
      query_id: details.id,
      message: data.message,
      type: 2,
      user_id: userDetails.user_id || 0,
    };
    axios
      .post(
        `${baseUrlPlotsApi}/realestate/messages/save`,
        payload
      )
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Successfully Added", "success");
            getAllMessages(details.id);
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          //console.log("err", err);
        }
      );
  };

  const getAllMessages = (queryId) => {
    const payload = { query_id: queryId };
    axios
      .post(`${baseUrlPlotsApi}/realestate/messages/get`, payload)
      .then(
        (res) => {
          if (res.data && res.data.queries) {
            setMessages(res.data.queries);
          }
        },
        (err) => {
          //console.log("err", err);
        }
      );
  };

  const viewClick = (e, data) => {
    e.stopPropagation();
    //console.log("data", data);
    getAllMessages(data.id);
    setDetails(data);
    setOpenQueriesPopup(true);
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <FilterPopup
        openFlag={openFilterPopup}
        setCloseFlag={setOpenFilterPopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
      />
      <QueriesPopup
        openFlag={openQueriesPopup}
        setCloseFlag={setOpenQueriesPopup}
        onSubmit={onQueriesSubmit}
        openSnackBar={openSnackBar}
        details={details}
        messages={messages}
        isActionEnabled={isActionEnabled}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={true}
              backRoute={(e) =>
                backRoute(e, "layoutdetails", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  project_hash: layoutDetails.project_hash,
                })
              }
            />
          </Grid>
        </Grid>

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}

        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-txt">enqueries</span>
        </Grid>

        {filterCond && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-filter">
              filter date :{" "}
              {moment(new Date(filterCond.from)).format("DD/MM/YYYY")} to{" "}
              {moment(new Date(filterCond.to)).format("DD/MM/YYYY")}
            </span>
          </Grid>
        )}
        {enqueries && enqueries.length > 0 && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-filter">
              no. of records : {enqueries.length}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {enqueries &&
              enqueries.length > 0 &&
              map(enqueries, function (data, index) {
                return (
                  <Card className="box-card" key={index}>
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{ background: "#ffeedd", color: "#462B00" }}
                          aria-label="recipe"
                        >
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <>
                          <Typography className="box-text" gutterBottom>
                            Name : {data.name}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Mobile No : {data.mobile}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Date : {data.qDate}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Time : {data.qTime}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Last Replied By : {data.last_replayed}
                          </Typography>
                        </>
                      }
                      action={
                        <>
                          {data.last_message === 1 && (
                            <div class="case-study-badge-container">
                              <span class="case-study-badge-text">
                                New Message
                              </span>
                            </div>
                          )}
                          <IconButton
                            aria-label="settings"
                            style={{ marginTop: 30 }}
                          >
                            <QuestionAnswerIcon
                              style={{ fontSize: 40 }}
                              onClick={(e) => viewClick(e, data)}
                            />
                          </IconButton>
                        </>
                      }
                    />
                  </Card>
                );
              })}
            {enqueries && enqueries.length === 0 && (
              <Card className="box-card" key={0}>
                <CardContent
                  className="box-content"
                  style={{ textAlign: "center" }}
                >
                  <Typography className="box-text" gutterBottom>
                    No Data
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Fab
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 200,
          background: "#462B00",
          color: "#fff",
        }}
        aria-label={"filter"}
        onClick={(e) => setOpenFilterPopup(true)}
      >
        <TuneIcon />
      </Fab>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    enqueries: state.layout.enqueries,
  };
};
const mapDispatchToProps = {
  getEnqueries,
};
export default connect(mapStateToProps, mapDispatchToProps)(Enqueries);
