import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TuneIcon from "@mui/icons-material/Tune";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import "../Home/PlotCard/PlotCard.css";
import "./Leads.css";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import moment from "moment";

// Store imports
import { getContactLogs } from "../../store/leads/actionCreator";
import FilterPopup from "../Overlays/FilterPopup/FilterPopup";

import { map } from "lodash";

const ViewContactLog = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { getContactLogs, leads_contact_log } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);
  const [dataDetails, setDataDetails] = React.useState(null);

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state.layoutDetails) {
      setLayoutDetails({
        ...layoutDetails,
        ...state.layoutDetails,
      });
    }

    if (state.data) {
      setDataDetails({
        ...dataDetails,
        ...state.data,
      });
      getContactLogs({
        mpdp_url_visitors_id: state.data.data.display_id,
      });
    }
  }, [state]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const getContactBy = (id) => {
    if (id === 1) {
      return "MANUAL CALL";
    } else if (id === 2) {
      return "WHATSAPP CALL";
    } else if (id === 3) {
      return "WHATSAPP TEXT";
    } else if (id === 4) {
      return "EMAIL";
    } else if (id === 1) {
      return "AUTOMATED CALL";
    }

    return "";
  };

  const getInterestData = (id) => {
    if (id === 1) {
      return "HIGH: READY TO BUY";
    } else if (id === 2) {
      return "MEDIUM: LOOKING TO BUY";
    } else if (id === 3) {
      return "LOW: JUST CURIOUS";
    }

    return "";
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={false}
              backRoute={(e) => backRoute(e, "leadsdetails", state.data)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-header">{"previous log"}</span>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {leads_contact_log &&
              leads_contact_log.length > 0 &&
              map(leads_contact_log, function (data, index) {
                return (
                  <Card className="box-card" key={index}>
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{ background: "#ffeedd", color: "#462B00" }}
                          aria-label="recipe"
                        >
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <>
                          <Typography className="box-text" gutterBottom>
                            {getContactBy(data.contact_by)}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Date :{" "}
                            {moment(new Date(data.contact_date)).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Time :{" "}
                            {moment(
                              new Date(
                                data.contact_date + " " + data.contact_time
                              )
                            ).format("h:m A")}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            {data.response_status === 1
                              ? "RESPONDED"
                              : "NOT RESPONDED"}
                          </Typography>
                          {data.response_status === 1 && (
                            <>
                              <Typography className="box-text" gutterBottom>
                                Next call time :{" "}
                                {data.preferred_time}
                              </Typography>
                              <Typography className="box-text" gutterBottom>
                                {getInterestData(data.interest_level)}
                              </Typography>
                            </>
                          )}
                        </>
                      }
                    />
                  </Card>
                );
              })}
            {leads_contact_log && leads_contact_log.length === 0 && (
              <Card className="box-card" key={0}>
                <CardContent
                  className="box-content"
                  style={{ textAlign: "center" }}
                >
                  <Typography className="box-text" gutterBottom>
                    No Data
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leads_contact_log: state.leads.leads_contact_log,
  };
};
const mapDispatchToProps = {
  getContactLogs,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewContactLog);
