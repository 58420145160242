import { useState, useEffect } from "react";

//Material UI imports
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
//Custom imports
import * as CSS from "../Style.js";

const FilterPopup = (props) => {
  const {
    openFlag,
    setCloseFlag,
    onSubmit,
    openSnackBar,
    isHistory = false,
  } = props;

  const [filterPopupData, setFilterPopupData] = useState({
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
    C: true,
    S: true,
    A: true,
    M: true,
    D: true,
  });

  const handleChange = (input, value) => {
    const newDate = moment(new Date(value)).format("YYYY-MM-DD");
    const updatedData = { ...filterPopupData };
    updatedData[input] = newDate;
    if (input === "from") {
      updatedData["to"] = "";
    }
    setFilterPopupData(updatedData);
  };

  const handleCheckboxChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.checked;
    const updatedData = { ...filterPopupData };
    updatedData[modifiedKey] = modifiedValue;
    setFilterPopupData(updatedData);
  };

  const doSubmit = () => {
    if (!filterPopupData.from && !filterPopupData.to) {
      openSnackBar("Enter all inputs", "error");
      return;
    }
    var startDate = moment(filterPopupData.from, "YYYY-MM-DD");
    var endDate = moment(filterPopupData.to, "YYYY-MM-DD");

    if (moment(endDate).diff(moment(startDate), "days") >= 0) {
      if (isHistory) {
        if (
          !filterPopupData.C &&
          !filterPopupData.S &&
          !filterPopupData.A &&
          !filterPopupData.M &&
          !filterPopupData.D
        ) {
          openSnackBar("Select one option", "error");
          return;
        }
      }

      onSubmit(filterPopupData);
    } else {
      openSnackBar("Invalid Date Selected", "error");
    }
  };

  return (
    <>
      <CSS.CustomizedDialog
        open={openFlag}
        onClose={() => setCloseFlag(false)}
        maxWidth={"lg"}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="1em 0 1em 0;"
        >
          <CSS.CloseBtn>
            <IconButton
              aria-label="closePopup"
              onClick={() => setCloseFlag(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </CSS.CloseBtn>
          <CSS.TitleContainer>
            <span className="title">Filter</span>
          </CSS.TitleContainer>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="From Date"
              inputFormat="DD/MM/YYYY"
              value={filterPopupData.from}
              onChange={(newValue) => {
                handleChange("from", newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              label="To Date"
              inputFormat="DD/MM/YYYY"
              value={filterPopupData.to}
              onChange={(newValue) => {
                handleChange("to", newValue);
              }}
              maxDate={
                filterPopupData.from === ""
                  ? new Date()
                  : moment(new Date(filterPopupData.from)).add(30, "d")
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {isHistory && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterPopupData.C}
                    onChange={handleCheckboxChange}
                    name="C"
                  />
                }
                label="GET CONTACT"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterPopupData.S}
                    onChange={handleCheckboxChange}
                    name="S"
                  />
                }
                label="SITE VISIT REQUEST"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterPopupData.A}
                    onChange={handleCheckboxChange}
                    name="A"
                  />
                }
                label="ASK US"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterPopupData.M}
                    onChange={handleCheckboxChange}
                    name="M"
                  />
                }
                label="MPDP"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterPopupData.D}
                    onChange={handleCheckboxChange}
                    name="D"
                  />
                }
                label="DOWNLOAD BROCHURE"
              />
            </FormGroup>
          )}
          <CSS.customBtn variant="outlined" onClick={doSubmit}>
            Submit
          </CSS.customBtn>
        </Grid>
      </CSS.CustomizedDialog>
    </>
  );
};

export default FilterPopup;
