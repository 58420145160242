import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import WestIcon from "@mui/icons-material/West";
import CircularProgress from "@mui/material/CircularProgress";

//Custom imports
import * as CSS from "../style";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

let publicUrl = process.env.PUBLIC_URL + "/";

const AddLayoutStepOne = (props) => {
  const { nextStep, id = null } = props;

  const onBackBtnClk = () => {
    nextStep(3);
  };

  const onNextBtnClk = () => {
    nextStep(5);
  };

  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const [allAmenities, setAllAmenties] = useState([]);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  useEffect(() => {
    getAllAmenities();
  }, []);

  useEffect(() => {
    if (id) {
      getProjectDetails(id);
    }
  }, [id]);

  const getProjectDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        setData((res.data && res.data.data) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAllAmenities = () => {
    setIsLoading(true);
    const payload = {};
    XHR.post("realestate/amenities", payload).then(
      (res) => {
        setIsLoading(false);
        setAllAmenties((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleCheckbox = (key, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const index = dataCopy[key] && dataCopy[key].indexOf(value);
    if (index > -1) {
      dataCopy[key].splice(index, 1); // 2nd parameter means remove one item only
    } else {
      dataCopy[key].push(value);
    }
    setData({
      ...data,
      [key]: dataCopy[key],
    });
    console.log("data", data);
  };

  const updateProjectData = () => {
    setIsLoading(true);
    const payload1 = JSON.parse(JSON.stringify({ ...data }));
    XHR.post("realestate/updateinfo", payload1).then(
      (res) => {
        setIsLoading(false);
        openSnackBar("Success.", "success");
        getProjectDetails(id);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={10} md={11} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Select Amenities</span>
                <div>
                  <span className="login-form-heading-step">(Step 4 of 6)</span>
                </div>
              </div>
              <Grid
                container
                gap="1em"
                justifyContent="center"
                alignItems="center"
                margin="2em 0 2em 0;"
              >
                {(allAmenities || []).map((el) => (
                  <div
                    className={`${
                      data &&
                      data.amenities &&
                      data.amenities.includes(String(el.id))
                        ? "pdp-amen-card-cntr-selected"
                        : "pdp-amen-card-cntr"
                    }`}
                    onClick={(event) =>
                      handleCheckbox("amenities", String(el.id))
                    }
                  >
                    <img
                      src={
                        el.image !== ""
                          ? el.image
                          : publicUrl + "assets/img/No_Image_Available.jpg"
                      }
                      alt="#"
                      style={{ width: 75 }}
                    />
                    <span className="pdp-amen-card-txt">{el.name}</span>
                  </div>
                ))}
              </Grid>
              <div className="login-form-div">
                <span className="login-form-heading-step">
                  {
                    data &&
                    data?.amenities &&
                    data?.amenities.length ? `${data?.amenities.length} Amenities Selected` : 'No Amenities Selected'
                  }
                </span>
              </div>
              <div className="profile-back-next-btn-cntr" style={{marginTop: '3%'}}>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onBackBtnClk}
                  className="login-button"
                  startIcon={<WestIcon />}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  onClick={() => updateProjectData()}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onNextBtnClk}
                  className="login-button"
                  endIcon={<EastIcon />}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddLayoutStepOne;
