import {
  GET_ALL_LAYOUT,
  GET_PROMOTORS_DETAILS,
  GET_SITE_VISIT_DETAILS,
  GET_FAVOURITE_DETAILS,
  GET_ENQUERIES_DETAILS,
  GET_PLOTS_DETAILS,
  GET_HISTORY_DETAILS,
  GET_GOOGLE_DETAILS,
  GET_LEADS_RECEIVED,
  GET_ALL_USER
} from "./actionType";
import XHR from "../../util/xhr";

export function getLayoutList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/list", inputs).then(
      (res) => {
        dispatch({
          type: GET_ALL_LAYOUT,
          data: {
            projects: res.data.project,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getPromotorsDetails(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate/promoters/get", inputs).then(
      (res) => {
        dispatch({
          type: GET_PROMOTORS_DETAILS,
          data: {
            promoters: res.data.companies,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getSiteVisitDetails(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/sitevisit", inputs).then(
      (res) => {
        dispatch({
          type: GET_SITE_VISIT_DETAILS,
          data: {
            sitevisit: res.data.sitevisit,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getFavourite(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/favourite", inputs).then(
      (res) => {
        dispatch({
          type: GET_FAVOURITE_DETAILS,
          data: {
            favourite: res.data.favourite,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getEnqueries(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/enqueries", inputs).then(
      (res) => {
        dispatch({
          type: GET_ENQUERIES_DETAILS,
          data: {
            enqueries: res.data.enqueries,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getPlots(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate/plots/getplots", inputs).then(
      (res) => {
        dispatch({
          type: GET_PLOTS_DETAILS,
          data: {
            plots: res.data.plots,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getHistory(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/history", inputs).then(
      (res) => {
        dispatch({
          type: GET_HISTORY_DETAILS,
          data: {
            hDetails: res.data.hDetails,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getGoogleLogin(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate_v1/google", inputs).then(
      (res) => {
        dispatch({
          type: GET_GOOGLE_DETAILS,
          data: {
            google: res.data.google,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getUserList(inputs = {}) {
  return function (dispatch) {
    return XHR.post("realestate/promoters/users", inputs).then(
      (res) => {
        dispatch({
          type: GET_ALL_USER,
          data: {
            users: res.data.users,
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}
