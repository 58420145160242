import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

//Custom imports
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import * as CSS from "../style";

import { baseUrl } from "../../../../util/config";

//Other lib imports
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ConfirmSharedDetails = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [inputObj, setInputObj] = useState({
    modeOfSharing: "Email",
    detailsShared: [
      {
        label: "Layout detail page link",
        value: false,
      },
      {
        label: "Promoter detail page link",
        value: false,
      },
      {
        label: "Layout photos",
        value: false,
      },
      {
        label: "Layout videos",
        value: false,
      },
      {
        label: "Layout brouchre",
        value: false,
      },
      {
        label: "Layout diagram",
        value: false,
      },
      {
        label: "Price details",
        value: false,
      },
      {
        label: "Return on investment information",
        value: false,
      },
      {
        label: "Banks ready to give loan",
        value: false,
      },
    ],
  });

  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    }
  }, []);

  useEffect(() => {
    if (state.data.data.display_id) {
      const payload = {
        mpdp_mapping_id: state.data.data.display_id,
      };
      axios.post(`${baseUrl}/leads/clientdetails`, payload).then(
        (res) => {
          // console.log("res.data", res);
          if (res.data && res.data.leadsDetails) {
            console.log("res.data", res.data.leadsDetails);
            if (res.data.leadsDetails.shared_details !== "") {
              setInputObj({
                ...inputObj,
                ...res.data.leadsDetails.shared_details,
              });
            }
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  }, [state.data.data.display_id]);

  const sharingMode = [
    { title: "Email", value: "Email" },
    { title: "Whatsapp", value: "Whatsapp" },
    { title: "Telegram", value: "Telegram" },
  ];

  const modeOfSharingInputChange = (e, value) => {
    const updatedObj = { ...inputObj };
    updatedObj["modeOfSharing"] = value;
    setInputObj({ ...updatedObj });
  };

  const sharedDetailsInputChange = (value, label) => {
    // const updatedObj = { ...inputObj }
    const detailsShared = inputObj.detailsShared.map((item) => {
      const updatedItem = item;
      if (updatedItem.label === label) {
        updatedItem.value = value;
      }
      return updatedItem;
    });
    setInputObj({ ...inputObj, detailsShared });
  };

  const doSubmit = () => {
    if (!inputObj.modeOfSharing) {
      openSnackBar("Enter all inputs", "error");
      return;
    }

    const found = inputObj.detailsShared.some((el) => el.value === true);

    if (!found) {
      openSnackBar("Enter all inputs", "error");
      return;
    }

    const payload = {
      shared_details: {
        ...inputObj,
      },
      mpdp_mapping_id: state.data.data.display_id,
      user_id: userDetails && userDetails.user_id,
    };
    axios.post(`${baseUrl}/leads/shareddetailsupdate`, payload).then(
      (res) => {
        if (res.data && res.data.status) {
          openSnackBar("Added Successfully", "success");
          doRoute();
          // Redirect
        }
      },
      (err) => {
        openSnackBar("Failed to submit", "error");
        //console.log("err", err);
      }
    );
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const doRoute = () => {
    navigate(`/sharedlayoutdetails`, {
      state: state.data,
    });
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Shared Details</span>
              </div>
              <div className="login-form-div">
                <Autocomplete
                  freeSolo
                  options={sharingMode.map((option) => option.title)}
                  value={inputObj.modeOfSharing}
                  name={"modeOfSharing"}
                  onInputChange={modeOfSharingInputChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Mode of Sharing" />
                  )}
                />
              </div>
              <div className="login-form-div">
                <div>
                  <FormControl>
                    <FormLabel>Details Shared</FormLabel>
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {inputObj.detailsShared.map((item) => {
                    return (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.value}
                              onChange={(e) =>
                                sharedDetailsInputChange(
                                  e.target.checked,
                                  item.label
                                )
                              }
                            />
                          }
                          label={item.label}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={(e) =>
                      backRoute(e, "sharedlayoutdetails", state.data)
                    }
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default ConfirmSharedDetails;
