import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TuneIcon from "@mui/icons-material/Tune";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

//Custom imports
import HomeHeader from "../../../HeaderMenu/HomeHeader";
import "../../../Home/PlotCard/PlotCard.css";
import "../../Leads.css";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import moment from "moment";

// Store imports
import { getLeadsList } from "../../../../store/leads/actionCreator";
import FilterPopup from "../../../Overlays/FilterPopup/FilterPopup";

import { map } from "lodash";

const PurchaseList = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { getLeadsList, leads_list } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state) {
      setLayoutDetails({
        ...layoutDetails,
        ...state,
      });
      getLeadsList({
        layout_id: state.id,
        type: state.type,
      });
    }
  }, [state]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = (data) => {
    getLeadsList({
      layout_id: layoutDetails.id,
      type: state.type,
      ...data,
    });
    setOpenFilterPopup(false);
    setFilterCond(data);
  };

  const doRoute = (e, menu_url, data = {}) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: {
          layoutDetails: {
            id: layoutDetails.id,
            layout_name: layoutDetails.layout_name,
            layout_id: layoutDetails.id,
            type: state.type,
          },
          data: data,
        },
      });
    }
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <FilterPopup
        openFlag={openFilterPopup}
        setCloseFlag={setOpenFilterPopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={false}
              backRoute={(e) =>
                backRoute(e, "sharedlayout", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            />
          </Grid>
        </Grid>

        {userDetails && userDetails.user_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {userDetails.user_name.toLowerCase()}
            </span>
          </Grid>
        )}

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}
        {filterCond && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-filter">
              Report for the date range :{" "}
              {moment(new Date(filterCond.from)).format("DD/MM/YYYY")} to{" "}
              {moment(new Date(filterCond.to)).format("DD/MM/YYYY")}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "leads", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Leads Home
            </Link>
            {/* <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "purchase", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Purchase Decision
            </Link> */}
            {/* {layoutDetails && layoutDetails.type !== "PDP" && (
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb breadcrumb-underline"
                onClick={(e) =>
                  backRoute(e, "sitevisitcontactlayout", {
                    id: layoutDetails.id,
                    layout_name: layoutDetails.layout_name,
                    layout_id: layoutDetails.id,
                  })
                }
              >
                Schedule Site Visit
              </Link>
            )} */}

            {/* {layoutDetails &&
              (layoutDetails.type === "SVU" ||
                layoutDetails.type === "SVC" ||
                layoutDetails.type === "SVD") && (
                <Link
                  underline="hover"
                  color="inherit"
                  className="breadcrumb breadcrumb-underline"
                  onClick={(e) =>
                    backRoute(e, "sitevisitinterestedlayout", {
                      id: layoutDetails.id,
                      layout_name: layoutDetails.layout_name,
                      layout_id: layoutDetails.id,
                    })
                  }
                >
                  Interested in Site Visit
                </Link>
              )} */}
            {/* {layoutDetails &&
              layoutDetails.type !== "SVU" &&
              layoutDetails.type !== "SVC" &&
              layoutDetails.type !== "SVD" && (
                <Typography color="text.primary" className="breadcrumb">
                  {layoutDetails && layoutDetails.type === "NCSV"
                    ? "Not Scheduled Site Visit"
                    : layoutDetails && layoutDetails.type === "CSVNI"
                    ? "Not Interested in Site Visit"
                    : layoutDetails && layoutDetails.type === "CSVI"
                    ? "Interested in Site Visit"
                    : layoutDetails && layoutDetails.type === "CSVCL"
                    ? "Call Later in Site Visit"
                    : ""}
                </Typography>
              )} */}

            {layoutDetails &&
              (layoutDetails.type === "PDP" ||
                layoutDetails.type === "PDB" ||
                layoutDetails.type === "PDNB" ||
                layoutDetails.type === "PDH") && (
                <Typography color="text.primary" className="breadcrumb">
                  {layoutDetails && layoutDetails.type === "PDP"
                    ? "No of Leads Decision is Pending"
                    : layoutDetails && layoutDetails.type === "PDB"
                    ? "No of Leads Decided to Buy"
                    : layoutDetails && layoutDetails.type === "PDNB"
                    ? "No of Leads Not Interested to Buy"
                    : layoutDetails && layoutDetails.type === "PDH"
                    ? "No of Leads Put The Decision On Hold"
                    : ""}
                </Typography>
              )}
          </Breadcrumbs>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            <Button variant="outlined" className="pc-leads-button">
              {`${leads_list && leads_list.length} ${
                layoutDetails && layoutDetails.type === "PDP"
                  ? "No of Leads Decision is Pending"
                  : layoutDetails && layoutDetails.type === "PDB"
                  ? "No of Leads Decided to Buy"
                  : layoutDetails && layoutDetails.type === "PDNB"
                  ? "No of Leads Not Interested to Buy"
                  : layoutDetails && layoutDetails.type === "PDH"
                  ? "No of Leads Put The Decision On Hold"
                  : ""
              }`}
            </Button>
          </Grid>
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {leads_list &&
              leads_list.length > 0 &&
              map(leads_list, function (data, index) {
                return (
                  <Card
                    className="box-card"
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={(e) => doRoute(e, "purchasedetails", data)}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{ background: "#ffeedd", color: "#462B00" }}
                          aria-label="recipe"
                        >
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <>
                          {data.display_name && (
                            <Typography className="box-text" gutterBottom>
                              Name : {data.display_name}
                            </Typography>
                          )}
                          <Typography className="box-text" gutterBottom>
                            Mobile No : {data.display_mobile}
                          </Typography>
                          {data.display_name && (
                            <Typography className="box-text" gutterBottom>
                              Email Id : {data.display_email}
                            </Typography>
                          )}
                          <Typography className="box-text" gutterBottom>
                            Date time :{" "}
                            {moment(new Date(data.req_datetime)).format(
                              "DD/MM/YYYY h:m A"
                            )}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Leads Through : {data.leads_through}
                          </Typography>
                          {data.type === "L" && (
                            <>
                              <Typography className="box-text" gutterBottom>
                                Leads Through Name : {data.leads_name}
                              </Typography>
                              <Typography className="box-text" gutterBottom>
                                Leads Through Email : {data.leads_email}
                              </Typography>
                              {data.leads_mobile && (
                                <Typography className="box-text" gutterBottom>
                                  Leads Through Mobile : {data.leads_mobile}
                                </Typography>
                              )}
                            </>
                          )}
                          {data.is_contact_site_visit === 3 && (
                            <Typography className="box-text" gutterBottom>
                              Followup Date :{" "}
                              {moment(
                                new Date(data.site_visit_details.followupDate)
                              ).format("DD/MM/YYYY")}
                            </Typography>
                          )}
                        </>
                      }
                    />
                  </Card>
                );
              })}
            {leads_list && leads_list.length === 0 && (
              <Card className="box-card" key={0}>
                <CardContent
                  className="box-content"
                  style={{ textAlign: "center" }}
                >
                  <Typography className="box-text" gutterBottom>
                    No Data
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Fab
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 200,
          background: "#462B00",
          color: "#fff",
        }}
        aria-label={"filter"}
        onClick={(e) => setOpenFilterPopup(true)}
      >
        <CalendarMonthIcon />
      </Fab>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leads_list: state.leads.leads_list,
  };
};
const mapDispatchToProps = {
  getLeadsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseList);
