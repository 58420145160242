import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import SaveIcon from "@mui/icons-material/Save";

//Custom imports
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import * as CSS from "../style";

import { baseUrl } from "../../../../util/config";

//Other lib imports
import axios from "axios";
import LayoutDetails from "../../../LayoutDetails/LayoutDetails";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ClientDetails = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [clientDetailsData, setClientDetailsData] = useState({
    name: "",
    email_id: "",
    mobile_no: "",
    location: "",
    address: "",
    marital_status: "M",
    gender: "M",
    age: "",
    kids: "",
    profession: "",
    income_per_annum: "",
    preferred_communication: "",
  });
  const [userDetails, setUserDetails] = useState(null);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const clientDetailsInputChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.value;
    const updatedData = { ...clientDetailsData };
    updatedData[modifiedKey] = modifiedValue;
    setClientDetailsData(updatedData);
  };

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    }
  }, []);

  useEffect(() => {
    if (state.data.data.display_id) {
      const payload = {
        mpdp_mapping_id: state.data.data.display_id,
      };
      axios
        .post(`${baseUrl}/leads/clientdetails`, payload)
        .then(
          (res) => {
            // console.log("res.data", res);
            if (res.data && res.data.leadsDetails) {
              console.log("res.data", res.data.leadsDetails);
              if (res.data.leadsDetails.client_details !== "") {
                setClientDetailsData({
                  ...clientDetailsData,
                  ...res.data.leadsDetails.client_details,
                });
              }
            }
          },
          (err) => {
            openSnackBar("Failed to submit", "success");
            console.log("err", err);
          }
        );
    }
  }, [state.data.data.display_id]);

  const doSubmit = () => {
    console.log("doSubmit");
    if (
      !clientDetailsData.name ||
      !clientDetailsData.email_id ||
      !clientDetailsData.mobile_no ||
      !clientDetailsData.location ||
      !clientDetailsData.marital_status ||
      !clientDetailsData.gender ||
      !clientDetailsData.age ||
      !clientDetailsData.profession ||
      !clientDetailsData.preferred_communication
    ) {
      openSnackBar("Enter all inputs", "error");
      return;
    }
    // console.log("clientDetailsData", clientDetailsData, state);
    const payload = {
      mpdp_mapping_id: state.data.data.display_id,
      client_details: clientDetailsData,
      user_id: userDetails && userDetails.user_id,
    };
    axios
      .post(
        `${baseUrl}/leads/clientdetailsupdate`,
        payload
      )
      .then(
        (res) => {
          if (res.data && res.data.status) {
            // updateHistory("A");\
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          console.log("err", err);
        }
      );
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  console.log("state", state);

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Client Details</span>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Name"
                  variant="outlined"
                  required
                  className={`login-inputWidth`}
                  size="small"
                  name={"name"}
                  onChange={clientDetailsInputChange}
                  value={clientDetailsData.name}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Email ID"
                  variant="outlined"
                  required
                  className={`login-inputWidth`}
                  size="small"
                  name={"email_id"}
                  onChange={clientDetailsInputChange}
                  value={clientDetailsData.email_id}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Mobile No"
                  variant="outlined"
                  required
                  className={`login-inputWidth`}
                  size="small"
                  name={"mobile_no"}
                  onChange={clientDetailsInputChange}
                  value={clientDetailsData.mobile_no}
                  // type={"number"}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Location"
                  variant="outlined"
                  required
                  className={`login-inputWidth`}
                  size="small"
                  name={"location"}
                  onChange={clientDetailsInputChange}
                  value={clientDetailsData.location}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Address (max 1500 characters)"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  name={"address"}
                  onChange={clientDetailsInputChange}
                  value={clientDetailsData.address}
                  inputProps={{ maxLength: 1500 }}
                />
              </div>
              <div className="login-form-div">
                <FormControl>
                  <FormLabel>Marital status*</FormLabel>
                  <RadioGroup
                    row
                    name={"marital_status"}
                    onChange={clientDetailsInputChange}
                    value={clientDetailsData.marital_status}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="Married"
                    />
                    <FormControlLabel
                      value="S"
                      control={<Radio />}
                      label="Single"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="login-form-div">
                <FormControl>
                  <FormLabel>Gender*</FormLabel>
                  <RadioGroup
                    row
                    name={"gender"}
                    onChange={clientDetailsInputChange}
                    value={clientDetailsData.gender}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="O"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Age"
                  variant="outlined"
                  required
                  className={`login-inputWidth`}
                  size="small"
                  name={"age"}
                  onChange={clientDetailsInputChange}
                  value={clientDetailsData.age}
                  type={"number"}
                />
              </div>
              <div className="login-form-div">
                <FormControl style={{ width: "70%" }}>
                  <InputLabel>Kids</InputLabel>
                  <Select
                    input={<OutlinedInput label="Kids" />}
                    MenuProps={MenuProps}
                    name={"kids"}
                    onChange={clientDetailsInputChange}
                    value={clientDetailsData.kids}
                  >
                    <MenuItem value={"0"}>0</MenuItem>
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="login-form-div">
                <FormControl style={{ width: "70%" }}>
                  <InputLabel required>Profession</InputLabel>
                  <Select
                    input={<OutlinedInput label="Profession" />}
                    MenuProps={MenuProps}
                    name={"profession"}
                    onChange={clientDetailsInputChange}
                    value={clientDetailsData.profession}
                  >
                    <MenuItem value={"public"}>Public</MenuItem>
                    <MenuItem value={"private"}>Private</MenuItem>
                    <MenuItem value={"business"}>Business</MenuItem>
                    <MenuItem value={"others"}>Others</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Income per annum"
                  variant="outlined"
                  required
                  className={`login-inputWidth`}
                  size="small"
                  name={"income_per_annum"}
                  onChange={clientDetailsInputChange}
                  value={clientDetailsData.income_per_annum}
                  type={"number"}
                />
              </div>
              {/* <div className="login-form-div">
                <FormControl style={{ width: "70%" }}>
                  <InputLabel>Income per annum</InputLabel>
                  <Select
                    input={<OutlinedInput label="Income per annum" />}
                    MenuProps={MenuProps}
                    name={"income_per_annum"}
                    onChange={clientDetailsInputChange}
                    value={clientDetailsData.income_per_annum}
                  >
                    <MenuItem value={"lakhs"}>in lakhs</MenuItem>
                    <MenuItem value={"crores"}>in crores</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <div className="login-form-div">
                <FormControl style={{ width: "70%" }}>
                  <InputLabel required>
                    Preferred communication channel
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput label="Preferred communication channel" />
                    }
                    MenuProps={MenuProps}
                    name={"preferred_communication"}
                    onChange={clientDetailsInputChange}
                    value={clientDetailsData.preferred_communication}
                  >
                    <MenuItem value={"phone"}>Phone</MenuItem>
                    <MenuItem value={"email"}>Email</MenuItem>
                    <MenuItem value={"whatsapp"}>Whatsapp</MenuItem>
                    <MenuItem value={"others"}>Others</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={(e) =>
                      backRoute(
                        e,
                        state &&
                          state.data.layoutDetails &&
                          (state.data.layoutDetails.type === "CDP" ||
                            state.data.layoutDetails.type === "CEP" ||
                            state.data.layoutDetails.type === "CDEP" ||
                            state.data.layoutDetails.type === "CDEC")
                          ? "collectinformationdetails"
                          : "leadsdetails",
                        state.data
                      )
                    }
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default ClientDetails;
