/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

//Material UI imports
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from "@mui/icons-material/Info";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CommuteIcon from "@mui/icons-material/Commute";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ButtonGroup from "@mui/material/ButtonGroup";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { baseUrl } from "../../util/config";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import "../Home/PlotCard/PlotCard.css";
import "../Home/Home.css";
import { map, includes } from "lodash";
import XHR from "../../util/xhr";
import axios from "axios";
import moment from "moment";
import { isMobile } from "react-device-detect";

//Style imports
import * as CSS from "./style";
import "./LeadsTracking.css";
import { getLeadsReceived } from "../../store/leads/actionCreator";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import LeadTrackingPopup from "../Overlays/LeadTrackingPopup/LeadTrackingPopup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LeadsTracking = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // console.log(
  //   "props.location.state.newId",
  //   state && state
  // );

  const { getLeadsReceived, leads_received } = props;

  const [userDetails, setUserDetails] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [leadsDetails, setLeadsDetails] = React.useState(null);
  const [searchDetails, setSearchDetails] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [viewType, setViewType] = React.useState("1");
  const [isDetailsView, setIsDetailsView] = React.useState(false);
  const [isDetailsData, setIsDetailsData] = React.useState({});
  const [openModel, setOpenModel] = React.useState({
    open: false,
    type: "",
    data: null,
  });

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [inputObj, setInputObj] = React.useState({
    mobile: "",
    from: "",
    to: "",
    stage: "",
  });

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state) {
      setLayoutDetails({
        ...layoutDetails,
        ...state,
      });
    }
  }, [state]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const doSubmit = () => {
    if (!inputObj.mobile || inputObj.mobile.length !== 10) {
      openSnackBar("Please Enter valid mobile no", "error");
      return;
    }

    const payload = {
      mobile: inputObj.mobile,
      layout_id: state.id,
      user_type: userDetails && userDetails.user_type,
      user_id: userDetails && userDetails.user_id,
    };

    setIsLoading(true);

    axios
      .post(`${baseUrl}/leads/tracking/get`, payload)
      .then(
        (res) => {
          console.log("res", res);
          if (res.data && res.data.leadsreceived) {
            setLeadsDetails({
              ...leadsDetails,
              ...res.data.leadsreceived,
            });
          }
          setIsLoading(false);
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          setIsLoading(false);
          //console.log("err", err);
        }
      );
  };

  const getDataByMobileno = (mobile) => {
    const payload = {
      mobile: mobile,
      layout_id: state.id,
      user_type: userDetails && userDetails.user_type,
      user_id: userDetails && userDetails.user_id,
    };

    setIsLoading(true);

    axios
      .post(`${baseUrl}/leads/tracking/get`, payload)
      .then(
        (res) => {
          console.log("res", res);
          if (res.data && res.data.leadsreceived) {
            setIsDetailsView(true);
            setLeadsDetails({
              ...leadsDetails,
              ...res.data.leadsreceived,
            });
          }
          setIsLoading(false);
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          setIsLoading(false);
          //console.log("err", err);
        }
      );
  };

  const doSearchSubmit = () => {
    setIsDetailsView(false);
    if (!inputObj.from || !inputObj.to) {
      openSnackBar("Please select from and to date", "error");
      return;
    }

    const payload = {
      from: inputObj.from,
      to: inputObj.to,
      stage: inputObj.stage,
      layout_id: state.id,
      user_type: userDetails && userDetails.user_type,
      user_id: userDetails && userDetails.user_id,
    };

    setIsLoading(true);

    axios
      .post(`${baseUrl}/leads/tracking/search`, payload)
      .then(
        (res) => {
          console.log("res", res);
          if (res.data && res.data.leadsreceived) {
            console.log("res", res.data.leadsreceived);
            if (res.data.leadsreceived.length > 0) {
              setSearchDetails(res.data.leadsreceived);
            } else {
              setSearchDetails([]);
            }
            setIsLoading(false);
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          setIsLoading(false);
          //console.log("err", err);
        }
      );
  };

  const handleChange = (input, value) => {
    const newDate = moment(new Date(value)).format("YYYY-MM-DD");
    const updatedData = { ...inputObj };
    updatedData[input] = newDate;
    setInputObj(updatedData);
  };

  const resetFilter = () => {
    setLeadsDetails(null);
    setSearchDetails(null);
    setInputObj({
      mobile: "",
      from: "",
      to: "",
      stage: "",
    });
  };

  console.log("searchDetails", searchDetails);

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <LeadTrackingPopup openFlag={openModel} setCloseFlag={setOpenModel} />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="3em 0 2em 0;"
        >
          <HomeHeader
            showBack={true}
            backRoute={(e) =>
              backRoute(e, "layoutdetails", {
                id: layoutDetails.id,
                layout_name: layoutDetails.layout_name,
                project_hash: layoutDetails.project_hash,
              })
            }
          />
        </Grid>
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "42%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}

      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 0 0;"
      >
        <ButtonGroup
          variant="contained"
          orientation={isMobile ? "vertical" : "horizontal"}
        >
          <Button
            className={`pdp-lo-button-grp-leads ${
              viewType === "1" ? "active-tab" : ""
            }`}
            onClick={() => {
              setViewType("1");
              resetFilter();
            }}
          >
            Mobile Search
          </Button>
          <Button
            className={`pdp-lo-button-grp-leads ${
              viewType === "2" ? "active-tab" : ""
            }`}
            onClick={() => {
              setViewType("2");
              resetFilter();
            }}
          >
            Date Search
          </Button>
        </ButtonGroup>
      </Grid>

      {viewType === "1" && (
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="2rem 0 0 0;"
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <div className="login-form-div">
            <TextField
              label="Enter Mobile No"
              variant="outlined"
              className="login-inputWidth"
              type="number"
              size="small"
              onChange={(e) =>
                setInputObj({ ...inputObj, mobile: e.target.value })
              }
              value={inputObj.mobile}
              inputProps={{ maxLength: 10 }}
            />
          </div>
          <div className="login-form-div">
            <Button
              variant="outlined"
              size="medium"
              className="login-button"
              endIcon={<SaveIcon />}
              onClick={doSubmit}
            />
          </div>
        </Grid>
      )}

      {viewType === "2" && (
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="2rem 0 0 0;"
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <div className="login-form-div">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="From Date"
                inputFormat="DD/MM/YYYY"
                value={inputObj.from}
                error={inputObj.from !== ""}
                onChange={(newValue) => {
                  handleChange("from", newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="login-form-div">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="To Date"
                inputFormat="DD/MM/YYYY"
                value={inputObj.to}
                onChange={(newValue) => {
                  handleChange("to", newValue);
                }}
                minDate={inputObj.from}
                disabled={inputObj.from === ""}
                maxDate={
                  inputObj.from === ""
                    ? new Date()
                    : moment(new Date(inputObj.from)).add(30, "d")
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div
            className="login-form-div"
            style={{ width: isMobile ? "60%" : "25%" }}
          >
            <FormControl>
              <InputLabel>Stage</InputLabel>
              <Select
                input={<OutlinedInput label="Stage" />}
                MenuProps={MenuProps}
                name={"Stage"}
                onChange={(e) =>
                  setInputObj({ ...inputObj, stage: e.target.value })
                }
                value={inputObj.stage}
              >
                <MenuItem value={"ALL"}>All Stage</MenuItem>
                <MenuItem value={"NC"}>Not Contacted</MenuItem>
                <MenuItem value={"RCI"}>Received Client Information</MenuItem>
                <MenuItem value={"SLD"}>Shared Layout Details</MenuItem>
                <MenuItem value={"SV"}>Site Visited</MenuItem>
                <MenuItem value={"DTB"}>No of Leads Decided to Buy</MenuItem>
                <MenuItem value={"PP"}>Purchased Plot</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="login-form-div">
            <Button
              variant="outlined"
              size="medium"
              className="login-button"
              endIcon={<SaveIcon />}
              onClick={doSearchSubmit}
            />
          </div>
          {isDetailsView && (
            <div className="login-form-div">
              <Button
                variant="outlined"
                size="medium"
                className="login-button"
                endIcon={<ArrowBackIcon />}
                onClick={() => {
                  setIsDetailsView(false);
                }}
              />
            </div>
          )}
        </Grid>
      )}

      {leadsDetails && (viewType === "1" || isDetailsView === true) && (
        <>
          {isDetailsView === true && (
            <div style={{ textAlign: "-webkit-center" }}>
              <Card className="box-card" key={1}>
                <CardHeader
                  title={
                    <>
                      <Typography className="box-text" gutterBottom>
                        Mobile No :{" "}
                        {isDetailsData && isDetailsData.display_mobile}
                      </Typography>
                      <Typography className="box-text" gutterBottom>
                        Lead Received on :{" "}
                        {moment(
                          new Date(isDetailsData && isDetailsData.req_datetime)
                        ).format("DD/MM/YYYY h:mm A")}
                      </Typography>
                      <Typography className="box-text" gutterBottom>
                        Current Stage :{" "}
                        {isDetailsData && isDetailsData.stage_label}
                      </Typography>
                    </>
                  }
                />
              </Card>
            </div>
          )}
          <Timeline>
            <TimelineItem className={"leads-header"}>
              <TimelineOppositeContent
                className={"leads-header-alternate-title"}
              >
                LEADS RECEIVED
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot className={"leads-header-Icon"}>
                  <AssignmentIcon />
                </TimelineDot>
                <TimelineConnector className={"leads-connector-line"} />
              </TimelineSeparator>
              <TimelineContent className={"leads-header-normal-title"}>
                {`${
                  leadsDetails.req_datetime !== "0000-00-00 00:00:00"
                    ? moment(new Date(leadsDetails.req_datetime)).format(
                        "DD/MM/YYYY h:m A"
                      )
                    : "YET TO BE DONE"
                }`}
              </TimelineContent>
            </TimelineItem>
            {/* ------------------------------------------------------- */}
            <TimelineItem className={"leads-header"}>
              <TimelineContent className={"leads-header-alternate-title"}>
                RECEIVED COLLECT INFORMATION
              </TimelineContent>
              <TimelineSeparator>
                <TimelineDot className={"leads-header-Icon"}>
                  <InfoIcon />
                </TimelineDot>
                <TimelineConnector className={"leads-connector-line"} />
              </TimelineSeparator>
              <TimelineOppositeContent
                className={"leads-header-alternate-value"}
                color="text.secondary"
              >
                <div>
                  {`${
                    leadsDetails.client_expectation_dt !== "0000-00-00 00:00:00"
                      ? moment(
                          new Date(leadsDetails.client_expectation_dt)
                        ).format("DD/MM/YYYY h:m A")
                      : "YET TO BE DONE"
                  }`}
                </div>
                <div>
                  {leadsDetails.client_expectation_dt !==
                  "0000-00-00 00:00:00" ? (
                    <>
                      <Button
                        variant="outlined"
                        className="login-button all-caps"
                        onClick={() => {
                          setOpenModel({
                            open: true,
                            type: 1,
                            data: leadsDetails,
                          });
                        }}
                      >
                        View Leads Details
                      </Button>
                    </>
                  ) : null}
                </div>
                <div>
                  {leadsDetails.client_expectation_dt !==
                  "0000-00-00 00:00:00" ? (
                    <>
                      <Button
                        variant="outlined"
                        className="login-button all-caps"
                        onClick={() => {
                          setOpenModel({
                            open: true,
                            type: 2,
                            data: leadsDetails,
                          });
                        }}
                      >
                        View Leads Expectation
                      </Button>
                    </>
                  ) : null}
                </div>
              </TimelineOppositeContent>
            </TimelineItem>
            {/* ------------------------------------------------------- */}
            <TimelineItem className={"leads-header"}>
              <TimelineOppositeContent
                className={"leads-header-alternate-title"}
              >
                SHARED LAYOUT DETAILS
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot className={"leads-header-Icon"}>
                  <FmdGoodIcon />
                </TimelineDot>
                <TimelineConnector className={"leads-connector-line"} />
              </TimelineSeparator>
              <TimelineContent className={"leads-header-normal-title"}>
                <div>
                  {`${
                    leadsDetails.details_shared_dt !== "0000-00-00 00:00:00"
                      ? moment(new Date(leadsDetails.details_shared_dt)).format(
                          "DD/MM/YYYY h:m A"
                        )
                      : "YET TO BE DONE"
                  }`}
                </div>
                <div>
                  {leadsDetails.details_shared_dt !== "0000-00-00 00:00:00" ? (
                    <>
                      <Button
                        variant="outlined"
                        className="login-button all-caps"
                        onClick={() => {
                          setOpenModel({
                            open: true,
                            type: 3,
                            data: leadsDetails,
                          });
                        }}
                      >
                        View Shared Details
                      </Button>
                    </>
                  ) : null}
                </div>
              </TimelineContent>
            </TimelineItem>

            {/* ------------------------------------------------------- */}
            <TimelineItem className={"leads-header"}>
              <TimelineContent className={"leads-header-alternate-title"}>
                SITE VISITED
              </TimelineContent>
              <TimelineSeparator>
                <TimelineDot className={"leads-header-Icon"}>
                  <PsychologyAltIcon />
                </TimelineDot>
                <TimelineConnector className={"leads-connector-line"} />
              </TimelineSeparator>
              <TimelineOppositeContent
                className={"leads-header-alternate-value"}
                color="text.secondary"
              >
                <div>
                  {`${
                    leadsDetails.site_visit_dt !== "0000-00-00 00:00:00"
                      ? moment(new Date(leadsDetails.details_shared_dt)).format(
                          "DD/MM/YYYY h:m A"
                        )
                      : "YET TO BE DONE"
                  }`}
                </div>
                <div>
                  {leadsDetails.site_visit_dt !== "0000-00-00 00:00:00" ? (
                    <>
                      <Button
                        variant="outlined"
                        className="login-button all-caps"
                        onClick={() => {
                          setOpenModel({
                            open: true,
                            type: 4,
                            data: leadsDetails,
                          });
                        }}
                      >
                        Site Visit Status
                      </Button>
                    </>
                  ) : null}
                </div>
              </TimelineOppositeContent>
            </TimelineItem>
            {/* ------------------------------------------------------- */}
            <TimelineItem className={"leads-header"}>
              <TimelineOppositeContent
                className={"leads-header-alternate-title"}
              >
                No of Leads Decided to Buy
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot className={"leads-header-Icon"}>
                  <CommuteIcon />
                </TimelineDot>
                <TimelineConnector className={"leads-connector-line"} />
              </TimelineSeparator>
              <TimelineContent className={"leads-header-normal-title"}>
                {`${
                  leadsDetails.purchase_decision_dt !== "0000-00-00 00:00:00"
                    ? moment(
                        new Date(leadsDetails.purchase_decision_dt)
                      ).format("DD/MM/YYYY h:m A")
                    : "YET TO BE DONE"
                }`}
              </TimelineContent>
            </TimelineItem>
            {/* ------------------------------------------------------- */}
            <TimelineItem className={"leads-header"}>
              <TimelineContent className={"leads-header-alternate-title"}>
                PURCHASED PLOT
              </TimelineContent>
              <TimelineSeparator>
                <TimelineDot className={"leads-header-Icon"}>
                  <AttachMoneyIcon />
                </TimelineDot>
                <TimelineConnector className={"leads-connector-line"} />
              </TimelineSeparator>
              <TimelineOppositeContent
                className={"leads-header-alternate-value"}
                color="text.secondary"
              >
                {`${
                  leadsDetails.conversion_dt !== "0000-00-00 00:00:00"
                    ? moment(new Date(leadsDetails.conversion_dt)).format(
                        "DD/MM/YYYY h:m A"
                      )
                    : "YET TO BE DONE"
                }`}
              </TimelineOppositeContent>
            </TimelineItem>
            {/* ------------------------------------------------------- */}
            <TimelineItem className={"leads-header"}>
              <TimelineOppositeContent
                className={"leads-header-alternate-title"}
              >
                GOT FEEDBACK
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot className={"leads-header-Icon"}>
                  <SupportAgentIcon />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent className={"leads-header-normal-title"}>
                {`${
                  leadsDetails.feedback_dt !== "0000-00-00 00:00:00"
                    ? moment(new Date(leadsDetails.feedback_dt)).format(
                        "DD/MM/YYYY h:m A"
                      )
                    : "YET TO BE DONE"
                }`}
              </TimelineContent>
            </TimelineItem>
            {/* ------------------------------------------------------- */}
          </Timeline>
        </>
      )}

      {searchDetails && viewType === "2" && isDetailsView !== true && (
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {searchDetails &&
              searchDetails.length > 0 &&
              map(searchDetails, function (data, index) {
                return (
                  <Card
                    className="box-card"
                    key={index}
                    onClick={(e) => {
                      getDataByMobileno(data.display_mobile);
                      setIsDetailsData(data);
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{ background: "#ffeedd", color: "#462B00" }}
                          aria-label="recipe"
                        >
                          {index + 1}
                        </Avatar>
                      }
                      title={
                        <>
                          <Typography className="box-text" gutterBottom>
                            Mobile No : {data.display_mobile}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Lead Received on :{" "}
                            {moment(new Date(data.req_datetime)).format(
                              "DD/MM/YYYY h:mm A"
                            )}
                          </Typography>
                          <Typography className="box-text" gutterBottom>
                            Current Stage : {data.stage_label}
                          </Typography>
                        </>
                      }
                    />
                  </Card>
                );
              })}
            {searchDetails && searchDetails.length === 0 && (
              <Card className="box-card" key={0}>
                <CardContent
                  className="box-content"
                  style={{ textAlign: "center" }}
                >
                  <Typography className="box-text" gutterBottom>
                    No Data
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      )}
    </CSS.ParentDiv>
  );
};

const mapStateToProps = (state) => {
  return {
    leads_received: state.leads.leads_received,
  };
};
const mapDispatchToProps = {
  getLeadsReceived,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsTracking);
