import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReCAPTCHA from "react-google-recaptcha";

import { connect } from "react-redux";
import { loginAction } from "../../store/auth/actionCreator";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";

import "./Login.css";

function Login(props) {
  const navigate = useNavigate();
  const { user, loginAction } = props;

  const [captcha, setCaptcha] = useState(null);
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString !== "" ? JSON.parse(userDetailsString) : {};

    if (!userDetails.isLogined) {
      navigate("/login", {});
    } else if (userDetails.user_type === "P") {
      navigate("/promoters", {});
    } else if (userDetails.user_type === "D") {
      navigate("/projects", {});
    } else if (userDetails.user_type === "S") {
      navigate("/projects", {});
    } else if (userDetails.user_type === "O") {
      navigate("/omtteam", {});
    }
  }, []);

  const { isActive, message, openSnackBar, setIsActive, severity } =
    useSnackbar();

  const handleLogin = (event) => {
    event.preventDefault();
    if (!userDetails.email || !userDetails.password) {
      //console.log(`Email and Password required`);
      openSnackBar(`Email and Password required`);
      return;
    }
    // if (!captcha) {
    //   openSnackBar(`Accept Captcha`);
    //   return;
    // }

    loginAction(userDetails, 1).then((res) => {
      console.log("res", res);
      if (res.status) {
        // login success
        console.log("res", res);
        if (res.user_type === "P") {
          navigate("/promoters", {});
        } else if (res.user_type === "D") {
          navigate("/projects", {});
        } else if (res.user_type === "S") {
          navigate("/projects", {});
        } else if (res.user_type === "O") {
          console.log("omtteam", res);
          navigate("/omtteam", {});
        }
      } else {
        openSnackBar(`Invalid email and password`);
      }
    });
  };

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  function onChange(value) {
    setCaptcha(value);
    //console.log("Captcha value:", value);
  }

  const onForgotPwdBtnClk = () => {
    //console.log("onForgotPwdBtnClk");
  };

  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  };

  return (
    <>
      <Snackbar
        open={isActive}
        message={message}
        severity={severity}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper elevation={0} bgcolor="white" style={{ borderRadius: "16px" }}>
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Sign in</span>
              </div>
              <div className="login-form-div">
                <TextField
                  label="E-Mail"
                  variant="outlined"
                  className="login-inputWidth login-text"
                  size="small"
                  autoComplete="off"
                  autoFocus
                  onChange={(e) => handleChange(e)}
                  name={"email"}
                  value={userDetails.email}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Password"
                  type="password"
                  className="login-inputWidth login-text"
                  variant="outlined"
                  size="small"
                  helperText="Forgot Password?"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                  name={"password"}
                  value={userDetails.password}
                  FormHelperTextProps={{
                    onClick: () => onForgotPwdBtnClk(),
                  }}
                />
              </div>
              {/* <ReCAPTCHA
                sitekey="6LcQdPUgAAAAAGL6A3j8EEe3fT9iMvhJL-j5UAqd"
                onChange={onChange}
                className="recaptcha-form-div"
              /> */}
              <div className="login-form-div">
                <Button
                  variant="outlined"
                  size="medium"
                  disabled={user.username === "" || user.password === ""}
                  onClick={(e) => handleLogin(e)}
                  className="login-inputWidth login-button"
                >
                  Sign in
                </Button>
              </div>
              <Copyright />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = {
  loginAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
