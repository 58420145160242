import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TuneIcon from "@mui/icons-material/Tune";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import NorthIcon from "@mui/icons-material/North";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import SouthIcon from "@mui/icons-material/South";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";

//Custom imports
import HomeHeader from "../HeaderMenu/HomeHeader";
import PlotsPopup from "../Overlays/PlotsPopup/PlotsPopup";
import FilterPlotsPopup from "../Overlays/FilterPlotsPopup/FilterPlotsPopup";
import Filter from "../Overlays/FilterPlotsPopup/Filter/Filter";
import "../Home/PlotCard/PlotCard.css";
import "../SiteVisit/SiteVisit.css";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import moment from "moment";
import axios from "axios";
import { map } from "lodash";
import * as CSS from "../Overlays/Style.js";

import { baseUrl } from "../../util/config";

// Store imports
import { getPlots, getUserList } from "../../store/layout/actionCreator";

const Plots = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { getPlots, plots, getUserList, user_list } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);
  const [details, setDetails] = React.useState(null);
  const [openPlotsPopup, setOpenPlotsPopup] = React.useState(false);

  const [layoutFilter, setLayoutFilter] = React.useState({
    availability: "ANY",
    corner: "ANY",
    direction: "ANY",
  });

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state) {
      setLayoutDetails({
        ...layoutDetails,
        ...state,
      });
      getPlots({
        project_id: state.id,
      });
    }
  }, [state]);

  React.useEffect(() => {
    if (userDetails) {
      getUserList({
        user_id: userDetails.user_id,
      });
    }
  }, [userDetails]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = (data) => {
    setOpenFilterPopup(false);
    setFilterCond(data);
  };

  const onPlotsSubmit = (data) => {
    console.log(data);
    const payload = {
      ...data,
    };
    axios.post(`${baseUrl}/realestate/plots/updatestatus`, payload).then(
      (res) => {
        if (res.data && res.data.status) {
          getPlots({
            project_id: state.id,
          });
          setOpenPlotsPopup(false);
        }
      },
      (err) => {
        openSnackBar("Failed to submit", "success");
        //console.log("err", err);
      }
    );
  };

  const viewClick = (e, data) => {
    e.stopPropagation();
    //console.log("data", data);
    setDetails(data);
    setOpenPlotsPopup(true);
  };

  const getFilterStatus = () => {
    let str = "";
    if (filterCond && filterCond.includes(0)) {
      str = str !== "" ? str + ", available" : "available";
    }

    if (filterCond && filterCond.includes(1)) {
      str = str !== "" ? str + ", sold" : "sold";
    }

    if (filterCond && filterCond.includes(2)) {
      str = str !== "" ? str + ", booked" : "booked";
    }
    return str;
  };

  const getDirection = (type) => {
    switch (type) {
      case 1:
        return "east";
      case 2:
        return "west";
      case 3:
        return "north";
      case 4:
        return "south";
      case 5:
        return "north east";
      case 6:
        return "north west";
      case 7:
        return "south east";
      case 8:
        return "south west";
      default:
        return "";
    }
  };

  const getDirectionIcon = (type) => {
    switch (type) {
      case 1:
        return <EastIcon fontSize="30" />;
      case 2:
        return <WestIcon fontSize="30" />;
      case 3:
        return <NorthIcon fontSize="30" />;
      case 4:
        return <SouthIcon fontSize="30" />;
      case 5:
        return <NorthEastIcon fontSize="30" />;
      case 6:
        return <NorthWestIcon fontSize="30" />;
      case 7:
        return <SouthEastIcon fontSize="30" />;
      case 8:
        return <SouthWestIcon fontSize="30" />;
      default:
        return "";
    }
  };

  const filterOnChange = (filterData) => {
    // console.log("$$$$$$$$Test", filterData);
    let newFilter = {};
    filterData.map((ele) => {
      if (ele.displayVal !== "ANY") {
        // console.log("ele", ele);
        if (
          ele.key === "direction" ||
          ele.key === "corner" ||
          ele.key === "availability"
        ) {
          let dataId = [];
          ele.val.filter((data) => {
            if (data.ticked) dataId.push(data.id);
          });
          newFilter[ele.key] = dataId;
        }
      } else {
        newFilter[ele.key] = "ANY";
      }
    });
    // console.log("$$$$$$$$Test", newFilter);
    setLayoutFilter({
      ...layoutFilter,
      ...newFilter,
    });
  };

  const getFilteredPlots = (plots) => {
    let plotCopy = JSON.parse(JSON.stringify(plots));

    if (layoutFilter.availability !== "ANY") {
      if (layoutFilter.availability.length > 0) {
        let filterAvailability = layoutFilter.availability.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter((el) =>
            filterAvailability.includes(parseInt(el.plot_status))
          );
      }
    }

    if (layoutFilter.direction !== "ANY") {
      if (layoutFilter.direction.length > 0) {
        let filterDirection = layoutFilter.direction.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter((el) =>
            filterDirection.includes(parseInt(el.direction))
          );
      }
    }

    if (layoutFilter.corner !== "ANY") {
      if (layoutFilter.corner.length > 0) {
        let filterCorner = layoutFilter.corner.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter((el) => filterCorner.includes(el.is_corner));
      }
    }

    return plotCopy;
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <FilterPlotsPopup
        openFlag={openFilterPopup}
        setCloseFlag={setOpenFilterPopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
      />
      <PlotsPopup
        openFlag={openPlotsPopup}
        setCloseFlag={setOpenPlotsPopup}
        onSubmit={onPlotsSubmit}
        openSnackBar={openSnackBar}
        details={details}
        userList={user_list}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={true}
              backRoute={(e) =>
                backRoute(e, "layoutdetails", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  project_hash: layoutDetails.project_hash,
                })
              }
            />
          </Grid>
        </Grid>

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}

        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-txt">plots</span>
        </Grid>

        {filterCond && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-filter">
              filter status : {getFilterStatus()}
            </span>
          </Grid>
        )}
        {plots && plots.length > 0 && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-filter">
              no. of records : {getFilteredPlots(plots).length}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="2em 0 2em 0;"
        >
          <Filter fliterFor={"pdp"} filterOnChange={filterOnChange} />
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            {plots &&
              plots.length > 0 &&
              map(getFilteredPlots(plots), function (data, index) {

                console.log('#### data', data);

                let plotStatus =
                  data && data.plot_status !== ""
                    ? data.plot_status === 0
                      ? "Unsold"
                      : data.plot_status === 1
                      ? "Sold"
                      : "Booked"
                    : " - ";
                return (filterCond && filterCond.includes(data.plot_status)) ||
                  !filterCond ? (
                  <Grid
                    container
                    direction="column"
                    gap="1em"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      background:
                        data.blocked_by_promoter === 1
                          ? "#8fb3dc"
                          : data.plot_status === 0
                          ? "#B9CDA0"
                          : data.plot_status === 1
                          ? "#FFB4AB"
                          : "#C1A788",
                      border:
                        data.blocked_by_promoter === 1
                          ? "1px solid #8fb3dc"
                          : data.plot_status === 0
                          ? "1px solid #B9CDA0"
                          : data.plot_status === 1
                          ? "1px solid #FFB4AB"
                          : "1px solid #C1A788",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
                      width: "75%",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                    key={index}
                    onClick={(e) => viewClick(e, data)}
                  >
                    <CSS.CardContainer>
                      <CSS.CardContainerTitle>PLOT NO. 123</CSS.CardContainerTitle>
                      <CSS.CardContainerContent>
                        {data && data.name}
                      </CSS.CardContainerContent>
                    </CSS.CardContainer>
                    <div>
                      <CSS.ChipContainer plotStatus={plotStatus.toLowerCase()}>
                        <CSS.ChipContent plotStatus={plotStatus.toLowerCase()}>
                          {plotStatus.toLowerCase() === "unsold"
                            ? "available"
                            : plotStatus.toLowerCase()}
                        </CSS.ChipContent>
                      </CSS.ChipContainer>
                      <div className="pdp-map-popup-chip-container-orange">
                        <span className="pdp-map-popup-chip-contents">
                          {data && data.is_corner === "yes"
                            ? "CORNER PLOT"
                            : "NOT A CORNER PLOT"}
                        </span>
                      </div>

                      <div className="pdp-map-popup-chip-container-orange">
                        <span className="pdp-map-popup-chip-contents">
                          {(data && getDirection(parseInt(data.direction))) ||
                            ""}
                        </span>
                      </div>
                    </div>
                  </Grid>
                ) : null;
              })}
            {plots && plots.length === 0 && (
              <Card className="box-card" key={0}>
                <CardContent
                  className="box-content"
                  style={{ textAlign: "center" }}
                >
                  <Typography className="box-text" gutterBottom>
                    No Data
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* <Fab
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 200,
          background: "#462B00",
          color: "#fff",
        }}
        aria-label={"filter"}
        onClick={(e) => setOpenFilterPopup(true)}
      >
        <TuneIcon />
      </Fab> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    plots: state.layout.plots,
    user_list: state.layout.user_list,
  };
};
const mapDispatchToProps = {
  getPlots,
  getUserList,
};
export default connect(mapStateToProps, mapDispatchToProps)(Plots);
