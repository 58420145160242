import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

//Other lib imports
import axios from "axios";

//Custom imports
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import * as CSS from "./style";
import HomeHeader from "../HeaderMenu/HomeHeader";

import { baseUrlPlotsApi } from "../../util/config";

const AddPromoter = () => {
  const navigate = useNavigate();

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [registrationData, setRegistrationData] = useState({
    promotor_name: "",
    email: "",
    name: "",
    contact: "",
    website: "",
    address: "",
    comment: "",
    captcha: "",
  });
  const [errors, setErrors] = useState({});

  const registrationInputChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.value;
    const updatedData = { ...registrationData };
    updatedData[modifiedKey] = modifiedValue;
    setRegistrationData(updatedData);
    validate({ [modifiedKey]: modifiedValue });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("contact" in fieldValues) {
      temp.contact = fieldValues.contact ? "" : "This field is required.";
      if (fieldValues.contact)
        temp.contact =
          fieldValues.contact.length === 10 ? "" : "Mobile no is not valid.";
    }

    setErrors({
      ...temp,
    });
  };

  const onSubmitBtnClk = () => {
    if (
      !registrationData.promotor_name ||
      !registrationData.name ||
      !registrationData.email ||
      !registrationData.contact ||
      !registrationData.address ||
      (errors["email"] && errors["email"] !== "") ||
      (errors["contact"] && errors["contact"] !== "")
    ) {
      if (errors["email"] && errors["email"] !== "") {
        openSnackBar("Enter Valid Email Id", "error");
        return;
      }
      if (errors["contact"] && errors["contact"] !== "") {
        openSnackBar("Enter valid contact mobile no", "error");
        return;
      }
      openSnackBar("Enter all inputs", "error");
      return;
    }
    doSubmit(registrationData);
  };

  const doSubmit = (data) => {
    // setIsLoading(true);
    const payload = { ...data };
    axios.post(`${baseUrlPlotsApi}/realestate_v1/promotor/add`, payload).then(
      (res) => {
        console.log("res.data.data", res);
        if (res.data && res.data.status) {
          openSnackBar("Added Successfully", "success");
          setRegistrationData({
            ...registrationData,
            promotor_name: "",
            email: "",
            name: "",
            contact: "",
            website: "",
            address: "",
            comment: "",
            captcha: "",
          });
        } else {
          openSnackBar("User already exist", "error");
        }
      },
      (err) => {
        // setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <HomeHeader />
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Add Promoter</span>
              </div>
              <div className="login-form-div">
                <TextField
                  name={"promotor_name"}
                  label="Promoter Name"
                  required
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  onChange={registrationInputChange}
                  value={registrationData.promotor_name}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"email"}
                  label="Company E-Mail"
                  required
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  onChange={registrationInputChange}
                  value={registrationData.email}
                  {...(errors["email"] && {
                    error: true,
                    helperText: errors["email"],
                  })}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"name"}
                  onChange={registrationInputChange}
                  value={registrationData.name}
                  required
                  label="Proprietor / Contact Person Name"
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"contact"}
                  onChange={registrationInputChange}
                  value={registrationData.contact}
                  required
                  label="Contact Mobile No"
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  {...(errors["contact"] && {
                    error: true,
                    helperText: errors["contact"],
                  })}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"website"}
                  onChange={registrationInputChange}
                  value={registrationData.website}
                  label="Website (Optional)"
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"address"}
                  onChange={registrationInputChange}
                  value={registrationData.address}
                  required
                  label="Company HQ Address"
                  multiline
                  rows={4}
                  fullWidth={true}
                  className="login-inputWidth"
                />
              </div>
              <div className="login-form-div">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onSubmitBtnClk}
                  className="login-inputWidth login-button"
                  endIcon={<EastIcon />}
                >
                  Submit
                </Button>
              </div>
              <div className="login-form-div">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    navigate("/", {
                      state: {},
                    });
                  }}
                  className="login-inputWidth login-button"
                >
                  Back
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default AddPromoter;
