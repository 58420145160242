import { isMobile } from "react-device-detect";
//Material UI imports
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
//Custom imports
import * as CSS from "./FilterStyle";

const RadioFilter = (props) => {

    const { objIndex, objContent, onModify, onDone } = props;

    const handleChange = (event) => {
        const updatedObj = {...objContent, val: event.target.value, displayVal: event.target.value};
        onModify(objIndex, updatedObj);
    };

    return (
    <CSS.ParentDiv isMobile={isMobile}>
        <RadioGroup value={objContent.val} onChange={handleChange}>
            <FormControlLabel value="ALL" control={<Radio sx={{ color: '#643f00', '&.Mui-checked': {color: '#643f00'} }} />} label="All" />
            <FormControlLabel value="YES" control={<Radio sx={{ color: '#643f00', '&.Mui-checked': {color: '#643f00'} }} />} label="Yes, Available" />
            <FormControlLabel value="NO" control={<Radio sx={{ color: '#643f00', '&.Mui-checked': {color: '#643f00'} }}/>} label="No, not Available" />
            <div className='filter-button-radio-cntr'>
                <Button variant="outlined" size={ isMobile ? 'large' : 'small' } onClick={onDone} className='filter-button filter-button-radio'>
                    Done
                </Button>
            </div>
        </RadioGroup>
    </CSS.ParentDiv>
    )
}

export default RadioFilter;
