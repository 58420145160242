import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

//Custom imports
import HomeHeader from "../../../HeaderMenu/HomeHeader";
import "../../../Home/PlotCard/PlotCard.css";
import "../../Leads.css";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import moment from "moment";

// Store imports
import { getLeadsSharedLayoutList } from "../../../../store/leads/actionCreator";
import FilterPopup from "../../../Overlays/FilterPopup/FilterPopup";

import { map } from "lodash";

const PurchaseDecision = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { leads_count, getLeadsSharedLayoutList } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState(null);

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  React.useEffect(() => {
    if (state) {
      setLayoutDetails({
        ...layoutDetails,
        ...state,
      });
      getLeadsSharedLayoutList({
        layout_id: state.id,
      });
    }
  }, [state]);

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = (data) => {
    setOpenFilterPopup(false);
    setFilterCond(data);
  };

  const doRoute = (e, menu_url, data) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: data,
      });
    }
  };

  console.log("leads_count", leads_count, state);

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <FilterPopup
        openFlag={openFilterPopup}
        setCloseFlag={setOpenFilterPopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        margin="2em 0 0 0;"
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <HomeHeader
              showBack={false}
              backRoute={(e) =>
                backRoute(e, "leads", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            />
          </Grid>
        </Grid>

        {userDetails && userDetails.user_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {userDetails.user_name.toLowerCase()}
            </span>
          </Grid>
        )}

        {layoutDetails && layoutDetails.layout_name && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {layoutDetails.layout_name.toLowerCase()}
            </span>
          </Grid>
        )}
        {filterCond && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-filter">
              Report for the date range :{" "}
              {moment(new Date(filterCond.from)).format("DD/MM/YYYY")} to{" "}
              {moment(new Date(filterCond.to)).format("DD/MM/YYYY")}
            </span>
          </Grid>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              className="breadcrumb breadcrumb-underline"
              onClick={(e) =>
                backRoute(e, "leads", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                })
              }
            >
              Leads Home
            </Link>
            <Typography color="text.primary" className="breadcrumb">
              Purchase Decision
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="1em 0 1em 0;"
          >
            <Button variant="outlined" className="pc-leads-button">
              {`${
                leads_count && "is_purchase_decision_total" in leads_count
                  ? leads_count.is_purchase_decision_total
                  : 0
              } POSSIBLE FOR Purchase Decision`}
            </Button>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Button
              variant="outlined"
              className="pc-leads-not-contacted-button"
              onClick={(e) =>
                doRoute(e, "purchaselist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  type: "PDP",
                })
              }
            >
              {`No of Leads Decision is Pending (${
                leads_count && "is_purchase_decision_pending" in leads_count
                  ? leads_count.is_purchase_decision_pending
                  : 0
              })`}
            </Button>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Button
              variant="outlined"
              className="pc-leads-contacted-button"
              onClick={(e) =>
                doRoute(e, "purchaselist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  type: "PDB",
                })
              }
            >
              {`No of Leads Decided to Buy (${
                leads_count && "is_purchase_decision_buy" in leads_count
                  ? leads_count.is_purchase_decision_buy
                  : 0
              })`}
            </Button>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Button
              variant="outlined"
              className="pc-leads-not-contacted-button"
              onClick={(e) =>
                doRoute(e, "purchaselist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  type: "PDNB",
                })
              }
            >
              {`No of Leads Not Interested to Buy (${
                leads_count && "is_purchase_decision_not_buy" in leads_count
                  ? leads_count.is_purchase_decision_not_buy
                  : 0
              })`}
            </Button>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Button
              variant="outlined"
              className="pc-leads-not-done-button"
              onClick={(e) =>
                doRoute(e, "purchaselist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  type: "PDH",
                })
              }
            >
              {`No of Leads Put The Decision On Hold (${
                leads_count && "is_purchase_decision_hold" in leads_count
                  ? leads_count.is_purchase_decision_hold
                  : 0
              })`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Fab
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 200,
          background: "#462B00",
          color: "#fff",
        }}
        aria-label={"filter"}
        onClick={(e) => setOpenFilterPopup(true)}
      >
        <CalendarMonthIcon />
      </Fab>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leads_count: state.leads.leads_count,
  };
};
const mapDispatchToProps = {
  getLeadsSharedLayoutList,
};
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDecision);
