import { useState, useEffect } from "react";

//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// Other Imports

import { map, includes } from "lodash";

//Custom imports
import * as CSS from "../Style.js";
import XHR from "../../../util/xhr";

const EmployeePopup = (props) => {
  const { openFlag, setCloseFlag, onSubmit, openSnackBar, editUser } = props;

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    contact: "",
    ori_password: "",
    user_id: "",
    promoter_id: "",
    role_id: 0,
    mLayouts: [],
    menuIds: [],
  });
  const [userDetails, setUserDetails] = useState(null);
  const [layoutDetails, setLayoutDetails] = useState([]);
  const [menuDetails, setMenuDetails] = useState([]);
  const [actionMenuDetails, setActionMenuDetails] = useState([]);
  const [errors, setErrors] = useState({});
  const [roleDetails, setRoleDetails] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
      setUserData({
        ...userData,
        promoter_id: userToken.user_id,
      });
    }
  }, []);

  useEffect(() => {
    if (editUser) {
      //console.log("editUser", editUser);
      setUserData({
        ...userData,
        name: editUser.name,
        email: editUser.email,
        contact: editUser.contact,
        ori_password: editUser.ori_password,
        user_id: editUser.id,
        mLayouts: editUser.mLayouts,
        menuIds: editUser.menuIds,
        role_id: editUser.role_id,
      });
    }
  }, [editUser]);

  useEffect(() => {
    if (userDetails) {
      let inputs = {
        promoter_id: userDetails.user_id,
        user_id: userData.user_id,
      };
      XHR.post("realestate/mapped/list", inputs).then(
        (res) => {
          if (res.data && res.data.projects && res.data.projects.length > 0) {
            setLayoutDetails(res.data.projects);
          }
        },
        (err) => {}
      );

      XHR.post("realestate_v1/menu", inputs).then(
        (res) => {
          if (res.data && res.data.menu && res.data.menu.length > 0) {
            setMenuDetails(res.data.menu);
          }
          if (res.data && res.data.action && res.data.action.length > 0) {
            setActionMenuDetails(res.data.action);
          }
        },
        (err) => {}
      );

      XHR.post("realestate_v1/role/get", {
        promotor_id: userDetails.user_id,
      }).then(
        (res) => {
          if (res.data && res.data.role) {
            setRoleDetails(res.data.role);
          }
        },
        (err) => {}
      );
    }
  }, [userDetails]);

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
    setErrors({
      ...errors,
      name: "",
      ori_password: "",
      email: "",
      contact: "",
      role_id: "",
    });
  };

  const validateInputValue = (e) => {
    const { name, value } = e.target;
    validate({ [name]: value });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("ori_password" in fieldValues)
      temp.ori_password = fieldValues.ori_password
        ? ""
        : "This field is required.";

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("contact" in fieldValues) {
      temp.contact = fieldValues.contact ? "" : "This field is required.";
      if (fieldValues.contact)
        temp.contact =
          fieldValues.contact.length === 10 ? "" : "Mobile no is not valid.";
    }

    setErrors({
      ...temp,
    });
  };

  const handleCheckboxChange = (e, type) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.checked;
    const updatedData = { ...userData };
    let updateArray = updatedData[type];
    //console.log("modifiedValue", modifiedValue, modifiedKey, updateArray);
    if (e.target.checked) {
      updateArray.push(parseInt(modifiedKey));
    } else {
      updateArray = updateArray.filter(function (e) {
        return e !== parseInt(modifiedKey);
      });
    }
    updatedData[type] = updateArray;
    setUserData(updatedData);
  };

  const doSubmit = () => {
    if (
      !userData.name ||
      !userData.email ||
      !userData.contact ||
      !userData.ori_password ||
      !userData.role_id ||
      userData.mLayouts.length === 0 ||
      userData.menuIds.length === 0
    ) {
      //console.log("openSnackBar");
      openSnackBar("Enter all inputs", "error");
      return;
    }
    onSubmit(userData);
  };

  const handleRoleChange = (e) => {
    if (e.target.value) {
      setUserData({
        ...userData,
        ["role_id"]: e.target.value,
      });
    }
  };

  console.log("userData", userData);

  return (
    <>
      <CSS.UserDialog
        open={openFlag}
        onClose={() => setCloseFlag(false)}
        maxWidth={"lg"}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="1em 0 1em 0;"
        >
          <CSS.CloseBtn>
            <IconButton
              aria-label="closePopup"
              onClick={() => setCloseFlag(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </CSS.CloseBtn>
          <CSS.TitleContainer>
            <span className="title">User</span>
          </CSS.TitleContainer>
          <TextField
            label="Name"
            autoComplete="off"
            fullWidth={true}
            onBlur={validateInputValue}
            onChange={handleInputValue}
            name={"name"}
            value={userData.name}
            {...(errors["name"] && {
              error: true,
              helperText: errors["name"],
            })}
          />
          <TextField
            label="E-Mail"
            autoComplete="off"
            fullWidth={true}
            onBlur={validateInputValue}
            onChange={handleInputValue}
            name={"email"}
            value={userData.email}
            {...(errors["email"] && {
              error: true,
              helperText: errors["email"],
            })}
          />
          <TextField
            label="Mobile No"
            type="number"
            autoComplete="off"
            fullWidth={true}
            onBlur={validateInputValue}
            onChange={handleInputValue}
            name={"contact"}
            value={userData.contact}
            inputProps={{ maxLength: 10 }}
            {...(errors["contact"] && {
              error: true,
              helperText: errors["contact"],
            })}
          />
          <TextField
            label="Password"
            autoComplete="off"
            fullWidth={true}
            onBlur={validateInputValue}
            onChange={handleInputValue}
            name={"ori_password"}
            value={userData.ori_password}
            {...(errors["ori_password"] && {
              error: true,
              helperText: errors["ori_password"],
            })}
          />
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink={false}>
              {userData && userData.role_id === 0 && "Select Role"}
            </InputLabel>
            <Select
              value={(userData && userData.role_id) || ""}
              onChange={(e) => {
                handleRoleChange(e);
              }}
            >
              {roleDetails.map((option, index) => (
                <MenuItem value={option.id}>{option.role_title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <CSS.TitleContainer>
            <span className="title">Assign Layouts</span>
          </CSS.TitleContainer>
          {layoutDetails.length > 0 && (
            <FormGroup style={{ width: "100%" }}>
              {map(layoutDetails, function (data, index) {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={includes(userData.mLayouts, data.id)}
                        onChange={(e) => handleCheckboxChange(e, "mLayouts")}
                        name={data.id}
                        style={{
                          color: "#835400",
                        }}
                      />
                    }
                    label={data && data.name}
                  />
                );
              })}
            </FormGroup>
          )}
          <CSS.TitleContainer>
            <span className="title">Assign Modules</span>
          </CSS.TitleContainer>
          {menuDetails.length > 0 && (
            <FormGroup style={{ width: "100%" }}>
              {map(menuDetails, function (data, index) {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={includes(userData.menuIds, data.menu_id)}
                        onChange={(e) => handleCheckboxChange(e, "menuIds")}
                        name={data.menu_id}
                        style={{
                          color: "#835400",
                        }}
                      />
                    }
                    label={data && data.menu_name}
                  />
                );
              })}
            </FormGroup>
          )}
          <CSS.TitleContainer>
            <span className="title">Action Modules</span>
          </CSS.TitleContainer>
          {actionMenuDetails.length > 0 && (
            <FormGroup style={{ width: "100%" }}>
              {map(actionMenuDetails, function (data, index) {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={includes(userData.menuIds, data.menu_id)}
                        onChange={(e) => handleCheckboxChange(e, "menuIds")}
                        name={data.menu_id}
                        style={{
                          color: "#835400",
                        }}
                      />
                    }
                    label={data && data.menu_name}
                  />
                );
              })}
            </FormGroup>
          )}

          <CSS.customBtn variant="outlined" onClick={doSubmit}>
            Submit
          </CSS.customBtn>
        </Grid>
      </CSS.UserDialog>
    </>
  );
};

export default EmployeePopup;
