import styled from 'styled-components';
import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    background: linear-gradient(121.26deg, #462B00 13.94%, #643F00 40.06%, #835400 65.64%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 30px rgba(0, 0, 0, 0.05);
    border-radius: 100px;

    .iconBtns {
        color: #FFEEDD;
    }
`;

const CustBtn = styled(Button)`
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
`;

const CustBtnGrp = styled(ButtonGroup)`
    button {
        border-color: indigo !important;
    }
`;

export { 
    BtnContainer,
    CustBtn,
    CustBtnGrp
}