import {} from "./actionType";
import XHR from "../../util/xhr";
import { LOGIN_SUCCESS, LOGIN_FAILED, CONFIG } from "./actionType";

export function loginAction(data, type) {
  return async function (dispatch) {
    return XHR.post(type === 1 ? "authenticateV1" : "register", data).then(
      (res) => {
        if (res.data.status) {
          console.log("data", data);
          const userDetails = {
            email: data.email,
            user_id: res.data.user_id,
            user_type: res.data.user_type,
            user_name: res.data.user_name,
          };
          localStorage.setItem(
            "realestate_user",
            JSON.stringify({ ...userDetails, isLogined: true })
          );

          setTimeout(() => {
            dispatch({
              type: LOGIN_SUCCESS,
              data: {
                isAuthenticated: true,
                user_id: res.data.user_id,
                user_type: res.data.user_type,
                user_name: res.data.user_name,
              },
            });
          }, 500);

          return Promise.resolve(res.data);
        } else {
          dispatch({
            type: LOGIN_FAILED,
            data: { isAuthenticated: false, user_id: 0 },
          });
          return false;
        }
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function getConfig() {
  return async function (dispatch) {
    return XHR.post("config", {}).then(
      (res) => {
        if (res.data.status) {
          dispatch({
            type: CONFIG,
            data: { googleApiKey: res.data.googleApiKey },
          });
          return Promise.resolve(res.data);
        }
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function logout() {
  return function () {
    localStorage.removeItem("realestate_user");
    window.location.href = "/portal";
  };
}
