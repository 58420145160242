import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import Home from "../Components/Home/Home";
import Login from "../Components/Login/Login";
import LayoutDetails from "../Components/LayoutDetails/LayoutDetails";
import SiteVisit from "../Components/SiteVisit/SiteVisit";
import Favourite from "../Components/Favourite/Favourite";
import Enqueries from "../Components/Enqueries/Enqueries";
import Plots from "../Components/Plots/Plots";
import History from "../Components/History/History";
import Google from "../Components/Google/Google";
import Promoters from "../Components/Promoters/Promoters";
import OmtTeam from "../Components/OmtTeam/OmtTeam";
import ViewEmployees from "../Components/ViewEmployees/ViewEmployees";
import Leads from "../Components/Leads/Leads";
// import ConfirmSharedDetails from '../Components/Leads/Forms/StageThree/ConfirmSharedDetails';
import LeadsDashboard from "../Components/Leads/LeadsDashboard";
import Role from "../Components/Role/Role";
import LeadsReceived from "../Components/Leads/LeadsReceived";
import LeadsContacted from "../Components/Leads/LeadsContacted";
import LeadsNotContacted from "../Components/Leads/LeadsNotContacted";
import LeadsContactedNoResponse from "../Components/Leads/LeadsContactedNoResponse";
import LeadsDetails from "../Components/Leads/LeadsDetails";
import AddContactLog from "../Components/Leads/Forms/StageOne/AddContactLog";
import ViewContactLog from "../Components/Leads/ViewContactLog";

import ClientDetails from "../Components/Leads/Forms/StageTwo/ClientDetails";
import ClientExpectation from "../Components/Leads/Forms/StageTwo/ClientExpectation";
import ClientInfomationList from "../Components/Leads/Forms/StageTwo/ClientInfomationList";
import ClientInformationDetails from "../Components/Leads/Forms/StageTwo/ClientInformationDetails";
import ClientInfomation from "../Components/Leads/ClientInfomation";

import SharedLayout from "../Components/Leads/SharedLayout";
import SharedLayoutList from "../Components/Leads/SharedLayoutList";
import QueryLayoutList from "../Components/Leads/Forms/StageThree/QueryLayoutList";
import SharedLayoutDetails from "../Components/Leads/Forms/StageThree/SharedLayoutDetails";
import QueryLayoutDetails from "../Components/Leads/Forms/StageThree/QueryLayoutDetails";
import ConfirmSharedDetails from "../Components/Leads/Forms/StageThree/ConfirmSharedDetails";
import AddQuery from "../Components/Leads/Forms/StageThree/AddQuery";
import ViewQuery from "../Components/Leads/Forms/StageThree/ViewQuery";

import SiteVisitLayout from "../Components/Leads/Forms/StageFour/SiteVisitLayout";
import SiteVisitDetails from "../Components/Leads/Forms/StageFour/SiteVisitDetails";
import SiteVisitInterestSchedule from "../Components/Leads/Forms/StageFour/SiteVisitInterestSchedule";
import SiteVisitList from "../Components/Leads/Forms/StageFour/SiteVisitList";
import SiteVisitContactLayout from "../Components/Leads/Forms/StageFour/SiteVisitContactLayout";
import SiteVisitInterestedLayout from "../Components/Leads/Forms/StageFour/SiteVisitInterestedLayout";
import SiteVisitInterestDetails from "../Components/Leads/Forms/StageFour/SiteVisitInterestDetails";

import PurchaseDecision from "../Components/Leads/Forms/StageFive/PurchaseDecision";
import PurchaseList from "../Components/Leads/Forms/StageFive/PurchaseList";
import PurchaseDetails from "../Components/Leads/Forms/StageFive/PurchaseDetails";

import ConversationFollowUp from "../Components/Leads/Forms/StageSix/ConversationFollowUp";
import ConversationList from "../Components/Leads/Forms/StageSix/ConversationList";
import ConfirmBookingAmount from "../Components/Leads/Forms/StageSix/ConfirmBookingAmount";
import AddRevision from "../Components/Leads/Forms/StageSix/AddRevision";
import ConfirmClientApproval from "../Components/Leads/Forms/StageSix/ConfirmClientApproval";
import ConfirmRegistrationDate from "../Components/Leads/Forms/StageSix/ConfirmRegistrationDate";
import ConfirmFullAmountPayment from "../Components/Leads/Forms/StageSix/ConfirmFullAmountPayment";
import ConfirmRegistrationCompletion from "../Components/Leads/Forms/StageSix/ConfirmRegistrationCompletion";
import ConfirmDocumentsHandover from "../Components/Leads/Forms/StageSix/ConfirmDocumentsHandover";
import RegistrationDocument from "../Components/Leads/Forms/StageSix/RegistrationDocument";
import RegistrationDocumentDetails from "../Components/Leads/Forms/StageSix/RegistrationDocumentDetails";
import CorrectionRevision from "../Components/Leads/Forms/StageSix/CorrectionRevision";

import PurchaseFeedback from "../Components/Leads/Forms/StageSeven/PurchaseFeedback";
import PurchaseFeedbackList from "../Components/Leads/Forms/StageSeven/PurchaseFeedbackList";
import PurchaseFeedbackDetails from "../Components/Leads/Forms/StageSeven/PurchaseFeedbackDetails";
import PurchaseFeedbackReceived from "../Components/Leads/Forms/StageSeven/PurchaseFeedbackReceived";
import PurchaseFeedbackReceivedList from "../Components/Leads/Forms/StageSeven/PurchaseFeedbackReceivedList";
import PurchaseFeedbackReceivedDetails from "../Components/Leads/Forms/StageSeven/PurchaseFeedbackReceivedDetails";

import LeadsTracking from "../Components/LeadsTracking/LeadsTracking";


import AddLayout from "../Components/OmtTeam/AddLayout";
import AddPromoter from "../Components/OmtTeam/AddPromoter";

import "./protected.css";

const Protected = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString !== "" ? JSON.parse(userDetailsString) : {};
    //console.log("userDetails", userDetails);
    if (!userDetails.isLogined) {
      navigate("/login", {});
    } else if (userDetails.user_type === "P") {
      navigate("/promoters", {});
    } else if (userDetails.user_type === "D") {
      navigate("/projects", {});
    } else if (userDetails.user_type === "S") {
      navigate("/projects", {});
    } else if (userDetails.user_type === "O") {
      navigate("/omtteam", {});
    }
  }, []);

  return (
    <div className="">
      <Routes>
        <Route path="*" exact element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/projects" element={<Home />} />
        <Route path="/layoutdetails" element={<LayoutDetails />} />
        <Route path="/sitevisit" element={<SiteVisit />} />
        <Route path="/favourite" element={<Favourite />} />
        <Route path="/enqueries" element={<Enqueries />} />
        <Route path="/plot" element={<Plots />} />
        <Route path="/history" element={<History />} />
        <Route path="/google" element={<Google />} />
        <Route path="/promoters" element={<Promoters />} />
        <Route path="/omtteam" element={<OmtTeam />} />
        <Route path="/viewemployee" element={<ViewEmployees />} />
        <Route path="/leads" element={<LeadsDashboard />} />
        <Route path="/role" element={<Role />} />
        <Route path="/leadsreceived" element={<LeadsReceived />} />
        <Route path="/leadscontacted" element={<LeadsContacted />} />
        <Route path="/leadsnotcontacted" element={<LeadsNotContacted />} />
        <Route
          path="/leadscontactednoresponse"
          element={<LeadsContactedNoResponse />}
        />
        <Route path="/leadsdetails" element={<LeadsDetails />} />
        <Route path="/addcontactlog" element={<AddContactLog />} />
        <Route path="/viewcontactlog" element={<ViewContactLog />} />

        <Route path="/clientdetails" element={<ClientDetails />} />
        <Route path="/clientexpectation" element={<ClientExpectation />} />
        <Route path="/collectinformation" element={<ClientInfomation />} />
        <Route
          path="/collectinformationlist"
          element={<ClientInfomationList />}
        />
        <Route
          path="/collectinformationdetails"
          element={<ClientInformationDetails />}
        />

        <Route path="/sharedlayout" element={<SharedLayout />} />
        <Route path="/sharedlayoutlist" element={<SharedLayoutList />} />
        <Route path="/sharedlayoutdetails" element={<SharedLayoutDetails />} />
        <Route
          path="/addsharedlayoutdetails"
          element={<ConfirmSharedDetails />}
        />
        <Route path="/addquery" element={<AddQuery />} />
        <Route path="/viewquery" element={<ViewQuery />} />
        <Route path="/querylayoutlist" element={<QueryLayoutList />} />
        <Route path="/querylayoutdetails" element={<QueryLayoutDetails />} />

        <Route path="/sitevisitlayout" element={<SiteVisitLayout />} />
        <Route path="/sitevisitlist" element={<SiteVisitList />} />
        <Route path="/sitevisitdetails" element={<SiteVisitDetails />} />
        <Route
          path="/sitevisitinterestschedule"
          element={<SiteVisitInterestSchedule />}
        />
        <Route
          path="/sitevisitcontactlayout"
          element={<SiteVisitContactLayout />}
        />
        <Route
          path="/sitevisitinterestedlayout"
          element={<SiteVisitInterestedLayout />}
        />
        <Route
          path="/sitevisitinterestdetails"
          element={<SiteVisitInterestDetails />}
        />

        <Route path="/purchase" element={<PurchaseDecision />} />
        <Route path="/purchaselist" element={<PurchaseList />} />
        <Route path="/purchasedetails" element={<PurchaseDetails />} />

        <Route path="/conversation" element={<ConversationFollowUp />} />
        <Route path="/conversationlist" element={<ConversationList />} />
        <Route path="/bookamount" element={<ConfirmBookingAmount />} />
        <Route path="/revision" element={<AddRevision />} />
        <Route path="/correctionrevision" element={<CorrectionRevision />} />
        <Route path="/clientapproval" element={<ConfirmClientApproval />} />
        <Route
          path="/confirmregistration"
          element={<ConfirmRegistrationDate />}
        />
        <Route path="/settle" element={<ConfirmFullAmountPayment />} />
        <Route
          path="/regcompleted"
          element={<ConfirmRegistrationCompletion />}
        />
        <Route path="/handover" element={<ConfirmDocumentsHandover />} />
        <Route
          path="/registrationdocuments"
          element={<RegistrationDocument />}
        />
        <Route
          path="/registrationdocumentsdetails"
          element={<RegistrationDocumentDetails />}
        />

        <Route path="/feedback" element={<PurchaseFeedback />} />
        <Route path="/feedbacklist" element={<PurchaseFeedbackList />} />
        <Route path="/feedbackdetails" element={<PurchaseFeedbackDetails />} />
        <Route
          path="/feedbackreceived"
          element={<PurchaseFeedbackReceived />}
        />
        <Route
          path="/feedbackreceivedlist"
          element={<PurchaseFeedbackReceivedList />}
        />
        <Route
          path="/feedbackreceiveddetails"
          element={<PurchaseFeedbackReceivedDetails />}
        />

        <Route path="/leadstracking" element={<LeadsTracking />} />


        <Route path="/addLayout" exact element={<AddLayout />} />
        <Route path="/addPromoter" exact element={<AddPromoter />} />

      </Routes>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Protected);
