import {
  GET_LEADS_RECEIVED,
  GET_LEADS_CONTACT_LIST,
  GET_LEADS_NOT_CONTACT_LIST,
  GET_LEADS_CONTACT_NO_RESPONSE_LIST,
  GET_LEADS_CONTACT_LOG,
  GET_LEADS_COUNT,
  GET_LEADS_LIST,
  GET_LEADS_INTEREST_COUNT
} from "./actionType";

export const initialState = {
  leads_received: {},
  leads_contact_list: [],
  leads_not_contact_list: [],
  leads_contact_no_response_list: [],
  leads_contact_log: [],
  leads_count: {},
  leads_list: [],
  interest_count: [],
};

const layoutReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_LEADS_RECEIVED: {
      const newState = {
        ...state,
        leads_received: data.leads,
      };
      return newState;
    }
    case GET_LEADS_NOT_CONTACT_LIST: {
      const newState = {
        ...state,
        leads_not_contact_list: data.leads_not_contact_list,
      };
      return newState;
    }
    case GET_LEADS_CONTACT_LIST: {
      const newState = {
        ...state,
        leads_contact_list: data.leads_contact_list,
      };
      return newState;
    }
    case GET_LEADS_CONTACT_NO_RESPONSE_LIST: {
      const newState = {
        ...state,
        leads_contact_no_response_list: data.leads_contact_no_response_list,
      };
      return newState;
    }
    case GET_LEADS_CONTACT_LOG: {
      const newState = {
        ...state,
        leads_contact_log: data.leads_contact_log,
      };
      return newState;
    }
    case GET_LEADS_COUNT: {
      const newState = {
        ...state,
        leads_count: data.leads_count,
      };
      return newState;
    }
    case GET_LEADS_LIST: {
      const newState = {
        ...state,
        leads_list: data.leads_list,
      };
      return newState;
    }
    case GET_LEADS_INTEREST_COUNT: {
      const newState = {
        ...state,
        interest_count: data.interest_count,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default layoutReducer;
