import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import SaveIcon from "@mui/icons-material/Save";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { baseUrl } from "../../../../util/config";

//Custom imports
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import * as CSS from "../style";

//Other lib imports
import axios from "axios";

const ConfirmFullAmountPayment = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [inputObj, setInputObj] = useState({
    amount: "",
    date: "",
    paymentMode: "online",
    transactionID: "",
    toAccount: "",
  });

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [dataDetails, setDataDetails] = React.useState(null);
  const [leadsDetails, setLeadsDetails] = React.useState(null);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  useEffect(() => {
    if (state.layoutDetails) {
      setLayoutDetails({
        ...layoutDetails,
        ...state.layoutDetails,
      });
    }

    if (state.data) {
      setDataDetails({
        ...dataDetails,
        ...state.data,
      });
    }
  }, [state]);

  useEffect(() => {
    if (layoutDetails && layoutDetails.id) {
      const payload = {
        layout_id: layoutDetails.id,
        mpdp_mapping_id: state.data.display_id,
      };
      axios.post(`${baseUrl}/leads/list`, payload).then(
        (res) => {
          console.log("res", res.data && res.data);
          if (res.data && res.data.leads_list) {
            if (res.data.leads_list.length > 0) {
              setLeadsDetails(res.data.leads_list[0]);
            }
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  }, [layoutDetails]);

  const doSubmit = () => {
    console.log("inputObj");
    console.log(inputObj);
    if (!inputObj.amount || !inputObj.paymentMode || !inputObj.date) {
      openSnackBar("Please fill all data", "error");
      return;
    }

    if (
      inputObj.paymentMode !== "cash" &&
      (!inputObj.transactionID || !inputObj.toAccount)
    ) {
      openSnackBar("Please fill all data", "error");
      return;
    }

    const payload = {
      is_conversion_type: 4,
      data: {
        ...inputObj,
      },
      mpdp_mapping_id: state.data.display_id,
      user_id: userDetails && userDetails.user_id,
    };
    axios
      .post(`${baseUrl}/leads/conversion/update`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          //console.log("err", err);
        }
      );
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                  Confirm Full Amount Payment
                </span>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Amount"
                  required={true}
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  type="number"
                  onChange={(e) =>
                    setInputObj({ ...inputObj, amount: e.target.value })
                  }
                  value={inputObj.amount}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Date"
                  required={true}
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setInputObj({ ...inputObj, date: e.target.value })
                  }
                  value={inputObj.date}
                />
              </div>
              <div className="login-form-div">
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="made-contact-by">
                    {"Mode of payment *"}
                  </InputLabel>
                  <Select
                    labelId="made-contact-by"
                    id="made-contact-by-select"
                    label="Mode of payment"
                    onChange={(e) =>
                      setInputObj({ ...inputObj, paymentMode: e.target.value })
                    }
                    value={inputObj.paymentMode}
                  >
                    <MenuItem value={"online"}>online</MenuItem>
                    <MenuItem value={"cash"}>cash</MenuItem>
                    <MenuItem value={"cheque"}>Cheque</MenuItem>
                    <MenuItem value={"neft"}>NEFT</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputObj.paymentMode !== "cash" && (
                <>
                  <div className="login-form-div">
                    <TextField
                      label="Transaction ID"
                      required={true}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      onChange={(e) =>
                        setInputObj({
                          ...inputObj,
                          transactionID: e.target.value,
                        })
                      }
                      value={inputObj.transactionID}
                    />
                  </div>
                  <div className="login-form-div">
                    <TextField
                      label="To Account"
                      required={true}
                      variant="outlined"
                      className="login-inputWidth"
                      size="small"
                      onChange={(e) =>
                        setInputObj({ ...inputObj, toAccount: e.target.value })
                      }
                      value={inputObj.toAccount}
                    />
                  </div>
                </>
              )}
              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={(e) =>
                      backRoute(e, "conversationlist", {
                        id: layoutDetails.id,
                        layout_name: layoutDetails.layout_name,
                        layout_id: layoutDetails.id,
                        type: layoutDetails.type,
                      })
                    }
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default ConfirmFullAmountPayment;
