/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

//Material UI imports
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from "@mui/icons-material/Info";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CommuteIcon from "@mui/icons-material/Commute";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TuneIcon from "@mui/icons-material/Tune";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";

//Custom imports
import LeadsHeader from "../HeaderMenu/LeadsHeader";
import "../Home/PlotCard/PlotCard.css";
import "../Home/Home.css";
import { map, includes } from "lodash";
import XHR from "../../util/xhr";
import FilterPopup from "../Overlays/FilterPopup/FilterPopup";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import moment from "moment";
//Style imports
import * as CSS from "./style";

import { getLeadsReceived } from "../../store/leads/actionCreator";

const LeadsDashboard = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // console.log(
  //   "props.location.state.newId",
  //   state && state
  // );

  const { getLeadsReceived, leads_received } = props;

  const [userDetails, setUserDetails] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [promoters, setPromoters] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  const [filterCond, setFilterCond] = React.useState({
    from: moment(new Date(new Date())).subtract(30, "d").format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
  });
  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }

    const date_filter = localStorage.getItem("date_filter") || null;
    if (date_filter) {
      const filterData = JSON.parse(date_filter);
      setFilterCond(filterData);
    }

  }, []);

  React.useEffect(() => {
    if (state && userDetails) {
      getLeadsReceived({
        layout_id: state.id,
        user_type: userDetails && userDetails.user_type,
        user_id: userDetails && userDetails.user_id,
        ...filterCond,
      });
    }
    if (userDetails) {
      let data = {
        company_id: userDetails.user_id,
      };
      setIsLoading(true);
      XHR.post("realestate/promoters/get", data).then(
        (res) => {
          //console.log("res.data", res.data);
          if (res.data && res.data.companies) {
            setIsLoading(false);
            setPromoters(res.data.companies);
          } else {
            setIsLoading(false);
          }
        },
        (err) => {}
      );
    }
    if (userDetails && userDetails.user_type === "D") {
      let data = {
        user_id: userDetails.user_id,
      };
      setIsLoading(true);
      XHR.post("realestate_v1/user/id", data).then(
        (res) => {
          //console.log("res.data", res.data);
          if (res.data && res.data.users && res.data.users.length > 0) {
            setIsLoading(false);
            setUser(res.data.users[0]);
          } else {
            setIsLoading(false);
          }
        },
        (err) => {}
      );
    }
  }, [userDetails]);

  React.useEffect(() => {
    if (state) {
      setLayoutDetails({
        ...layoutDetails,
        ...state,
      });
    }
  }, [state]);

  const menuOptions = [
    {
      menu_id: 1,
      menu_url: "leadsreceived",
      menu_name: "Leads Received",
    },
    {
      menu_id: 2,
      menu_url: "leadscontacted",
      menu_name: "Leads Contacted",
    },
    {
      menu_id: 3,
      menu_url: "leadsnotcontacted",
      menu_name: "Leads Not Contacted",
    },
    {
      menu_id: 4,
      menu_url: "leadscontactednoresponse",
      menu_name: "Leads Contacted No Response",
    },
    {
      menu_id: 5,
      menu_url: "collectinformation",
      menu_name: "Collect Client Information",
    },
    {
      menu_id: 6,
      menu_url: "abouttheclient",
      menu_name: "About the Client",
    },
    {
      menu_id: 7,
      menu_url: "clientexpectation",
      menu_name: "Client Expectation",
    },
    {
      menu_id: 8,
      menu_url: "clientexpectation",
      menu_name: "Shared Layout Details",
    },
    {
      menu_id: 9,
      menu_url: "clientexpectation",
      menu_name: "Sharing Completed",
    },
    {
      menu_id: 10,
      menu_url: "clientexpectation",
      menu_name: "Sharing Pending",
    },
    {
      menu_id: 11,
      menu_url: "clientexpectation",
      menu_name: "Other QUERIES FROM ENQUIRY",
    },
    {
      menu_id: 12,
      menu_url: "clientexpectation",
      menu_name: "ANSWERED ENQUIRY",
    },
    {
      menu_id: 13,
      menu_url: "clientexpectation",
      menu_name: "PENDING QUERIES",
    },
  ];

  const doRoute = (e, menu_url, data = {}) => {
    e.stopPropagation();
    if (menu_url !== "") {
      navigate(`/${menu_url}`, {
        state: data,
      });
    }
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  const onSubmit = (data) => {
    getLeadsReceived({
      layout_id: state.id,
      user_type: userDetails && userDetails.user_type,
      user_id: userDetails && userDetails.user_id,
      ...data,
    });
    setOpenFilterPopup(false);
    setFilterCond(data);
    localStorage.setItem("date_filter", JSON.stringify(data));
  };

  console.log("leads_received", promoters);

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <FilterPopup
        openFlag={openFilterPopup}
        setCloseFlag={setOpenFilterPopup}
        onSubmit={onSubmit}
        openSnackBar={openSnackBar}
      />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="3em 0 2em 0;"
        >
          <LeadsHeader
            showBack={true}
            backRoute={(e) =>
              backRoute(e, "layoutdetails", {
                id: layoutDetails.id,
                layout_name: layoutDetails.layout_name,
                project_hash: layoutDetails.project_hash,
              })
            }
          />
        </Grid>
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "42%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}

      {promoters &&
        promoters.general_information &&
        promoters.general_information.company_name !== "" && (
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="1em 0 0 0;"
          >
            <span className="plp-lo-dtls-header">
              {promoters.general_information.company_name.toLowerCase()}
            </span>
          </Grid>
        )}

      {userDetails && userDetails.layout_name && (
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-header">
            {layoutDetails.layout_name.toLowerCase()}
          </span>
        </Grid>
      )}

      {layoutDetails && layoutDetails.layout_name && (
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="1em 0 0 0;"
        >
          <span className="plp-lo-dtls-header">
            {layoutDetails.layout_name.toLowerCase()}
          </span>
        </Grid>
      )}

      {filterCond && (
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="2em 0 0 0;"
        >
          <span className="plp-lo-dtls-filter">
            filter date :{" "}
            {moment(new Date(filterCond.from)).format("DD/MM/YYYY")} to{" "}
            {moment(new Date(filterCond.to)).format("DD/MM/YYYY")}
          </span>
        </Grid>
      )}
      <Timeline>
        <TimelineItem className={"leads-header"}>
          <TimelineOppositeContent
            className={"leads-header-normal-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "leads_count" in leads_received
                ? leads_received.leads_count
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"}>
              <AssignmentIcon />
            </TimelineDot>
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-header-normal-title"}>
            {/* <a
              onClick={(e) =>
                doRoute(e, "leadsreceived", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
from: filterCond.from,
to: filterCond.to,
                  type: "LR",
                })
              }
            >
              
            </a> */}
            Total no of Leads Received
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            className={"leads-content-normal-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "contacted" in leads_received
                ? leads_received.contacted
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "leadscontacted", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "LC",
                })
              }
            >
              Total no of Leads Contacted
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received && "not_contacted" in leads_received
                ? leads_received.not_contacted
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "leadsnotcontacted", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "LNC",
                })
              }
            >
              Total no of Leads Yet to Contact
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received && "no_response" in leads_received
                ? leads_received.no_response
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "leadscontactednoresponse", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "LCNR",
                })
              }
            >
              Total no of Leads Contacted, but No Response
            </a>
          </TimelineContent>
        </TimelineItem>
        {/* ------------------------------------------------------- */}
        <TimelineItem className={"leads-header"}>
          <TimelineContent className={"leads-header-alternate-title"}>
            Information About the Leads
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"}>
              <InfoIcon />
            </TimelineDot>
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-header-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_collected_info_total" in leads_received
                ? leads_received.is_collected_info_total
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "collectinformationlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CDEC",
                })
              }
            >
              Lead Information Collected
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_collected_info_done" in leads_received
                ? leads_received.is_collected_info_done
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "collectinformation", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CIN",
                })
              }
            >
              Lead Information Not Collected
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_collected_info_pending" in leads_received
                ? leads_received.is_collected_info_pending
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        {/* ------------------------------------------------------- */}
        <TimelineItem className={"leads-header"}>
          <TimelineOppositeContent
            className={"leads-header-normal-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_details_shared_total" in leads_received
                ? leads_received.is_details_shared_total
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"}>
              <FmdGoodIcon />
            </TimelineDot>
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-header-normal-title"}>
            Shared Layout Details with Lead
            {/* <a
              onClick={(e) =>
                doRoute(e, "sharedlayout", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
from: filterCond.from,
to: filterCond.to,
                })
              }
            >
              
            </a> */}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            className={"leads-content-normal-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_details_shared" in leads_received
                ? leads_received.is_details_shared
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "sharedlayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "DS",
                })
              }
            >
              Sharing Completed
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received && "is_details_not_shared" in leads_received
                ? leads_received.is_details_not_shared
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "sharedlayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "DNS",
                })
              }
            >
              Sharing Pending
            </a>
          </TimelineContent>
        </TimelineItem>
        {/* <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            10
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "sharedlayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
from: filterCond.from,
to: filterCond.to,
                  type: "DNS",
                })
              }
            >
              Answered
            </a>
          </TimelineContent>
        </TimelineItem> */}
        {/* <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            10
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "sharedlayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
from: filterCond.from,
to: filterCond.to,
                  type: "DNS",
                })
              }
            >
              Pending
            </a>
          </TimelineContent>
        </TimelineItem> */}

        {/* ------------------------------------------------------- */}
        <TimelineItem className={"leads-header"}>
          <TimelineContent className={"leads-header-alternate-title"}>
            No of Other Questions from the Leads
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"}>
              <PsychologyAltIcon />
            </TimelineDot>
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-header-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_other_queries_total" in leads_received
                ? leads_received.is_other_queries_total
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "querylayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "OQA",
                })
              }
            >
              No of Questions Answered
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_other_queries_answered" in leads_received
                ? leads_received.is_other_queries_answered
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "querylayoutlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "OQNA",
                })
              }
            >
              No of Questions Not Answered
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_other_queries_pending" in leads_received
                ? leads_received.is_other_queries_pending
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        {/* ------------------------------------------------------- */}
        <TimelineItem className={"leads-header"}>
          <TimelineOppositeContent
            className={"leads-header-normal-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_site_visit_total" in leads_received
                ? leads_received.is_site_visit_total
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"}>
              <CommuteIcon />
            </TimelineDot>
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-header-normal-title"}>
            No of Leads Possible for Site Visit
            {/* <a
              onClick={(e) =>
                doRoute(e, "sitevisitlayout", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
from: filterCond.from,
to: filterCond.to,
                  type: "SV",
                })
              }
            >
              
            </a> */}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received && "is_site_visit_not_contacted" in leads_received
                ? leads_received.is_site_visit_not_contacted
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "sitevisitlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "NCSV",
                })
              }
            >
              Leads Not Contacted to schedule site visit
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received &&
              "is_site_visit_contacted_call_later" in leads_received
                ? leads_received.is_site_visit_contacted_call_later
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "sitevisitlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CSVCL",
                })
              }
            >
              Leads Contacted and Need to call later
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received &&
              "is_site_visit_contacted_not_interest" in leads_received
                ? leads_received.is_site_visit_contacted_not_interest
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "sitevisitlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CSVNI",
                })
              }
            >
              Leads Contacted and Not Interested
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received &&
              "is_site_visit_contacted_interest_and_scheduled" in leads_received
                ? leads_received.is_site_visit_contacted_interest_and_scheduled
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "sitevisitinterestedlayout", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CSVIL",
                })
              }
            >
              Leads Contacted and Scheduled
            </a>
          </TimelineContent>
        </TimelineItem>
        {/* ------------------------------------------------------- */}
        <TimelineItem className={"leads-header"}>
          <TimelineContent className={"leads-header-alternate-title"}>
            {/* <a
              onClick={(e) =>
                doRoute(e, "purchase", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
from: filterCond.from,
to: filterCond.to,
                  type: "PD",
                })
              }
            >
              PURCHASE DECISION
            </a> */}
            No of Leads Possible to Buy the Plot
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"}>
              <AttachMoneyIcon />
            </TimelineDot>
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-header-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_purchase_decision_total" in leads_received
                ? leads_received.is_purchase_decision_total
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "purchaselist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "PDP",
                })
              }
            >
              No of Leads Decision is Pending
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_purchase_decision_pending" in leads_received
                ? leads_received.is_purchase_decision_pending
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "purchaselist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "PDB",
                })
              }
            >
              No of Leads Decided to Buy
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_purchase_decision_buy" in leads_received
                ? leads_received.is_purchase_decision_buy
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "purchaselist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "PDNB",
                })
              }
            >
              No of Leads Not Interested to Buy
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_purchase_decision_not_buy" in leads_received
                ? leads_received.is_purchase_decision_not_buy
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "purchaselist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "PDH",
                })
              }
            >
              No of Leads Put The Decision On Hold
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_purchase_decision_hold" in leads_received
                ? leads_received.is_purchase_decision_hold
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        {/* ------------------------------------------------------- */}
        <TimelineItem className={"leads-header"}>
          <TimelineOppositeContent
            className={"leads-header-normal-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_conversion_followup_total" in leads_received
                ? leads_received.is_conversion_followup_total
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"}>
              <SupportAgentIcon />
            </TimelineDot>
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-header-normal-title"}>
            No of Leads for Registration
            {/* <a
              onClick={(e) =>
                doRoute(e, "conversation", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
from: filterCond.from,
to: filterCond.to,
                  type: "CFU",
                })
              }
            >
              
            </a> */}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            className={"leads-content-normal-value"}
            color="text.secondary"
          >
            {`${
              leads_received &&
              "is_conversion_followup_booking" in leads_received
                ? leads_received.is_conversion_followup_booking
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent className={"leads-content-normal-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "conversationlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CBP",
                })
              }
            >
              No of Leads Paid for Booking and Allotment
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received &&
              "is_conversion_followup_review" in leads_received
                ? leads_received.is_conversion_followup_review
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent>
            <a
              onClick={(e) =>
                doRoute(e, "registrationdocuments", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CRD",
                })
              }
            >
              No of Leads in Registration Document Review Stage
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received &&
              "is_conversion_followup_confirm" in leads_received
                ? leads_received.is_conversion_followup_confirm
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent>
            <a
              onClick={(e) =>
                doRoute(e, "conversationlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CRC",
                })
              }
            >
              No of Leads Registration Date Confirmed
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received &&
              "is_conversion_followup_settle" in leads_received
                ? leads_received.is_conversion_followup_settle
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent>
            <a
              onClick={(e) =>
                doRoute(e, "conversationlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CFA",
                })
              }
            >
              No of Leads Paid Full Amount
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received &&
              "is_conversion_followup_completed" in leads_received
                ? leads_received.is_conversion_followup_completed
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent>
            <a
              onClick={(e) =>
                doRoute(e, "conversationlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CRP",
                })
              }
            >
              No of Leads Registration Completed
            </a>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            {`${
              leads_received &&
              "is_conversion_followup_handover" in leads_received
                ? leads_received.is_conversion_followup_handover
                : 0
            }`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineContent>
            <a
              onClick={(e) =>
                doRoute(e, "conversationlist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "CDH",
                })
              }
            >
              No of Leads Document Handover
            </a>
          </TimelineContent>
        </TimelineItem>
        {/* ------------------------------------------------------- */}
        <TimelineItem className={"leads-header"}>
          <TimelineContent className={"leads-header-alternate-title"}>
            No of Leads for Purchase Feedback
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"}>
              <RateReviewIcon />
            </TimelineDot>
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-header-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_feedback_total" in leads_received
                ? leads_received.is_feedback_total
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "feedbacklist", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "PFP",
                })
              }
            >
              No of Leads Feedback Pending
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_feedback_pending" in leads_received
                ? leads_received.is_feedback_pending
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent className={"leads-content-alternate-title"}>
            <a
              onClick={(e) =>
                doRoute(e, "feedbackreceived", {
                  id: layoutDetails.id,
                  layout_name: layoutDetails.layout_name,
                  layout_id: layoutDetails.id,
                  from: filterCond.from,
                  to: filterCond.to,
                  type: "PFR",
                })
              }
            >
              No of Leads Feedback Received
            </a>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot className={"leads-header-Icon"} />
            <TimelineConnector className={"leads-connector-line"} />
          </TimelineSeparator>
          <TimelineOppositeContent
            className={"leads-content-alternate-value"}
            color="text.secondary"
          >
            {`${
              leads_received && "is_feedback_received" in leads_received
                ? leads_received.is_feedback_received
                : 0
            }`}
          </TimelineOppositeContent>
        </TimelineItem>

        {/* ------------------------------------------------------- */}
      </Timeline>

      <Fab
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 200,
          background: "#462B00",
          color: "#fff",
        }}
        aria-label={"filter"}
        onClick={(e) => setOpenFilterPopup(true)}
      >
        <TuneIcon />
      </Fab>
    </CSS.ParentDiv>
  );
};

const mapStateToProps = (state) => {
  return {
    leads_received: state.leads.leads_received,
  };
};
const mapDispatchToProps = {
  getLeadsReceived,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsDashboard);
