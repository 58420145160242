import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
//Custom imports
import "./PlotCard.css";
import axios from "axios";

import { map } from "lodash";
import moment from "moment";

import { baseUrl, baseUrlPlotsApi } from "../../../util/config";

const PlotCard = (props) => {
  const navigate = useNavigate();

  const { list, openLayout } = props;

  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    }
  }, []);

  const copyUrl = (
    e,
    layout_id,
    district,
    state,
    promotors,
    company_hash,
    company_userid,
    name
  ) => {
    e.stopPropagation();
    const payload = {
      layout_id: layout_id,
      user_id: (userDetails && userDetails.user_id) || "",
    };
    axios.post(`${baseUrlPlotsApi}/analytics/addleads`, payload).then(
      (res) => {
        if (res.data && res.data.status) {
          let path = `${baseUrl}/mpdp/residential_plots_${
            state != "" ? state.replace(/\s+/g, "").toLowerCase() : "state"
          }/residential_plots_${
            district != ""
              ? district.replace(/\s+/g, "").toLowerCase()
              : "district"
          }/${
            promotors != ""
              ? promotors.replace(/\s+/g, "").toLowerCase()
              : "promotors "
          }/${name.replace(/\s+/g, "").toLowerCase()}/${company_hash}/${
            res.data.url
          }`;
          navigator.clipboard.writeText(path);
          console.log("res.data", path);
        }
      },
      (err) => {
        //console.log("err", err);
      }
    );
  };

  const onLayoutBtnClk = (e, id) => {
    e.stopPropagation();
    navigate(`/addLayout`, {
      state: {
        id: id,
        isSubmited: false,
      },
    });
  };

  return (
    <>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="1em 0 1em 0;"
      >
        {list &&
          list.length > 0 &&
          map(list, function (data, index) {
            let aboutCompany = data.about_company
              ? JSON.parse(data.about_company)
              : {};

            return (
              <Card
                className="box-card"
                key={index}
                onClick={(e) => {
                  userDetails && userDetails.user_type == "O"
                    ? onLayoutBtnClk(e, data.id)
                    : openLayout(e, data);
                }}
              >
                <CardHeader
                  action={
                    <>
                      {userDetails && userDetails.user_type == "D" && (
                        <IconButton
                          aria-label="settings"
                          onClick={(e) => {
                            copyUrl(
                              e,
                              data.id,
                              data.company_district,
                              data.company_state,
                              data.company_promotors,
                              data.hash_key,
                              data.id,
                              data.name
                            );
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )}
                    </>
                  }
                  title={
                    <div className="project-list">
                      <Typography className="box-text" gutterBottom>
                        {data.name}
                      </Typography>
                      {userDetails && userDetails.user_type == "O" && (
                        <>
                          <Typography className="box-label" gutterBottom>
                            {moment(new Date(data.ts)).format(
                              "DD/MM/YYYY H:m A"
                            )}
                          </Typography>
                          {aboutCompany && (
                            <Typography className="box-label" gutterBottom>
                              {aboutCompany.address}
                            </Typography>
                          )}
                        </>
                      )}
                      {userDetails && userDetails.user_type != "O" && (
                        <Grid
                          container
                          gap="1em"
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                        >
                          <ButtonGroup
                            variant="contained"
                            orientation={"horizontal"}
                            className="button-group-card"
                          >
                            {data.unsold_cnt > 0 && (
                              <Button className={`pdp-lo-button-grp`}>
                                <span className="dot-text">
                                  Available ({data.unsold_cnt})
                                </span>
                              </Button>
                            )}
                            {data.sold_cnt > 0 && (
                              <Button className={`pdp-lo-button-grp `}>
                                <span className="dot-text">
                                  Sold ({data.sold_cnt})
                                </span>
                              </Button>
                            )}
                            {data.booked_cnt > 0 && (
                              <Button className={`pdp-lo-button-grp`}>
                                <span className="dot-text">
                                  Booked ({data.booked_cnt})
                                </span>
                              </Button>
                            )}
                          </ButtonGroup>
                        </Grid>
                      )}
                    </div>
                  }
                />
              </Card>
            );
          })}

        {list && list.length == 0 && (
          <Card className="box-card" key={0}>
            <CardHeader
              title={
                <div className="project-list">
                  <Typography className="box-text" gutterBottom>
                    {"No layout is added"}
                  </Typography>
                </div>
              }
            />
          </Card>
        )}
      </Grid>
    </>
  );
};

export default PlotCard;
