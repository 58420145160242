export const GET_LEADS_RECEIVED = "GET_LEADS_RECEIVED";
export const GET_LEADS_NOT_CONTACT_LIST = "GET_LEADS_NOT_CONTACT_LIST";
export const GET_LEADS_CONTACT_LIST = "GET_LEADS_CONTACT_LIST";
export const GET_LEADS_CONTACT_NO_RESPONSE_LIST =
  "GET_LEADS_CONTACT_NO_RESPONSE_LIST";
export const GET_LEADS_CONTACT_LOG = "GET_LEADS_CONTACT_LOG";
export const GET_LEADS_CLIENT_DETAILS_COLLECTED_LIST =
  "GET_LEADS_CLIENT_DETAILS_COLLECTED_LIST";
export const GET_LEADS_CLIENT_DETAILS_NOT_COLLECTED_LIST =
  "GET_LEADS_CLIENT_DETAILS_NOT_COLLECTED_LIST";
export const GET_LEADS_CLIENT_EXPECTATION_COLLECTED_LIST =
  "GET_LEADS_CLIENT_EXPECTATION_COLLECTED_LIST";
export const GET_LEADS_CLIENT_EXPECTATION_NOT_COLLECTED_LIST =
  "GET_LEADS_CLIENT_EXPECTATION_NOT_COLLECTED_LIST";
export const GET_LEADS_COUNT = "GET_LEADS_COUNT";
export const GET_LEADS_LIST = "GET_LEADS_LIST";
export const GET_LEADS_INTEREST_COUNT = "GET_LEADS_INTEREST_COUNT";