import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import SaveIcon from "@mui/icons-material/Save";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";

import { baseUrl } from "../../../../util/config";

//Custom imports
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Snackbar from "../../../snackbar";
import * as CSS from "../style";

//Other lib imports
import axios from "axios";

const ConfirmDocumentsHandover = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [inputObj, setInputObj] = useState({
    documents: [
      {
        label: "Registration Document",
        checked: false,
      },
      {
        label: "Parental Documents",
        checked: false,
      },
      {
        label: "Concerned Authority Approvals",
        checked: false,
      },
    ],
    handOverDate: null,
  });

  const [userDetails, setUserDetails] = React.useState(null);
  const [layoutDetails, setLayoutDetails] = React.useState(null);
  const [dataDetails, setDataDetails] = React.useState(null);
  const [leadsDetails, setLeadsDetails] = React.useState(null);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    } else {
      navigate("/login", {});
    }
  }, []);

  useEffect(() => {
    if (state.layoutDetails) {
      setLayoutDetails({
        ...layoutDetails,
        ...state.layoutDetails,
      });
    }

    if (state.data) {
      setDataDetails({
        ...dataDetails,
        ...state.data,
      });
    }
  }, [state]);

  useEffect(() => {
    if (layoutDetails && layoutDetails.id) {
      const payload = {
        layout_id: layoutDetails.id,
        mpdp_mapping_id: state.data.display_id,
      };
      axios.post(`${baseUrl}/leads/list`, payload).then(
        (res) => {
          console.log("res", res.data && res.data);
          if (res.data && res.data.leads_list) {
            if (res.data.leads_list.length > 0) {
              setLeadsDetails(res.data.leads_list[0]);
            }
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  }, [layoutDetails]);

  const documentsChange = (e, index) => {
    let updatedDocument = [...inputObj.documents];
    updatedDocument[index].checked = e.target.checked;
    setInputObj({ ...inputObj, documents: updatedDocument });
  };

  const doSubmit = () => {
    // const outputObj = { ...inputObj, buyingInterest: [...poltName] };
    console.log("inputObj");
    console.log(inputObj);

    if (
      !inputObj.handOverDate ||
      !inputObj.documents.some(({ checked }) => checked)
    ) {
      openSnackBar("Please Enter all fields", "error");
      return;
    }

    const payload = {
      is_conversion_type: 6,
      data: {
        ...inputObj,
      },
      mpdp_mapping_id: state.data.display_id,
      user_id: userDetails && userDetails.user_id,
    };
    axios
      .post(`${baseUrl}/leads/conversion/update`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "error");
          //console.log("err", err);
        }
      );
  };

  const backRoute = (e, url, state = {}) => {
    e.stopPropagation();
    if (url !== "") {
      navigate(`/${url}`, {
        state: state,
      });
    }
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                No of Leads Document Handover
                </span>
              </div>
              <div className="login-form-div">
                <div>
                  <FormControl>
                    <FormLabel>Documents*</FormLabel>
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {inputObj.documents.map((item, index) => {
                    return (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.checked}
                              onChange={(e) => documentsChange(e, index)}
                            />
                          }
                          label={item.label}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Handover Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  onChange={(e) =>
                    setInputObj({ ...inputObj, handOverDate: e.target.value })
                  }
                  value={inputObj.handOverDate}
                  inputProps={{ maxLength: 1500 }}
                />
              </div>

              <div
                className="profile-back-next-btn-cntr"
                style={{ marginTop: "5%" }}
              >
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    startIcon={<WestIcon />}
                    onClick={(e) =>
                      backRoute(e, "conversationlist", {
                        id: layoutDetails.id,
                        layout_name: layoutDetails.layout_name,
                        layout_id: layoutDetails.id,
                        type: layoutDetails.type,
                      })
                    }
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    className="login-button"
                    endIcon={<SaveIcon />}
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default ConfirmDocumentsHandover;
