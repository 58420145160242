import { useState, useEffect } from "react";

//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

// Other Imports

import { map, includes } from "lodash";

//Custom imports
import * as CSS from "../Style.js";
import XHR from "../../../util/xhr";

const RolePopup = (props) => {
  const { openFlag, setCloseFlag, onSubmit, openSnackBar, editRole } = props;

  const [roleData, setRoleData] = useState({
    role_title: "",
  });
  const [userDetails, setUserDetails] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("realestate_user") || null;
    let userToken = token !== "" ? JSON.parse(token) : {};
    if (userToken && userToken.isLogined) {
      setUserDetails({
        ...userDetails,
        ...userToken,
      });
    }
  }, []);

  useEffect(() => {
    if (editRole) {
      setRoleData({
        ...roleData,
        role_title: editRole.role_title,
        id: editRole.id,
      });
    } else {
      setRoleData({
        ...roleData,
        role_title: "",
        id: 0,
      });
    }
  }, [editRole]);

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setRoleData({
      ...roleData,
      [name]: value,
    });
    setErrors({
      ...errors,
      role_title: "",
    });
  };

  const validateInputValue = (e) => {
    const { name, value } = e.target;
    validate({ [name]: value });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("role_title" in fieldValues)
      temp.role_title = fieldValues.role_title ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
  };

  const doSubmit = () => {
    if (!roleData.role_title) {
      //console.log("openSnackBar");
      openSnackBar("Enter role name", "error");
      return;
    }
    onSubmit(roleData);
  };

  return (
    <>
      <CSS.UserDialog
        open={openFlag}
        onClose={() => setCloseFlag(false)}
        maxWidth={"lg"}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="1em 0 1em 0;"
        >
          <CSS.CloseBtn>
            <IconButton
              aria-label="closePopup"
              onClick={() => setCloseFlag(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </CSS.CloseBtn>
          <CSS.TitleContainer>
            <span className="title">Add / Edit Role</span>
          </CSS.TitleContainer>

          <TextField
            label="Role Name"
            autoComplete="off"
            fullWidth={true}
            onBlur={validateInputValue}
            onChange={handleInputValue}
            name={"role_title"}
            value={roleData.role_title}
            {...(errors["role_title"] && {
              error: true,
              helperText: errors["role_title"],
            })}
          />
          <CSS.customBtn variant="outlined" onClick={doSubmit}>
            Submit
          </CSS.customBtn>
        </Grid>
      </CSS.UserDialog>
    </>
  );
};

export default RolePopup;
